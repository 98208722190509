/* eslint-disable @typescript-eslint/no-require-imports */
export default {
  countries: require('fourwaves-shared/src/core/countries.en.json'),
  provinces: require('fourwaves-shared/src/core/provinces.en.json'),
  currencies: require('fourwaves-shared/src/core/currencies.en.json'),
  data_table: {
    results_per_page: 'Results per page',
    display: 'Display',
    edit_columns: 'Edit columns',
    export: 'Export as Excel',
    export_submission_files: 'Export Submission Files',
    generate_badges: 'Generate badges',
    generate_poster_tags: 'Generate Poster Tags',
    generate_booklet: 'Generate Booklet',
    new_participant: 'New participant',
    filters: 'Filters',
    filters_action: 'Apply',
    filters_reset: 'Reset Filters',
    filters_button: 'Filters | {count} Filter | {count} Filters',
    filters_general: 'General',
    apply_filters: 'Apply filters',
    applied_filters: 'Applied filters',
    clear_filters: 'Clear filters',
    selected: '{0} selected',
    selected_element: 'selected',
    selected_elements: 'selected',
    update_abstract_id: 'Edit ID',
    new_abstract_id: 'New ID',
    update_submission_decision: 'Edit decision: {name}',
    abtract_submission_confirm: 'Submission title:',
    public_status: 'Public',
    public_status_description:
      'Unselecting this option will make this submission invisible to organizers and participants throughout your event website.',
    public_profile_status: 'Public Profile',
    public_profile_status_description:
      'Unselecting this option will make this participant invisible to organizers and participants on the Participants page on the event website.',
    update_published_status: 'Edit Privacy Status',
    empty_transaction_title: 'No transactions',
    empty_transaction_subtitle: 'There are no transactions related to this event yet.',
    empty_sub_title: 'No submissions',
    empty_sub_subtitle: 'There are no submissions related to this event yet.',
    no_results_title: 'No results',
    no_results_subtitle: 'Please try again with a different spelling or other keywords and filters.',
    submission_decision: {
      decision: 'Decision',
      type: 'Type',
      bulk_edit_button: 'Edit decisions',
      bulk_confirm:
        "You're about to edit the decision of {count} submission. | You're about to edit the decision of {count} submissions.",
    },
    mailing_modal: {
      prep: 'Prepare your email',
      confirmation: 'Confirm your sending',
      send: 'Send',
    },
    assign_to_schedules_modal: {
      all: 'All sessions',
      list_button: 'Add to session',
      title: 'Add to session',
      steps: {
        prep: 'Select Session(s)',
        confirmation: 'Confirmation',
      },
      no_schedules: 'You must add session in order to be able to assign submissions.',
      schedules: 'Sessions',
      schedules_message: '',
      press_enter: 'Press enter',
      selector: 'Select a session',
      no_search_result: 'No session found. Consider changing the search query',
      confirmation: 'Confirmation',
      confirm_text: "You're about to assign {formEntry_count} submission(s) to {schedule_count} session(s).",
      all_schedules: 'All sessions',
    },
    datetime: {
      0: 'Registration date',
      1: 'Submission date',
      transactions: 'Transaction date',
      from: 'From',
      to: 'To',
      before: 'Before',
      after: 'After',
    },
    decisions: 'Presentation types',
  },
  global: {
    show_more: 'Show more',
    learn_more: 'Learn more',
    yes: 'Yes',
    no: 'No',
    save: 'Save',
    save_changes: 'Save changes',
    words: 'Words',
    confirm: 'Confirm',
    skip: 'Skip',
    confirmation: 'Confirmation',
    continue: 'Continue',
    yes_to_confirm: ' Type "delete" in the box below to confirm deletion.',
    close: 'Close',
    edit: 'Edit',
    edit_form: 'Edit form',
    cancel: 'Cancel',
    discard: 'Discard',
    create: 'Create',
    remove: 'Remove',
    loading: 'Loading...',
    maybe_later: 'Maybe Later',
    video_loading: 'This looks like a large file. Sit tight, upload might take a while.',
    generating: 'Generating...',
    select_all: 'All',
    clear_selection: 'Unselect all',
    export: 'Export',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    password: 'Password',
    address_line_1: 'Address',
    city: 'City',
    postal_code: 'Postal or ZIP',
    country_region: 'Country',
    state_province: 'Province / State',
    company: 'Company',
    empty_table: 'No data available',
    success_message: 'The information has been saved successfully.',
    pagination_prev: 'Previous',
    pagination_next: 'Next',
    back: 'Back',
    next: 'Next',
    options: 'Options',
    french: 'French',
    english: 'English',
    browser: 'Bilingual',
    change_detection_message: 'You have made changes.',
    by: 'by',
    link: 'Link',
    view: 'View',
    search_placeholder: 'Search',
    search_results_count: 'No results found | {count} result | {count} results',
    search_empty_state: {
      title: 'No results',
      subtitle: 'Please try again with a different spelling or other keywords and filters.',
    },
    more: 'More',
    select_unassigned_choice: 'Unassigned',
    select_default_placeholder: 'Select an option',
    select_filter_placeholder: 'Select a filter',
    please_select: 'Please select...',
    delete: 'Delete',
    all_rights_reserved: 'All rights reserved',
    pagination_display: 'Display',
    already_registered_message: 'Your account is already activated. Please log in.',
    event: 'Event',
    scroll_to: 'Scroll to',
    step: 'Step',
    delete_modal: {
      button: 'Delete',
      description:
        'Are you sure you want to delete the selected items? Please type the number of items to delete to confirm.',
      textbox_label: 'Number of elements to delete',
      participant: '{count} participant | {count} participants',
      abstract: '{count} submission | {count} submissions',
      review: '{count} review | {count} reviews',
      schedulePresentations: '{count} presentation | {count} presentations',
    },
    payment_methods: {
      stripe: 'Online (Stripe)',
      paysafe: 'Online (Paysafe)',
      touchnet: 'Online (TouchNet)',
      alipay: 'Online (Alipay)',
      publisoft: 'Online (Publisoft)',
      globalpayments: 'Online (Global Payments)',
      moneris: 'Online (Moneris)',
      bypass: 'Ignored',
      invoicing: 'Invoicing',
      void: 'Ignored',
    },
    transaction_status: {
      refund: {
        concluded: 'Refunded',
        pending: 'Pending',
        canceled: 'Canceled',
      },
      charge: {
        concluded: 'Paid',
        pending: 'Pending',
        canceled: 'Unpaid',
      },
    },
    transaction_type: {
      refund: 'Refund',
      charge: 'Payment',
    },
    submit: 'Submit',
    save_and_close: 'Save and close',
    until: 'until',
    opens_on: 'Opens on',
    closes_on: 'Closes on',
    closed: 'Closed',
    back_to_table: 'Back to table',
    actions: 'Actions',
    contact: 'Contact',
    paid_on: 'Paid on',
    time: 'Time',
    date: 'Date',
    view_all_info: 'View all info',
    full_view: 'Full view',
    last_modified: 'Last modified',
    no_location: 'No location',
    upload_file: 'Add file or drop it here',
    online_event: 'Online event',
    hybrid_event: 'Hybrid event',
    change_file: 'Change file',
    delete_image: 'Delete image',
    delete_video: 'Delete video',
    delete_file: 'Delete file',
    send: 'Send {count} email | Send {count} emails',
    select_all_entries: 'Select all',
    time_range: 'Time range',
    review: 'Review',
    terms_of_service: 'Terms of service',
    terms_of_service_url: 'https://fourwaves.com/terms-of-service/',
    privacy_policy: 'Privacy policy',
    privacy_policy_url: 'https://fourwaves.com/privacy-policy/',
    view_form: 'View form',
    add: 'Add',
    clone: 'Clone',
    missing_answer: 'Missing Answer',
  },
  datepicker: {
    days: {
      dim: 'Sun',
      lun: 'Mon',
      mar: 'Tue',
      mer: 'Wed',
      jeu: 'Thu',
      ven: 'Fri',
      sam: 'Sat',
    },
    months: {
      jan: 'Jan',
      feb: 'Feb',
      mar: 'Mar',
      april: 'Apr',
      may: 'May',
      june: 'Jun',
      july: 'Jul',
      aug: 'Aug',
      sep: 'Sep',
      oct: 'Oct',
      nov: 'Nov',
      dev: 'Dec',
    },
    placeholder: {
      date: 'Select a date',
      timeRange: 'Select a time range',
      time: 'Select',
    },
  },
  validation: {
    required: 'This field is required.',
    is: 'This field must be identical.',
    email: 'Your email address must be valid.',
    emails: 'not a valid email address.',
  },
  events: {
    index: {
      title: 'Events',
      create: 'New Event',
      empty_state: {
        active: {
          title: 'No active or upcoming events',
          subtitle: 'It looks a bit quiet in here.',
        },
        draft: {
          title: 'No drafts',
          subtitle: 'You have no events under construction at the moment.',
        },
        past: {
          title: 'No past events',
          subtitle: `But perhaps something exciting's coming up!`,
        },
        all: {
          title: 'No events',
          subtitle: 'Create your first event now!',
        },
      },
      filters: {
        all: 'All events',
        active: 'Active & Upcoming',
        draft: 'Drafts',
        past: 'Past',
      },
      format: {
        online: 'Virtual',
        physical: 'In person',
        hybrid: 'Hybrid',
      },
      time_status: {
        draft: 'Draft',
        live: 'Live now',
        past: 'Past',
        upcoming_days: 'In {count} day | In {count} days',
        upcoming_months: 'In {count} month | In {count} months',
      },
    },
    configuration: {
      event_startDate: 'From',
      event_endDate: 'To',
      startTime: 'From',
      endTime: 'To',
      is_online: 'Online event',
      is_physical: 'Physical event',
      is_hybrid: 'Hybrid event',
    },
    committee: {
      role: 'Role',
      invite_modal: {
        description: 'An email will be sent to each user with a link to activate their account or login.',
        email_label: 'Email Address',
        email_label_help: 'Type one or more email addresses.',
      },
      organizers: {
        role_name: 'Organizer',
        invite_button: 'Add Organizer',
        remove_modal_title: 'Remove Organizer',
        remove_modal_description: 'This organizer will lose access to the organizer panel of the event:',
        remove_modal_description_organization:
          'This user will lose access to all events in which they are listed as organizer or program chair.',
      },
      chairs: {
        role_name: 'Program Chair',
        invite_button: 'Add Chair',
        remove_modal_title: 'Remove Chair',
        remove_modal_description: 'This chair will lose access to the organizer panel of the event.',
      },
      reviewers: {
        invite_button: 'Add Reviewer',
        remove_modal_title: 'Remove Reviewer',
        remove_modal_description: 'This reviewer will no longer have access to the submissions of the event.',
      },
    },
    deletion: {
      modal_title: 'Event',
      modal_subtitle:
        'The event {eventName} will be deleted, as well as access to all related content. This action is permanent and cannot be undone.',
    },
    unavailable_feature: {
      modal_title: 'Feature Unavailable',
      modal_subtitle:
        'This feature is not included in your current plan. Please contact us at support@fourwaves.com if you wish to upgrade or if you have any questions.',
    },
    locked: {
      modal_title: 'Event Locked',
      modal_subtitle: 'Please Unlock you event before you can enjoy this feature.',
    },
    logos: {
      link: 'Link',
    },
    payments: {
      connect: 'Connect',
      connected: 'Connected',
      create_service_account: {
        link_label: 'Click here',
        stripe: ' to create a Stripe account and connect it to Fourwaves.',
        touchnet: ' to connect a TouchNet account to Fourwaves.',
        global_payments: ' to connect a Global Payments account to Fourwaves.',
        moneris: ' to connect a Moneris account to Fourwaves.',
      },
      title: {
        stripe: 'Stripe',
        touchnet: 'TouchNet',
        global_payments: 'Global Payments',
        moneris: 'Moneris',
      },
    },
    taxes: {
      title: 'Taxes',
      sub_title:
        'Collect taxes on your transactions. You can customize the taxable options when you create your registration and submission forms.',
      gst: 'GST/HST tax ID',
      gst_description: '9 digits followed by RC or RT, followed by 4 reference digits',
      qst: 'QST tax ID',
      qst_description: '10-digit NEQ, followed by TQ, followed by 4 reference digits',
      vat: 'VAT tax ID',
      vat_description: '',
      pst: 'PST tax ID',
      pst_description: '',
      receipt_header: 'Billing info',
      receipt_header_description:
        'Specify the billing address, payment terms, etc. Will be displayed at checkout and on invoices.',
      billing_address: 'Billing info',
    },
    pages: {
      registration: {
        configuration: {
          title: 'Configuration',
          button_label: 'Edit',
          start_date: 'Start Date',
          end_date: 'End Date',
          add_section: 'Add section',
          add_field: 'Add field',
          add_field_title: 'Select a field type',
          add_field_choice: 'Add a choice',
        },
        email_confirmation: {
          title: 'Confirmation',
          notice_html: 'Edit the email sent when the registration is completed.',
          button_label: 'Edit',
        },
      },
      configuration: {
        title: 'Configuration',
        notice_html: 'Setup the visibility of your page.',
        button_label: 'Edit',
        page_setup_button_label: 'Page setup',
      },
      builder: {
        empty_title: 'This page looks pretty empty',
        empty_subtitle: 'All it needs is a little content to come to life!',
        add_content_block: 'Add content block',
        add_content_block_title: 'Select a content type',
        content_types: {
          legacyrichtexteditor: 'Rich text editor',
          richtexteditor: 'Text',
          textimage: 'Text and Image',
          images: 'Image gallery',
          file: 'Files',
          speakers: 'Speakers',
          logos: 'Logos',
          accordion: 'Accordion',
        },
        content_title: 'Content title',
        content_description: 'Description',
        content_size: 'Size',
        image_alignment: 'Image alignment',
        image_alignements: {
          left: 'Left',
          right: 'Right',
        },
        no_title: 'No title',
        remove_modal_title: 'Remove a content block',
        remove_modal_content: 'Do you really want to delete this content block?',
        sidenav_title: 'Content Blocks',
        sizes: {
          small: 'Small',
          medium: 'Medium',
          large: 'Large',
        },
        speaker_title: 'Speaker',
        add_speaker: 'Add speaker',
        speaker: {
          name: 'Name',
          affiliation: 'Affiliation',
          biography: 'Biography',
          headshot: 'Headshot',
        },
        logo_title: 'Logo',
        add_logo: 'Add logo',
        logo_link: 'External Link',
        accordion: {
          section_title: 'Accordion section',
          add: 'Add section',
          title: 'Title',
          description: 'Description',
        },
        download_file: 'Download file',
        download_image: 'Download image',
        delete_file: 'Delete file',
        edit_content: 'Edit content block',
        delete_content: 'Delete content block',
      },
      ordering: {
        title: 'Website menu',
        new_page: 'Create Page',
        delete_group_subtitle: 'Do you really want to delete this group? The pages it contains will not be deleted.',
        delete_page_cascade_effect: 'content blocks inside this page',
        content_pages_disabled: 'Your plan does not allow the creation of new pages.',
        create_sub_menu: 'Create Group',
        save: 'Save',
      },
      schedule: {
        empty_title: 'No sessions',
        empty_subtitle: 'Your schedule looks pretty quiet (for now).',
        add_session: 'Add Session',
        virtual_poster_session: 'Virtual Poster session',
        zoom_embed: 'Zoom embed',
        liveStreamTypes: {
          none: 'None',
          external: 'External Platform (Zoom, YouTube, Vimeo)',
          virtualpostersession: 'Virtual Poster Session',
          zoomembed: 'Zoom Embed (Beta)',
        },
        presentationTimesType: {
          none: 'None',
          equal: 'Equal length',
          unequal: 'Unequal length',
        },
        room: {
          label: 'Room',
          select: 'Select a room',
          create: 'Create Room',
          edit: 'Edit Room',
          delete: 'Delete',
          delete_cascade_effect: 'all relations this room had with sessions',
          remove_modal_title: 'Delete room',
          remove_modal_content:
            'This room will no longer be associated with any session and will be permanently deleted from your event.',
        },
        recorded_video: 'Video',
        livestream: 'Live stream',
        room_name: 'Name',
        livestream_link: 'External link (Zoom, YouTube, Vimeo)',
        zoomembed_meeting_number: 'Meeting ID',
        zoomembed_meeting_number_help: 'Zoom Meeting or Webinar ID. Ex. 883 9252 9190.',
        zoomembed_meeting_password: 'Passcode',
        zoomembed_meeting_password_help: 'Leave empty if none.',
        remove_modal_title: 'Delete Session',
        remove_modal_content: 'Do you really want to delete this session?',
        title: 'Session name',
        description: 'Description',
        presentation: {
          title: 'Presentations',
          attach_submission: 'Add presentation',
          search_placeholder: 'Search by title, presenter name, topics, etc...',
          total_count: 'Abstract(s)',
          no_submission: 'There are no abstracts to show',
          presented_by: 'Presented by',
          remove_all_presentations: 'Remove all',
          remove_modal_item: 'Do you really want to remove {submission} from {session}',
          remove_modal_title: 'Remove presentation',
          remove_modal_content: 'Do you really want to remove this presentation from this session?',
          remove_all_modal_title: 'Remove {count} presentation | Remove {count} presentations',
          remove_all_modal_description: 'Are you sure you want to remove all presentations from {session}',
          remove_all_modal_textbox_label: 'Number of elements to remove',
          remove_all_modal_button_confirm: 'Remove',
          view_all: 'View all {0} presentations',
          edit_length: 'Edit Duration',
          vps_alert: {
            title: 'Missing Presentations',
            message:
              "Warning, you need to add presentations to activate the video conversations during this virtual poster session. See <u><a href='https://help.fourwaves.com/en/articles/8760840-set-up-virtual-poster-sessions' target='_blank'>this article</a></u> for more information.",
          },
        },
        presentation_times_type: 'Presentation times',
        presentation_times_type_desc: 'Do the presentations in this session have presentation times?',
        tracks: {
          name: 'Tracks',
          assign_tracks: 'Assign track',
          delete_cascade_effect: 'all relations this track had with sessions and presentations',
          add_button: 'New Track',
          empty_state: 'No tracks have been created yet.',
        },
        export: {
          modal_title: 'Export Schedule',
          file_type_label: 'File Type',
          file_type_word: 'Word - List View',
          file_type_pdf: 'PDF - Grid View',
          schedule_format_label: 'Format',
          schedule_format_simplified: 'Simplified',
          schedule_format_detailed: 'Detailed',
        },
      },
      confirmation_email: {
        back_to_submission: 'Back',
        back_to_registration: 'Back to registration',
        back_to_review: 'Back to reviews',
        email_confirmation: 'Email confirmation',
        subject_label: 'Subject',
        message: 'Message',
        attach_files: 'Attach files',
        insert_form_value: 'Insert form value',
        form_values: {
          event_name: 'Event name',
          event_start_date: 'Conference start date',
          event_end_date: 'Conference end date',
          first_name: 'First name',
          last_name: 'Last name',
          account_link: 'Link to account page',
          review_link: 'Link to reviews',
          abstract_title: 'Submission title',
          abstract_id: 'Submission ID',
        },
        description: 'Description',
        virtual_poster_session: 'Virtual Poster session',
      },
    },
    forms: {
      section_nav_title: 'Form Sections',
      field_edit: {
        under_construction: 'Under creation...',
        properties: 'Properties',
        pricing: 'Pricing',
        label: 'Label',
        short_label: 'Internal name',
        short_label_desc: 'Identifies this item in reports, invoices and emails.',
        allowed_file_types: 'Allowed file types',
        has_max_words: 'Word limit',
        max_words: 'Maximum number of words',
        max_review_score: 'Max score',
        allow_basic_styles: 'Allow basic styles (bold, italic, underline)',
        description: 'Description',
        add_description: 'Add a description',
        is_required: 'Mandatory',
        is_organizers_only: 'Hidden field',
        conditionnal_logic: 'Conditional Logic',
        is_organizers_only_desc: 'Only organizers can see this field.',
        limit: 'Limit',
        word_limit: 'Word limit:',
        has_quantity: 'Limit quantity',
        max_quantity: 'Quantity',
        max_quantity_desc: 'Choose the maximum quantity for this item.',
        cost: 'Cost',
        late_cost: 'Variable cost',
        late_cost_hint: 'Automatically change the cost at a specific date.',
        late_cost_date: 'Effective date',
        late_cost_date_desc: 'The cost will automatically change to the late cost at the time specified.',
        tax_rules_label: 'Tax rules',
        tax_rules: {
          none: 'No tax',
          qstallgstall: 'QST 9.975% and GST 5%',
          qstquebecgstcanada: 'QST 9.975% and GST 5% in Quebec, GST 5% rest of Canada',
          qstquebecgstall: 'QST 9.975% and GST 5% in Quebec, GST 5% rest of the world',
          gstall: 'GST 5%',
          gst5canada: 'GST 5% in Canada',
          gst5pst7: 'GST 5% and PST 7%',
          gst5pst6: 'GST 5% and PST 6%',
          gst10all: 'GST 10%',
          gst15all: 'GST 15%',
          hst13all: 'HST 13%',
          hst15all: 'HST 15%',
          hst13canada: 'HST 13% in Canada',
          vat77all: 'VAT 7.7%',
          vat10all: 'VAT 10%',
          vat14all: 'VAT 14%',
          vat19all: 'VAT 19%',
          vat20all: 'VAT 20%',
          vat21all: 'VAT 21%',
          vat22all: 'VAT 22%',
          vat24all: 'VAT 24%',
          vat25all: 'VAT 25%',
          vat25denmark: 'VAT 25% in Denmark',
          vat19germany: 'VAT 19% in Germany',
          vat19europe: 'VAT 19% in Europe',
          vat0: 'VAT 0%',
          vatreversecharge0: 'VAT reverse charge 0%',
        },
        tax_rules_add_to_cost: 'Add taxes to the cost',
        tax_rules_included: 'Include taxes in the cost',
        availability_period: 'Availability period',
        start_date: 'From',
        end_date: 'To',
        instructions: 'Instructions',
        section_cant_remove_modal_title: "Section can't be deleted",
        section_cant_remove_modal_content: "The following fields can't be deleted:",
        section_delete_cascade_effect: 'all fields inside this section and their related user data',
        field_delete_cascade_effect: 'all related user data',
        choice_delete_cascade_effect: 'all related user data and any display conditions that could be linked to it',
        open_contextual_menu_aria: 'Open {field} contextual menu',
        file_types: {
          all: 'Any type',
          image: 'Images (jpg, png, gif)',
          pdf: 'Adobe Acrobat Reader (pdf)',
          doc: 'Microsoft Word and text (docx, doc)',
        },
        field_types: {
          text: {
            name: 'Short Answer',
            default_label: '',
          },
          textarea: {
            name: 'Long Answer',
            default_label: '',
          },
          email: {
            name: 'Email Address',
            default_label: '',
          },
          checkboxes: {
            name: 'Multi Select',
            default_label: '',
          },
          radios: {
            name: 'Single Select',
            default_label: '',
          },
          dropdown: {
            name: 'Dropdown',
            default_label: '',
          },
          file: {
            name: 'File Upload',
            default_label: '',
          },
          images: {
            name: 'Figures',
            default_label: 'Figures',
          },
          richtext: {
            name: 'Rich text',
            default_label: '',
          },
          instructions: {
            name: 'Instructions',
            default_label: '',
          },
          poster: {
            name: 'Poster',
            default_label: 'Poster',
          },
          slides: {
            name: 'Slides',
            default_label: 'Slides',
          },
          video: {
            name: 'Video',
            default_label: 'Video',
          },
          score: {
            name: 'Score',
            default_label: 'Score',
            placeholder: 'Score: {min} to {max}',
          },
          countrylist: {
            name: 'Country list',
            default_label: 'Country list',
          },
        },
        pricing_tab: {
          errors: {},
        },
        conditions: {
          tab_title: 'Conditions',
          cant_add_condition: "This field can't have conditions.",
          add_button: 'Add a condition choice',
          action: {
            this_field_should_be: 'This field should be',
            show: 'Visible',
            hide: 'Hidden',
          },
          logic: {
            when: 'Only when',
            all: 'All',
            any: 'Any',
            these_conditions: 'Of these',
            are_valid: 'are selected:',
            is_valid: 'is selected:',
          },
          choice: {
            is_set_to: 'is set to',
          },
          errors: {
            no_choices: 'You need to add at least one condition.',
            incomplete_choices: 'One or more conditions are incomplete.',
            duplicate_choice: 'Some choices have been selected twice or more.',
            all_same_single_select: 'Two or more choices from same single select field are used.',
          },
          choice_remove_modal_title: 'Remove a condition choice',
          choice_remove_modal_content:
            "Warning! Removing this choice from the condition will change the field's display logic inside the form.",
          is_conditional: 'Conditional',
        },
        section_add: 'Add a section',
        section_label: 'Section title',
        section_description: 'Description',
      },
      submission_decision_types: {
        pending: 'Pending',
        accepted: 'Accepted',
        rejected: 'Rejected',
      },
    },
    publish: {
      title: 'Publish an event',
      message: 'Your event website will be published online. Do you wish to continue?',
      button: 'Publish',
    },
    unlock: {
      title: 'Unlock Event',
      choose_package: {
        title: 'Choose your package',
        subtitle: '<a href="https://fourwaves.com/pricing" target="_blank">Learn more about our pricing</a>',
        free: 'Free',
        essentials: 'Essentials',
        advanced: 'Advanced',
        professional: 'Pro',
        custom: 'License Code',
      },
      licensepicker: {
        title: 'Enter your license',
        code: 'License Code',
      },
      billing: {
        title: 'Billing information',
        unlock: 'Unlock my event',
      },
      confirmation: {
        title: 'All set!',
        message: 'Event unlocked',
        publish: 'Publish the event',
        later: 'Later',
      },
      unlock: 'Unlock',
      terms_input_label:
        "I accept the <a href='https://fourwaves.com/terms-of-service/' target='_blank'>Terms of Service</a>",
      confirmation_message: 'I confirm I want to unlock this event',
      company: 'University, organization or company name',
      contact_support: 'If you would like to use this plan, please contact us at support@fourwaves.com.',
    },
    tables: {
      upvotes: 'Upvotes',
      quick_view: 'Quick view',
      creation_date: 'Creation date',
      last_update_date: 'Last modified',
      abstract_id: 'ID',
      is_public: 'Public',
      review_status: 'Review status',
      review_score: 'Review score',
      review_score_std_dev: 'Standard deviation',
      submitted: 'Submitted',
      status: 'Status',
      status_items: {
        on_waiting_list: 'On waitlist',
        registered: 'Registered',
        pending: 'Pending',
        submitted: 'Submitted',
      },
      flags: 'Flags',
      flags_items: {
        unpaid_items: 'Unpaid items',
        is_missing_required_fields: 'Missing required fields',
        not_registered: 'Not registered',
        pending_payment: 'Pending payment',
        conflict: 'In conflict',
      },
      schedule_filter: 'Session',
      full_name: 'Full name',
    },
    abstracts: {
      title: 'Submissions',
      pending_your_decision: 'No decision',
      date_on: 'on',
      decisions: {
        title: 'Decisions',
        empty: 'No presentation types have been set up for this event.',
        accepted: 'Accepted',
        rejected: 'Rejected',
        pending: 'Pending',
      },
    },
    reviews: {
      page_title: 'Reviews',
      id: 'ID',
      abstract_id: 'Submission ID',
      title: 'Title',
      reviewer: 'Reviewer',
      hidden_reviewer: '[Hidden Reviewer]',
      status: 'Review status',
      statuses: {
        pending: 'Pending',
        conflictofinterest: 'Conflict of interests',
        completed: 'Completed',
        unassigned: 'Unassigned',
      },
      status_no_review: 'No reviews',
      back_to_list: 'Back to reviews',
      assign_modal: {
        assign_reviewers: 'Assign reviewers',
        list_button: 'Assign reviewers',
        steps: {
          select: 'Select Reviewers',
          method: 'Assignation Method',
          confirmation: 'Confirmation',
        },
        disabled_title: 'No reviews to assign',
        disabled: 'Please change the parameters or the selection of submissions or reviewers.',
        reviewers_message: '',
        assignation_message:
          'Assign submissions manually to reviewers or randomly distribute them with the desired number of evaluations per submission.',
        assignation_method: 'Assignation method',
        randomly_distribute: 'Random distribution',
        manual: 'Manual distribution',
        reviews_per_submission: 'Number of reviews per submission',
        reviewers: 'Reviewers',
        confirmation: 'Confirmation',
        confirm_text: "You're about to assign {review_count} review(s) to {reviewer_count} reviewer(s).",
        selector: 'Choose a reviewer',
        press_enter: 'Press enter',
        all_reviewers: 'All reviewers',
        review_step_count: 'Steps {count} of {total}',
        no_search_result: 'No reviewers found. Consider changing the search query.',
        no_reviewers: 'You must add reviewers in order to be able to assign submissions.',
        notify_message: 'Notify reviewers by email',
      },
      form: {
        conflict_description: 'Are you sure you want to declare a conflict of interest?',
        revert_conflict_description: 'Are you sure you want to revert the conflict of interest?',
        evaluate: 'Review form',
        cancelled: 'Conflict of interest declared',
        completed: 'Evaluation completed',
        submitted: 'Evaluation submitted',
        declare_conflict: 'Declare Conflict of Interest',
        revert_conflict: 'Revert Conflict of Interest',
        revert: 'Revert',
        review_period_unavailable: 'Review period is unavailable. You cannot review or modify a review.',
        edit: 'Edit Review',
      },
    },
    dashboard: {
      title: 'Event Overview',
      type: {
        Physical: 'In Person',
        Online: 'Virtual',
        Hybrid: 'Hybrid',
      },
      vps_enabled: 'Virtual Poster Sessions Module Enabled',
      menu: {
        export: 'Export Usage Data',
        clone: 'Clone Event',
        delete: 'Delete Event',
        copy: 'Copy Event Website Link',
        event_id: 'Event ID',
        publish: 'Publish',
        aria: 'Toggle the event menu',
      },
      registration: {
        stats_pending_payment: 'With a pending payment',
        stats_registered: 'Registered',
        stats_waitlist: 'On waitlist',
        stats_with_abstract: 'With a submission',
        header: 'Registrations',
        action_period: 'Change dates',
        period: 'Registration period',
        confirmation_email: 'Confirmation email',
        confirmation_email_text: 'Edit the email sent after a registration.',
        action_confirmation_email: 'Edit email',
        card: {
          title: 'Registration',
          view: 'View Registration',
          created: 'Registered',
          edit: 'Edit Registration',
          empty_state: 'The submitter has not registered to this event.',
          empty_state_user: `You're not registered to this event.`,
        },
      },
      reviews: {
        header: 'Reviews',
        pending: 'Pending',
        completed: 'Completed',
        conflict_of_interest: 'Conflict of interest',
        period: 'Review period',
        action_period: 'Change dates',
      },
      submission: {
        header: 'Submissions',
        stats_submitted: 'Submitted',
        stats_accepted_for: 'Accepted for',
        stats_waiting: 'Waiting',
        stats_conflict: 'In conflict',
        period: 'Submission period',
        action_period: 'Change dates',
        confirmation_email: 'Confirmation email',
        confirmation_email_text: 'Edit the email sent after a submission.',
        action_confirmation_email: 'Edit email',
        card_title: 'Submission',
        card_edit: 'Edit Submission',
      },
      transactions: {
        header: 'Payments',
        stats_total: 'Paid',
        stats_net_profit: 'Profit',
        stats_total_refunded: 'Refunded',
        stats_transactions: 'Payments',
        stats_refunds: 'Refunds',
        stats_transactionFees: 'Transaction fees',
        bank_account: 'Bank account',
        action_view: 'View',
        stripe_connected: 'Stripe account Connected',
        not_connected: 'No account connected',
        action_connect: 'Connect',
        payment_settings: 'Payment settings',
        payment_settings_text: 'Change payment settings.',
      },
      vps: {
        header: 'Virtual Poster Session',
        description: 'This event benefits from the functionality of virtual poster sessions.',
        export: 'Export usage data',
        export_modal_title: 'Export Usage Data',
        export_modal_content: 'An Excel file will be sent to your email inbox within the next hour.',
        dates: 'Virtual poster sessions on your schedule',
        no_dates_to_show: 'There is no virtual poster session on your schedule',
      },
      communications: {
        header: 'Communications',
      },
    },
    participant: {
      state_registered: 'Registered',
      state_waitlist: 'On waitlist',
      transactions: 'Transactions',
      emails: 'Emails',
      info: 'Info',
      view_receipt: 'View invoice',
      back_to_list: 'Back to participants',
      accept_from_waitlist: {
        title: 'Accept from Waitlist',
        message:
          'Accept <strong>{name}</strong> on the registrant list? An email will be sent with a link to complete the form. This action is irreversible.',
      },
    },
    status: {
      under_construction: 'Under construction',
      published: 'Published',
      archived: 'Passed',
    },
    abstract: {
      title: 'Submission',
      submitted_on: 'Submitted on',
      info: 'Info',
      back_to_list: 'Back to submissions',
      no_title: 'No title',
      no_abstract: 'No submission.',
      evaluate_abstract: 'Evaluate submission',
      criteria_count: 'Criteria 2 of 4',
      reviews: 'Reviews',
      summary: 'Summary',
      completed_reviews: 'review(s) completed',
      pending_review: 'Review pending',
      edited_review: 'Edited',
      conflicted_review: 'Conflict of interest',
      score: 'Score',
      average_score: 'Average score',
      average_total_score: 'Average Total Score',
      sessions: 'Sessions',
      go_to_presentation: 'View presentation',
      empty_reviews_title: 'No reviewers assigned',
      empty_reviews_subtitle: 'No reviewers have been assigned this submission yet.',
      empty_reviews_subtitle_submitter:
        'No reviewers have been assigned your submission yet. Please come back later :)',
      empty_reviews_not_shared: 'Review results not available',
    },
    mailing: {
      title: 'Send Email',
      subject: 'Subject',
      message: 'Message',
      count_notice: 'Your email will be sent to {count} recipient. | Your email will be sent to {count} recipients.',
      sending_in_progress: 'Sending in progress',
      success_message: 'Your message has been sent successfully.',
      confirmation_title: 'Confirmation',
      confirmation: 'Your email will be sent to {count} recipient. | Your email will be sent to {count} recipients.',
      attachment: 'Attachment',
      attachment_wait: 'Please wait until attachment upload is completed',
    },
    badge_modal: {
      title: 'Badge Generator',
      steps: {
        select: 'Select Badge Options',
        confirmation: 'Preview and Generate',
      },
      generate_button: 'Generate badges',
      select_format: 'Please select a format',
      format: {
        5390: '8 per page (2¼″ x 3½″)',
        5392: '6 per page (3″ x 4″)',
        5395: '8 per page (2⅓″ x 3⅜″)',
      },
      select_custom_field: 'Please select a custom field to display',
      custom_field: {
        none: 'None',
      },
      preview: 'Preview',
    },
    poster_tags_modal: {
      title: 'Poster Tags Generator',
      generate_button: 'Generate poster tags',
      id: '#',
      by: 'By',
      preview: 'Preview',
      preview_title: 'Submission title',
    },
    advanced_booklet_modal: {
      generate_booklet: 'Generate booklet',
      subtitle: 'Select the information you wish to include in the booklet',
      form_fields: 'Form fields',
      select_all: 'All',
    },
  },
  account: {
    login: {
      title: 'Log in',
      button: 'Log in',
      sso_required: 'Single sign-on enabled',
    },
    profile: {
      affiliation: 'Affiliation',
      bio: 'Bio',
      website_url: 'Website',
      twitter_url: 'X (Twitter)',
      linkedin_url: 'LinkedIn',
      account_title: 'Account',
      profile_title: 'Profile',
      password_title: 'Password',
      profile_picture: 'Profile picture',
      contact_information: 'Profile information',
      social_networks: 'Social networks',
      newsletter: 'I agree to receive emails from Fourwaves regarding product updates for event organizers.',
      optional: 'optional',
      finish: 'Finish account activation',
      edit: {
        button_label: 'Edit profile',
        emailSent_message: 'Please confirm your email by clicking on the link sent to you.',
        confirm_message: 'Your email has been successfully modified!',
      },
      'create-password': {
        new_password: 'Create your password',
      },
      'change-password': {
        button_label: 'Change password',
        current_password: 'Current password',
        new_password: 'New password',
        confirm_password: 'Confirm new password',
      },
      'delete-account': {
        button_label: 'Delete Account',
        modal_item: 'Your Account',
        modal_subtitle:
          'This will also delete all the content and data related to your account. This action is permanent and cannot be undone.',
      },
    },
    forgot_password: {
      title: 'Get a new password',
      confirmation_message: 'A link was sent by email.',
      button: 'Send link by email',
      description: 'Enter the email address of your account.',
    },
    reset_password: {
      title: 'Reset your password',
      confirmation_message: 'Your password has been reset.',
    },
    restricted_access: {
      title: 'Restricted access',
      message: 'You must be registered to attend the <a href="{link}">event</a>.',
    },
    register: {
      terms:
        "By submitting this form, you agree to the <a href='https://fourwaves.com/terms-of-use/' target='_blank'>Terms of Use</a> and the <a href='https://fourwaves.com/privacy-policy/' target='_blank'>Privacy Policy</a>.",
    },
    complete_registration: {
      title: 'Activate your account',
      subtitle:
        'Your Fourwaves account is required to access livestreams, virtual poster sessions and other interactive features. It is also required to edit the events you organize.',
    },
    complete_profile: {
      title: 'Complete your profile',
      subtitle:
        'Help event participants learn more about you. You can update your profile later through your Fourwaves account.',
    },
    not_found: {
      title: 'This account cannot be found',
      message: 'The email you used is not linked to an account.',
      link: 'Back to the login page',
    },
    reset_password_denied: {
      title: 'Please contact your system administrator',
      message: 'Single Sign-On Users must contact their System Administrator directly to reset their password.',
      link: 'Back to the login page',
    },
  },
  submissions: {
    title: 'Submissions',
    more_sessions: '+ {count} more session | + {count} more sessions',
    table_columns: {
      title: 'Title',
      presenting_on: 'Presenting on',
    },
    empty_state: {
      title: 'No submissions',
      subtitle: 'You have not submitted any work yet.',
    },
  },
  transactions: {
    title: 'Transactions',
    view_invoice: 'View invoice',
    paidOn: 'Paid on',
    recentTransactions: 'Recent transactions',
    table_columns: {
      event: 'Event',
      date: 'Transaction Date',
      payment_method: 'Payment Method',
      amount: 'Amount',
      status: 'Status',
    },
    table_status: {
      pending: 'Pending',
      concluded: 'Paid',
      canceled: 'Declined',
      refunded: 'Refunded',
    },
    empty_state: {
      title: 'No transactions',
      subtitle: 'You have not made any transactions related to an event yet.',
    },
  },
  clipboard_btn: {
    success_message: 'The website link has been copied to your clipboard',
    email_success: 'The email has been copied to your clipboard',
    error_message: 'An error occured',
  },
  hints: {
    send_email_unlock: 'Unlock the event to send emails.',
    send_email_plan: 'Your plan does not allow sending email.',
    badges_plan: 'Your plan does not allow badge generation.',
    abstract_booklet_plan: 'Your plan does not allow abstract booklet generation.',
  },
};
