
import { Component, namespace, Vue, Prop, Emit } from 'nuxt-property-decorator';
import { RouteMapItem, OrganizerNavItem } from '../types';
import NavItem from './NavItem.vue';

const EventsModule = namespace('events');
const SettingsModule = namespace('settings');

@Component({
  components: {
    NavItem,
  },
})
export default class DataSideNavItems extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean;
  @EventsModule.State submissionFormId!: string;
  @EventsModule.State registrationFormId!: string;
  @EventsModule.Getter isReviewingFeatureEnabled!: boolean;
  @SettingsModule.Getter isOrganizer!: boolean;

  @Emit()
  onToggle() {
    return OrganizerNavItem.Data;
  }

  get routesMap(): RouteMapItem[] {
    return [
      {
        visibility: this.isOrganizer,
        to: { name: 'events-data-form-entries-formId', params: { formId: this.registrationFormId } },
        label: this.$t('navigation.organizer.event_data.registrations'),
        dataTestId: 'registrations-page',
      },
      {
        visibility: true,
        to: { name: 'events-data-form-entries-formId', params: { formId: this.submissionFormId } },
        label: this.$t('navigation.organizer.event_data.submissions'),
        dataTestId: 'abstracts-page',
      },
      {
        visibility: this.isOrganizer,
        to: { name: 'events-data-transactions' },
        label: this.$t('navigation.organizer.event_data.transactions'),
        dataTestId: 'transactions-page',
      },
      {
        visibility: this.isReviewingFeatureEnabled,
        to: { name: 'events-data-reviews' },
        label: this.$t('navigation.organizer.event_data.reviews'),
        dataTestId: 'reviews-page',
      },
      {
        visibility: this.isOrganizer,
        to: { name: 'events-data-communications' },
        label: this.$t('navigation.organizer.event_data.communications'),
        dataTestId: 'communications-page',
      },
    ];
  }

  get isParentActive() {
    return this.routesMap.some(({ to }) => this.$route.name?.split('___')[0].startsWith(to?.name || ''));
  }

  created() {
    if (this.isParentActive) this.onToggle();
  }
}
