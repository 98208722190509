import { MutationTree, ActionTree } from 'vuex';
import { RootState, LayoutState } from '~/types';

export const state = (): LayoutState => ({
  isGoogleMapsLoaded: false,
});

export const mutations: MutationTree<LayoutState> = {
  setIsGoogleMapsLoaded(state: LayoutState, loaded: boolean): void {
    state.isGoogleMapsLoaded = loaded;
  },
};

export const actions: ActionTree<LayoutState, RootState> = {
  setIsGoogleMapsLoaded({ commit }, value: boolean): void {
    commit('setIsGoogleMapsLoaded', value);
  },
};
