import { EmailVariableDto } from '../models';

// [^% ] to allow side by side tags. Allow any character except ampersand and space.
const regExp = /%.[^% ]*%/gi;

export function transposeEmailModel(text: string, variables: EmailVariableDto[]) {
  if (!text) return text;
  return text.replace(regExp, match => {
    const variable = variables.find(v => getHtmlTag(v.userFriendlyTag) === match);
    return variable != null ? variable.tag : match;
  });
}

// Once in RTE, our "&><" are gonna be encoded and match will fail.
// We must decode in the find.
function getHtmlTag(tag: string): string {
  return tag.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}
