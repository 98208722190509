
import { Component, Prop, Watch, mixins } from 'nuxt-property-decorator';
import { ChangeDetectionMixin } from 'fourwaves-shared';

@Component({})
export default class ChangeDetectionBox extends mixins(ChangeDetectionMixin) {
  @Prop({ required: true }) checkPoint: any;
  @Prop({ required: true }) model: any;
  @Prop(Boolean) readonly navLess!: boolean;

  @Watch('checkPoint', { immediate: true })
  public resetChangeDetection() {
    this.preventLostChanges('model', this.checkPoint);
  }

  public async onClickDiscard() {
    this.$emit('discard', this.checkPoint);
    await this.$nextTick();
    this.resetChangeDetection();
  }
}
