
import { Component, Watch, Ref, Prop, namespace, Vue } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { GlobalFilterEvents } from 'fourwaves-shared';
import { Collapsible } from 'fourwaves-shared/components';

const EventsModule = namespace('events');

@Component({
  components: {
    Collapsible,
  },
})
export default class LangChooser extends Vue {
  @EventsModule.State language!: boolean;

  @Prop(Boolean) readonly dark!: boolean;

  @Ref('optionsMenuContainer') readonly optionsMenuContainer?: HTMLMenuElement;
  @Ref('selectButton') readonly selectButton?: HTMLElement;

  isOpen = false;
  GlobalFilterEvents = GlobalFilterEvents;
  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;

  get selectedOption() {
    return this.$i18n.locale;
  }

  @Watch('language', { immediate: true })
  onEventLanguageChange(newLang: string) {
    if (newLang === 'french') {
      this.$i18n.setLocale('fr');
    } else if (newLang === 'english') {
      this.$i18n.setLocale('en');
    }
    // we keep the same language if "browser"
  }

  @Watch('$route')
  onRouteChanged() {
    this.isOpen = false;
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;

    if (isOpen) {
      document.body.addEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$on(GlobalFilterEvents.SelectOpen, this.closeTray);
    } else {
      document.body.removeEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
    }
  }

  async mounted() {
    await this.$nextTick();
    if (!this.optionsMenuContainer) return;
    this.simplebarInstance = new SimpleBar(this.optionsMenuContainer);
    await this.$nextTick();
    this.optionsMenuContainer?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
    this.scrollElement = this.simplebarInstance.getScrollElement();
  }

  beforeDestroy() {
    document.body.removeEventListener('click', () => {
      this.closeTray();
    });
    this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
  }

  public changeLanguage(code: 'fr' | 'en') {
    if (this.$i18n.locale === code) return;
    this.$moment.locale(code);
    this.$i18n.setLocaleCookie(code);
    this.$router.push(this.switchLocalePath(code));
  }

  public isLanguageActive(code: string) {
    return this.$i18n.locale === code;
  }

  public onButtonClick() {
    if (!this.isOpen) this.$nuxt.$emit(GlobalFilterEvents.SelectOpen);
    this.isOpen = !this.isOpen;
  }

  public tabTrapHandle(index: number): string | null {
    return index === 0 ? 'first' : index === this.$i18n.locales.length - 1 ? 'last' : null;
  }

  public closeTray(focusButton = false) {
    this.isOpen = false;
    if (focusButton) this.selectButton?.focus();
  }

  public onTrayClose() {
    if (!this.scrollElement) return;
    this.scrollElement.scrollTop = 0;
  }
}
