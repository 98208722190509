import { Middleware } from '@nuxt/types';
import { SettingsDto } from 'fourwaves-shared';

const enforceFormEntryOwnerMiddleware: Middleware = context => {
  if (!context.$auth.$state.loggedIn) return;

  const settings: SettingsDto | null = context.store.state.auth?.user || null;
  const isFormEntryOwner = settings?.permittedFormEntryIds.includes(context.route.params.id) || false;
  if (!isFormEntryOwner) {
    const errorMessage = context.app.i18n.t('shared.error.server_validation.authorization_error');
    context.app.$toast.error(errorMessage);
    context.$auth.redirect('home');
  }
};

export default enforceFormEntryOwnerMiddleware;
