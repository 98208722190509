export interface SelectOption<T = string | number | boolean> {
  id: T;
  label: string | object;
  icon?: string;
}

export function createSelectOption(id: string | number | boolean, label: string | object, icon?: string): SelectOption {
  return {
    id,
    label,
    icon,
  };
}
