export default {
  bank_account: {
    code: 'Gateway Code',
    provider: 'Provider',
    provider_select: 'Select a provider',
    provider_alert:
      'Please note that once you have connected a provider to this bank account, you won’t be able to remove or edit it later.',
    connected: 'Connected',
    view_details: 'View details',
    currency: 'Default Currency',
    stripe: {
      name: 'Stripe',
      action_title: 'Link your Stripe account to Fourwaves',
      action_subtitle: 'You might need to create it first',
      action_cta: 'Go to Stripe',
      error_message: 'Please connect your Stripe account.',
    },
    globalpayments: {
      name: 'Global Payments',
      modal: {
        title: 'Configuration Global Payments',
        app_key: 'App Key',
      },
    },
    touchnet: {
      name: 'TouchNet',
      modal: {
        title: 'Configuration TouchNet',
        tlink_url: 'TLink URL',
        upay_site_url: 'uPay Site URL',
        upay_site_id: 'uPay Site ID',
      },
    },
    moneris: {
      name: 'Moneris',
      modal: {
        title: 'Configuration Moneris',
        tlink_url: 'TLink URL',
        upay_site_url: 'uPay Site URL',
        upay_site_id: 'uPay Site ID',
      },
    },
    creation_modal: {
      title: 'Create Bank Account',
      info: 'General Info',
      connection: 'Connect Payment Gateway',
    },
    edition_modal: {
      title: 'Edit Bank Account',
      subtitle: 'General Info',
    },
    delete_modal: {
      title: 'Delete Bank Account',
      subtitle: 'Are you sure you want to delete this bank account?',
      item: 'Bank Account',
      unavailable_title: 'Deletion Unavailable',
      unavailable_subtitle:
        'This bank account is connected to one or more events. Please unlink all events before you can delete this bank account.',
    },
    request_connection_state: {
      accepted: 'Approved',
      waiting: 'Pending Approval',
      refused: 'Rejected',
      correctionrequired: 'Modification Required',
    },
    decision_modal: {
      title: 'Connection request',
      connection_form: 'Connection Form',
      decision: 'Decision',
      decision_message:
        'By accepting, the bank account will be connected to the event. A notification will be sent by email to the requester.',
      decision_select: 'Your decision',
      notes: 'Note for the requester',
      save: 'Save the decision',
      costs: 'Costs and taxes of the event',
    },
  },
};
