
import { Component, mixins, Prop, Watch, Ref } from 'nuxt-property-decorator';
import { v4 as uuid } from 'uuid';
import SimpleBar from 'simplebar';
import { cloneDeep, debounce } from 'lodash-es';
import { PreventPageScrollMixin } from '~/mixins';

@Component({})
export default class SidePanel extends mixins(PreventPageScrollMixin) {
  @Prop({ type: String, default: null }) readonly actionLabel!: string;
  @Prop({ type: String, default: null }) readonly title!: string | null;
  @Prop({ required: false, default: null }) initialModel!: any;
  @Prop({ type: [Boolean, String], default: false }) readonly form!: boolean | string;
  @Prop(Boolean) readonly actionLess!: boolean;

  @Ref() readonly hiddenHandle?: HTMLButtonElement;
  @Ref() readonly panelScroll?: HTMLElement;

  showPanel = false;
  formId: string | null = null;
  callBackFocusElement: HTMLButtonElement | null = null;
  simplebarInstance: SimpleBar | null = null;
  modelCheckPoint: any = null;

  get mainActionLabel() {
    return this.actionLabel || this.$t('global.save');
  }

  get isMainButtonSubmit() {
    return !!this.form;
  }

  @Watch('showPanel')
  async onShowPanelChange() {
    await this.$nextTick();
    if (this.showPanel) {
      this.modelCheckPoint = cloneDeep(this.initialModel);
      if (this.panelScroll) {
        this.simplebarInstance = new SimpleBar(this.panelScroll);
        this.panelScroll?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
      }
      this.hiddenHandle?.focus();
      this.preventPageScroll();
    } else {
      this.simplebarInstance?.unMount();
      this.callBackFocusElement?.focus();
      this.callBackFocusElement = null;
      this.restorePageScroll();
    }
  }

  created() {
    if (!this.form) return;
    this.formId = typeof this.form === 'string' ? this.form : uuid();
    // may be called twice when form is submittable (once by click event, once by submit event)
    this.save = debounce(this.save, 200, { leading: true, trailing: false });
  }

  public save(event: PointerEvent | SubmitEvent) {
    event.preventDefault();
    this.$emit('save');
  }

  public triggerVisibility(targetElement?: HTMLButtonElement | EventTarget | null) {
    if (targetElement instanceof HTMLButtonElement) this.callBackFocusElement = targetElement;
    this.showPanel = !this.showPanel;
  }

  public cancel(): void {
    this.$emit('cancel', this.modelCheckPoint);
    this.triggerVisibility();
  }

  beforeDestroy() {
    this.restorePageScroll();
  }
}
