import { TranslatableField } from 'fourwaves-shared';

export enum AddOnType {
  PeerReviewsModule = 'peerreviewsmodule',
  VirtualPosterSessions = 'virtualpostersessions',
  VideoRecordings = 'videorecordings',
  UnlimitedSubmissions = 'unlimitedsubmissions',
  PaymentCollection = 'paymentcollection',
}

export interface AddOnDto {
  id: string;
  name: TranslatableField;
  type: AddOnType;
  version: string;
  cost: number;
  isSuperAdminOnly: boolean;
}

export interface AddOnWithEventStatusDto extends AddOnDto {
  isActiveForEvent: boolean;
}

export interface AddOnOption {
  id: string;
  label: string;
  description: string;
  cost: number;
  disabled: boolean;
}
