
import { Component, Vue } from 'nuxt-property-decorator';
import FourwavesLogoFull from 'fourwaves-shared/src/images/fourwaves-light-full.png';
import FourwavesLogoSquare from 'fourwaves-shared/src/images/fourwaves-light-sq.png';
import DashboardDropdown from '../dropdown/DashboardDropdown.vue';

@Component({
  components: { DashboardDropdown },
})
export default class DashboardNavPanel extends Vue {
  showBackButtonRoutes = ['account-profile___', 'account-profile-password___'];

  readonly FourwavesLogoFull = FourwavesLogoFull;
  readonly FourwavesLogoSquare = FourwavesLogoSquare;

  get isRedirecting() {
    return this.$route.name?.startsWith('index');
  }

  get showBackButton(): boolean {
    if (!this.$route.name) return false;
    return this.showBackButtonRoutes.some(x => this.$route.name!.startsWith(x));
  }
}
