export enum InviteUserType {
  Organizer = 'organizer',
  Chair = 'chair',
  Reviewer = 'reviewer',
  Administrator = 'administrator',
}

export interface InviteUserModel {
  eventId?: string;
  organizationId?: string;
  emailAddresses: string[];
  type: InviteUserType;
}
