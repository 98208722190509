import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class RouteContextMixin extends Vue {
  get isInUserDashboard() {
    return !this.$route.name || this.$route.name.startsWith('user-dashboard-');
  }

  get isInUserSubmissionsDashboard() {
    return this.$route.name && this.$route.name.startsWith('user-dashboard-participant-submissions');
  }

  get isInOrganization() {
    return this.$route.name && this.$route.name.startsWith('organizations-id');
  }

  get isInOrganizerPanel() {
    return this.$route.name && this.$route.name.startsWith('events-') && this.$route.params.id != null;
  }
}
