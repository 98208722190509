
import { Component, Vue, namespace, Prop, Emit } from 'nuxt-property-decorator';
import { isValidGuid } from 'fourwaves-shared';
import { RouteMapItem, OrganizerNavItem } from '../types';
import NavItem from './NavItem.vue';

const EventsModule = namespace('events');
const SettingsModule = namespace('settings');

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: {
    NavItem,
  },
})
export default class ConfigurationSideNavItems extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean;
  @EventsModule.Getter isReviewingFeatureEnabled!: boolean;
  @SettingsModule.Getter isOrganizer!: boolean;

  @Emit()
  onToggle() {
    return OrganizerNavItem.Configuration;
  }

  get routesMap(): RouteMapItem[] {
    return [
      {
        visibility: true,
        to: { name: 'events-configuration', params: { id: this.$route.params.id } },
        label: this.$t('navigation.organizer.event_config.general'),
        dataTestId: 'general-page',
        currentActiveOnly: true,
      },
      {
        visibility: true,
        to: { name: 'events-configuration-theme', params: { id: this.$route.params.id } },
        label: this.$t('navigation.organizer.event_config.theme_colors'),
        dataTestId: 'theme-page',
      },
      {
        visibility: true,
        to: { name: 'events-configuration-committee', params: { id: this.$route.params.id } },
        label: this.$t('navigation.organizer.event_config.committee'),
        dataTestId: 'committee-page',
      },
      {
        visibility: true,
        to: { name: 'events-configuration-payments', params: { id: this.$route.params.id } },
        label: this.$t('navigation.organizer.event_config.payments'),
        dataTestId: 'payments-page',
      },
      {
        visibility: this.isReviewingFeatureEnabled,
        to: { name: 'events-configuration-reviews', params: { id: this.$route.params.id } },
        label: this.$t('navigation.organizer.event_config.reviews'),
        dataTestId: 'review-config-page',
      },
    ];
  }

  get isParentActive() {
    return this.routesMap.some(
      ({ to }) =>
        this.$route.name?.split('___')[0].startsWith(to?.name || '') &&
        !this.$route.name.includes('configuration-pages'),
    );
  }

  created() {
    if (this.isParentActive) this.onToggle();
  }
}
