import Vue from 'vue';

interface NavigationResolutionHandlers {
  confirm: () => void;
  cancel: () => void;
}

interface BeforeUnloadState {
  isActive: boolean;
  resolutionHandlers: NavigationResolutionHandlers | null;
}

export interface BeforeUnloadInjection extends BeforeUnloadState {
  isModalVisible: boolean;
  confirmNavigation(): Promise<boolean>;
}

const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = '';
};

export const beforeUnloadVm: BeforeUnloadInjection = new Vue({
  name: 'BeforeUnloadVM',
  data(): BeforeUnloadState {
    return { isActive: false, resolutionHandlers: null };
  },
  computed: {
    isModalVisible() {
      return this.isActive && this.resolutionHandlers !== null;
    },
  },
  watch: {
    isActive(isActive: boolean, wasActive?: boolean) {
      if (isActive === wasActive) return;
      if (isActive) window.addEventListener('beforeunload', beforeUnloadHandler);
      else window.removeEventListener('beforeunload', beforeUnloadHandler);
    },
  },
  methods: {
    confirmNavigation() {
      return new Promise(resolve => {
        this.resolutionHandlers = {
          confirm: () => {
            this.isActive = false;
            this.resolutionHandlers = null;
            resolve(true);
          },
          cancel: () => {
            this.resolutionHandlers = null;
            resolve(false);
          },
        };
      });
    },
  },
});

export default (_, inject) => {
  inject('beforeUnload', beforeUnloadVm);
};
