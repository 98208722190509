export default {
  submission: {
    configuration: {
      title: 'Submissions',
      form: 'Form',
      settings: 'Settings',
      email: 'Confirmation Email',
      options: 'Options',
      registration_mandatory: 'Registration is mandatory before submitting',
      multiple_submissions: 'Participants can submit more than once',
      editable_when_accepted: 'Submissions can be modified when accepted',
      limited_capacity: 'Set maximum number of submissions',
      max_submissions_placeholder: 'Enter a number',
      submission_period: 'Submission Period',
      from: 'From',
      to: 'To',
      presentation_types: {
        title: 'Presentation types',
        delete_cascade_effect: 'all related data',
        textbox: 'Name',
        create_title: 'Create Presentation Type',
        update_title: 'Edit Presentation Type',
        empty: 'No presentation type has been configured for this event yet.',
        hint: 'You can accept/reject submissions for each Presentation type.',
      },
    },
    export_submission_files: {
      title: 'Export Submission Files',
      message: 'A link to the files will be sent to your email inbox within the next hour.',
      group_by_session: 'Group Submissions by Session',
      unavailable_title: 'Export Unavailable',
      unavailable_message:
        'There must be at least one hour in between two exports. Please come back in {minutes} minutes.',
      config_message: 'Your submission form does not contain any fields allowing file uploads.',
      missing_submission_files_message: 'Your selection of submissions does not contain any files.',
    },
  },
};
