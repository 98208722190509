export default {
  transaction: {
    table: {
      title: 'Transactions',
      transaction_id: 'Transaction ID',
      payment_gateway_id: 'ID - Payment',
      creation_date: 'Creation Date',
      company: 'Company',
      card_name: 'Name on card',
      card_type: 'Card type',
      card_last4digits: 'Last 4',
      items: 'Item Count',
      coupon_code: 'Coupon Code',
      for: 'For',
      by: 'By',
      payment_method: 'Payment method',
      type: 'Transaction type',
      fourwaves_fee: 'Fourwaves fee',
      stripe_fee: 'Stripe fee',
      sub_total: 'Subtotal',
      total: 'Total',
      profit: 'Profit',
      payment_gateway_fee: 'Payment gateway fee',
      full_address: 'Billing address',
      gst: 'GST',
      hst: 'HST',
      qst: 'QST',
      vat: 'VAT',
      pst: 'PST',
      currency: 'Currency',
      event_name: 'Event Name',
    },
    refund_modal: {
      title: 'Refund charge',
      paid: 'Paid',
      refunded: 'Refunded',
      refundable: 'Refundable',
      fully_refunded: 'The total amount paid was fully refunded.',
      unsupported: 'It is not possible to refund this type of transaction.',
      amount: 'Enter amount to refund',
    },
    update_modal: {
      title_pending: 'Mark as Paid',
      title_concluded: 'Mark as Unpaid',
      message_pending:
        'Would you like to mark this transaction as paid? An email will be sent to notify the participant.',
      message_concluded: 'Would you like to mark this transaction as unpaid?',
    },
    remove_modal: {
      title: 'Remove a transaction',
      content: 'Do you really want to delete this transaction? This action is permanent and cannot be undone.',
    },
    status: {
      title: 'Status',
      pending: 'Unpaid',
      concluded: 'Paid',
      canceled: 'Declined',
    },
  },
};
