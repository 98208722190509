import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class NavPanelMixin extends Vue {
  @Prop(Boolean) readonly isNavCollapsed!: boolean;

  openNavItemKey: string | null = null;

  public onToggle(key: string) {
    this.openNavItemKey = key === this.openNavItemKey ? null : key;
  }

  public isNavItemOpen(key: string) {
    return this.isNavCollapsed ? false : this.openNavItemKey === key;
  }
}
