export default {
  plans: {
    upgrade: 'Upgrade',
    essential: {
      name: 'Essential',
      badge: 'Essential Plan',
    },
    lock_alert: {
      content_block: 'Not all content types are available on your current plan. Please contact your admin to upgrade.',
      content_block_admin: 'Not all content types are available on your current plan',
      content_block_menu:
        "Your current plan doesn't allow all types of content blocks. Please contact your admin to upgrade.",
      content_block_menu_admin: "Your current plan doesn't allow all types of content blocks.",
    },
  },
};
