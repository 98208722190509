
/* eslint-disable */
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import List from '@ckeditor/ckeditor5-list/src/list';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import BalloonToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/balloon/balloontoolbar';

export default {
  name: 'RichTextEditor',
  inject: ['$validator'],
  props: {
    name: String,
    placeholder: String,
    tabindex: [String, Number],
    value: {
      type: [String, Number],
      default: '',
    },
    isBasicStylesAllowed: {
      type: Boolean,
      default: true,
    },
    isLinkAllowed: {
      type: Boolean,
      default: false,
    },
    isImageAllowed: {
      type: Boolean,
      default: false,
    },
    isHeadingAllowed: {
      type: Boolean,
      default: false,
    },
    isListAllowed: {
      type: Boolean,
      default: false,
    },
    isTextAlignmentAllowed: {
      type: Boolean,
      default: false,
    },
    isMediaEmbedAllowed: {
      type: Boolean,
      default: false,
    },
    isHorizontalLineAllowed: {
      type: Boolean,
      default: false,
    },
    validation: [Object, String],
    label: String,
    type: {
      type: String,
      default: 'text', // all the possible HTML5 input types, except those that have a special UI
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    help: String,
    disabled: {
      type: Boolean,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      isTouched: false,
      initialValue: this.value,
      showEditor: true,
      ckInstanceId: '',
      showEditorTimer: null,
      editorInstance: null,
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          StrikethroughPlugin,
          SuperscriptPlugin,
          SubscriptPlugin,
          ParagraphPlugin,
          AlignmentPlugin,
          LinkPlugin,
          HorizontalLine,
          BalloonToolbar,
        ],
        toolbar: {
          items: [],
        },
        balloonToolbar: [],
        language: window.$nuxt.$i18n.locale,
        heading: {
          options: [
            {
              model: 'heading1',
              view: 'h2',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h3',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'heading3',
              view: 'h4',
              title: 'Heading 3',
              class: 'ck-heading_heading3',
            },
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
          ],
        },
        image: {
          toolbar: ['imageStyle:block', 'imageStyle:side', '|', 'imageTextAlternative', '|', 'linkImage'],
        },
        mediaEmbed: {
          previewsInData: true,
        },
      },
      cursorPositionOnBlur: null,
    };
  },
  computed: {
    classes() {
      return [
        { 'is-active': this.isActive },
        { 'is-invalid': this.invalid },
        { 'is-touched': this.isTouched },
        { 'is-multi-line': this.multiLine },
        { 'has-counter': this.maxlength },
        { 'is-disabled': this.disabled },
        { 'has-label': this.hasLabel },
        { 'has-text': this.value },
      ];
    },
    borderBoxClassNames() {
      return [
        'js-rte-' + this.name,
        { '-focus': this.isActive, '-disabled': this.readonly, '-destructive': this.validationErrors },
      ];
    },
    validationErrors() {
      if (this.errors && this.errors.items && this.errors.items.some(e => e.id === this.name)) {
        return this.errors.items.find(x => x.id === this.name).msg;
      }

      return this.errors.first(this.name);
    },
    hasLabel() {
      return Boolean(this.label) || Boolean(this.$slots.default);
    },
    hasTopRight() {
      return this.$slots['top-right'] && this.$slots['top-right'].length > 0;
    },
    isLabelInline() {
      return this.valueLength === 0 && !this.isActive;
    },
    valueLength() {
      return this.value ? this.value.length : 0;
    },
  },
  watch: {
    isBasicStylesAllowed: function () {
      this.refreshEditor();
    },
    isLinkAllowed: function () {
      this.refreshEditor();
    },
  },
  created() {
    // Normalize the value to an empty string if it's null
    if (this.value === null) {
      this.initialValue = '';
      this.updateValue('');
    }

    this.applyToolbarConfigurations();
  },
  async mounted() {
    this.$nuxt.$on('injectTextInRichTextEditor', data => {
      // data properties = inputName, name
      if (data.inputName && data.inputName === this.name && data.text) {
        this.injectTextValue(data.text);
      }
    });
    await this.$nextTick();
    if (this.autofocus) this.focus();
  },
  destroyed() {
    clearTimeout(this.showEditorTimer);
    this.showEditorTimer = null;
    this.$nuxt.$off('injectTextInRichTextEditor');
  },
  methods: {
    refreshEditor() {
      if (!this.editorConfig) return;

      this.$refs.input.$destroy();
      this.showEditor = false;

      this.applyToolbarConfigurations();

      this.showEditorTimer = setTimeout(() => {
        this.showEditor = true;
      }, 10);
    },
    applyToolbarConfigurations() {
      this.editorConfig.toolbar.items = ['subscript', 'superscript'];

      if (this.isBasicStylesAllowed) {
        this.editorConfig.plugins.push(BoldPlugin);
        this.editorConfig.plugins.push(ItalicPlugin);
        this.editorConfig.plugins.push(UnderlinePlugin);
        this.editorConfig.toolbar.items.unshift('bold', 'italic', 'underline', '|');
      }

      if (this.isHeadingAllowed) {
        this.editorConfig.plugins.push(Heading);
        this.editorConfig.toolbar.items.unshift('heading');
      }
      if (this.isLinkAllowed) {
        this.editorConfig.toolbar.items.push('link');
      }

      if (this.isListAllowed) {
        this.editorConfig.plugins.push(List);
        this.editorConfig.toolbar.items.push('numberedList');
        this.editorConfig.toolbar.items.push('bulletedList');
        this.editorConfig.toolbar.items.push('|');
      }

      if (this.isImageAllowed) {
        this.editorConfig.plugins.push(Image);
        this.editorConfig.plugins.push(ImageToolbar);
        this.editorConfig.plugins.push(ImageCaption);
        this.editorConfig.plugins.push(ImageStyle);
        this.editorConfig.plugins.push(ImageResize);
        this.editorConfig.plugins.push(LinkImage);
        this.editorConfig.plugins.push(ImageUpload);
        this.editorConfig.plugins.push(ImageInsert);
        this.editorConfig.toolbar.items.push('imageUpload');
        this.editorConfig.toolbar.items.push('imageInsert');
      }

      if (this.isTextAlignmentAllowed) {
        this.editorConfig.plugins.push(Alignment);
        this.editorConfig.toolbar.items.push('alignment:left');
        this.editorConfig.toolbar.items.push('alignment:center');
        this.editorConfig.toolbar.items.push('alignment:right');
      }

      if (this.isMediaEmbedAllowed) {
        this.editorConfig.plugins.push(MediaEmbed);
        this.editorConfig.toolbar.items.push('mediaEmbed');
      }

      if (this.isHorizontalLineAllowed) {
        this.editorConfig.toolbar.items.push('horizontalLine');
      }

      this.editorConfig.balloonToolbar = [...this.editorConfig.toolbar.items];
    },
    updateValue(event) {
      this.$emit('input', event);
    },
    onChange(e) {
      this.$emit('change', this.value, e);
    },
    onFocus(e) {
      this.isActive = true;
      this.$emit('focus', e);
    },
    onBlur(e) {
      if (this.editorInstance.model.document.selection.getFirstPosition()) {
        this.cursorPositionOnBlur = this.editorInstance.model.document.selection.getFirstPosition();
      }

      this.isActive = false;
      this.$emit('blur', e);
      if (!this.isTouched) {
        this.isTouched = true;
        this.$emit('touch');
      }
    },
    onKeydown(e) {
      this.$emit('keydown', e);
    },
    onKeydownEnter(e) {
      this.$emit('keydown-enter', e);
    },
    onEditorReady(editor) {
      this.editorInstance = editor;
    },
    reset() {
      // Blur the input if it's focused to prevent required errors
      // when it's value is reset
      if (document.activeElement === this.$refs.input || document.activeElement === this.$refs.textarea) {
        (document.activeElement as HTMLElement).blur();
      }
      this.updateValue(this.initialValue);
      this.resetTouched();
    },
    resetTouched(options = { touched: false }) {
      this.isTouched = options.touched;
    },
    focus() {
      this.refs?.input?.focus();
    },
    injectTextValue(text) {
      if (!this.cursorPositionOnBlur) {
        this.cursorPositionOnBlur = this.editorInstance.model.document.selection.getFirstPosition();
      }

      if (text && text.length > 0 && this.cursorPositionOnBlur) {
        this.editorInstance.model.change(writer => {
          writer.insertText(text, this.cursorPositionOnBlur);
          this.editorInstance.editing.view.focus();
          writer.setSelection(writer.createPositionAt(this.cursorPositionOnBlur.getShiftedBy(text.length)));
        });
      }
    },
  },
};
