
import { Component, mixins } from 'nuxt-property-decorator';
import { isValidGuid } from 'fourwaves-shared';
import NavItem from '../nav-items/NavItem.vue';
import ConfigurationSideNavItems from '../nav-items/ConfigurationSideNavItems.vue';
import WebsiteSideNavItems from '../nav-items/WebsiteSideNavItems.vue';
import DataSideNavItems from '../nav-items/DataSideNavItems.vue';
import { OrganizerNavItem } from '../types';
import NavPanelMixin from '../NavPanelMixin';

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: {
    NavItem,
    ConfigurationSideNavItems,
    WebsiteSideNavItems,
    DataSideNavItems,
  },
})
export default class OrganizerSideNavPanel extends mixins(NavPanelMixin) {
  readonly OrganizerNavItem = OrganizerNavItem;
}
