export default {
  create_event: {
    title: 'Create a new event',
    event_name: 'Event Name',
    event_organization: 'Organization',
    event_organization_placeholder: 'Select an organization',
    event_startDate: 'Start Date',
    create_button: 'Create event',
  },
};
