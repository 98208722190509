import Vue from 'vue';
import {
  TranslatableField,
  transl8,
  CivicAddressDto,
  dateMixins,
  moneyMixins,
  addressMixins,
  PageDto,
  validatorMixins,
  contentMixins,
  formatScore,
  validationMixins,
  FormFieldDto,
  FormFieldType,
  FormEntryDto,
  FormDto,
  SubmissionScope,
  PageScope,
  SubmissionDecisionEnum,
  PageType,
  formEntryMixins,
  fileMixins,
} from 'fourwaves-shared';

type PossibleDate = Date | string | null;

export const dashboardMixinMethods = {
  isProductionEnv(): boolean {
    return this.$config.API_URL === 'https://api.fourwaves.com';
  },
  showDateRangeWithTimeZone(startDate: string, endDate: string, format = 'LLL'): string {
    const start = this.$moment(startDate).format(format);
    const end = this.$moment(endDate).format(format);
    return `${start} ${this.$t('global.until')} ${end}`;
  },
  toDayTimeRange(startDate: PossibleDate, endDate: PossibleDate): string {
    if (!startDate || !endDate) return '---';
    if (!startDate && !endDate) return '';

    const start = this.$moment(new Date(startDate));
    const end = this.$moment(new Date(endDate));
    const day = this.$moment.locale() === 'fr' ? `${start.format('Do MMMM')}` : `${start.format('MMMM D')}`;
    return `${day}, ${start.format('LT')} - ${end.format('LT')}`;
  },
  showPeriod(startDate: PossibleDate, endDate: PossibleDate): string {
    const now = this.$moment();
    if (now.isBefore(this.$moment(startDate))) {
      const date = this.$moment(startDate);
      return `${this.$t('global.opens_on')} ${this.$moment(date).format('LLL')}`;
    }
    if (now.isBefore(this.$moment(endDate))) {
      const date = this.$moment(endDate);
      return `${this.$t('global.closes_on')} ${this.$moment(date).format('LLL')}`;
    }
    return this.$t('global.closed');
  },
  showUserFriendlyDate(date?: string): string {
    return date ? this.$moment(new Date(date)).format('LLL') : '---';
  },
  showDateWithContext(date?: string): string {
    return date ? this.$moment(new Date(date)).calendar() : '---';
  },
  transl8(field: TranslatableField): string {
    return transl8(field);
  },
  async validateAll(): Promise<boolean> {
    this.clearErrorBag();
    const isValid = await this.$validator.validateAll();
    if (!isValid) this.scrollToErrors();
    return isValid;
  },
  showCivicAddress(civicAddress: CivicAddressDto | null, vatNumber?: string | null): string {
    if (civicAddress == null) return '---';
    const addressComponents = [
      civicAddress.addressLine1,
      civicAddress.city,
      `${civicAddress.stateProvince} (${civicAddress.countryRegion})`,
      civicAddress.postalCode,
    ].filter(Boolean); // Exclude empty strings

    let addressString = addressComponents.join(', ');

    if (vatNumber) {
      addressString += `, VAT: ${vatNumber}`;
    }

    return addressString;
  },
  buildPageLink(page?: PageDto): string {
    if (!page) return '';
    switch (page.type) {
      case PageType.Registration:
        return this.localePath({
          name: 'events-configuration-pages-registration',
          params: { id: this.$route.params.id },
        });
      case PageType.Submission:
        return this.localePath({
          name: 'events-configuration-pages-submission',
          params: { id: this.$route.params.id },
        });
      case PageType.Schedule:
        return this.localePath({
          name: 'events-configuration-pages-schedule',
          params: { id: this.$route.params.id },
        });
      default: {
        const hasContentTab = page.type === PageType.Content || page.type === PageType.Home;
        return this.localePath({
          name: hasContentTab ? 'events-configuration-pages-pageId' : 'events-configuration-pages-pageId-settings',
          params: { id: this.$route.params.id, pageId: page.id },
        });
      }
    }
  },
  showScore(score: number | null, base = 5): string {
    return formatScore(score, base);
  },
  async executeWithSpinner(func: Function, spinnerId: string) {
    this.$wait.start(spinnerId);
    await func();
    this.$wait.end(spinnerId);
  },
  isValidSubmissionField(field: FormFieldDto, formEntry: FormEntryDto, form: FormDto): boolean {
    return (
      formEntry.formEntryValues.some(formEntryValue => formEntryValue.formFieldId === field.id) &&
      field.fieldType !== FormFieldType.Instructions &&
      field.fieldType !== FormFieldType.Authors &&
      field.id !== form.submissionTitleFormFieldId &&
      field.id !== form.submissionAbstractFormFieldId
    );
  },
  isFieldMissingAnswer(field: FormFieldDto, formEntry: FormEntryDto, hiddenFieldIds: string[]): boolean {
    return (
      !formEntry.formEntryValues.some(
        formEntryValue => formEntryValue.formFieldId === field.id && formEntryValue.value,
      ) &&
      field.isRequired &&
      !hiddenFieldIds.includes(field.id)
    );
  },
  hasFieldSingleFile(fieldType: FormFieldType): boolean {
    return (
      fieldType === FormFieldType.File ||
      fieldType === FormFieldType.Video ||
      fieldType === FormFieldType.Poster ||
      fieldType === FormFieldType.Slides
    );
  },
  isFieldVerbose(fieldType: FormFieldType): boolean {
    return (
      fieldType === FormFieldType.Text ||
      fieldType === FormFieldType.Textarea ||
      fieldType === FormFieldType.RichText ||
      fieldType === FormFieldType.Dropdown ||
      fieldType === FormFieldType.Checkboxes ||
      fieldType === FormFieldType.Radios ||
      fieldType === FormFieldType.Email ||
      fieldType === FormFieldType.Score ||
      fieldType === FormFieldType.CountryList
    );
  },
  canSeePresentationPage(formEntry: FormEntryDto, pages: PageDto[], isParticipant: boolean): boolean {
    const presentationPages = pages.find(p => p.type === PageType.Abstracts);
    if (!presentationPages || !formEntry) return false;

    const hasAcceptedDecisions =
      formEntry.submissionDecisions?.some(d => d.decision === SubmissionDecisionEnum.Accepted) ?? false;

    return (
      presentationPages.isVisible &&
      (presentationPages.scope === PageScope.Everyone || isParticipant) &&
      formEntry.isPublished &&
      (presentationPages.submissionScope === SubmissionScope.Submitted || hasAcceptedDecisions)
    );
  },
};

export const mixinMethods = {
  // Shared mixins
  ...formEntryMixins,
  ...dateMixins,
  ...addressMixins,
  ...validatorMixins,
  ...moneyMixins,
  ...contentMixins,
  ...validationMixins,
  ...fileMixins,
  ...dashboardMixinMethods,
};

Vue.mixin({
  methods: mixinMethods,
});
