export enum UpdateUserType {
  Organizer = 'organizer',
  Chair = 'chair',
}

export interface UpdateUserModel {
  eventId: string;
  userId: string;
  type: UpdateUserType;
}
