export default {
  org_config: {
    events: {
      title: 'Events',
      name: 'Event name',
      manage_events: 'Manage Events',
      new_event: 'Create New Event',
      import_events: 'Import Events',
      import_modal: {
        title: 'Import Events',
        subtitle: 'Select the events you wish to link to this organization.',
        no_events_title: 'No events to import.',
        no_events_subtitle: 'All your events already belong to this organization.',
      },
    },
    plans: {
      title: 'Plans & Billing',
      empty_title: "Something exciting's coming up!",
      empty_subtitle:
        "Organizations are about to move to a whole new level. Self-serve upgrades, unlimited events, better insights - there's no shortage of benefits to be had from this new approach. Stay tuned!",
      empty_cta: 'Learn more',
    },
    users: {
      title: 'Users',
      admins: {
        title: 'Organization Admins',
        hint: 'These users have full rights to an organization, as well as to all its events and users.',
        invite_modal_title: 'Add Administrator',
        remove_modal_title: 'Remove Administrator',
        remove_modal_subtitle: 'This administrator will no longer have access to the organization and its events.',
        empty_tile: 'No administrator have been added to this organization yet.',
      },
      organizers: {
        title: 'Event Organizers',
        event_count: 'Linked to {count} event | Linked to {count} events',
        empty_tile: 'No organizer have been added to an event of this organization.',
      },
      events_modal: {
        title: '{name} Events',
      },
    },
    settings: {
      title: 'Settings',
      infos: {
        title: 'Organization Info',
        name: 'Name',
        name_hint: 'Be as specific as possible. Ex. Department of Marine Biology of the Brightwater University.',
        address: 'Street Address',
        address_hint: 'Required to publish your events, upgrade your plan, etc.',
        city: 'City',
        zip: 'Postal / Zip Code',
      },
    },
    transactions: {
      title: 'Transactions',
      empty_title: 'No transactions',
      empty_subtitle: 'There are no transactions related to this organization yet.',
    },
    ba: {
      index: {
        title: 'Bank Accounts',
        empty_title: 'No bank accounts',
        empty_subtitle: 'There are no bank accounts linked to this organization at the moment.',
        name: 'Name',
        events_connected: 'Events Connected',
        payment_gateway: 'Payment Gateway',
        code: 'Gateway Code',
        clipboard_success_message: 'Code copied to clipboard',
      },
      detail: {
        back_to_index: 'Back to all Bank Accounts',
        settings: {
          title: 'Settings',
          account_title: 'Account Info',
          name: 'Bank Account Name',
          gateway_hint:
            'Share this code with your organizers to allow them to connect their events to this bank account.',
          billing: 'Billing Info',
          tax: 'Tax ID',
          payment_title: 'Payment Gateway',
        },
        form: {
          title: 'Connection Form',
          empty_subtitle: 'Customize this form so that event organizers can request connection to this bank account.',
        },
        requests: {
          title: 'Connection History ',
          empty_title: 'No connected events',
          empty_subtitle: 'This bank account has not been linked to any event yet.',
          event_name: 'Event Name',
          state: 'Status',
          edit_state: 'Last Status Change',
          decision_by: 'Decision By',
          request_date: 'Request Date',
          request_by: 'Request By',
          request_by_email: 'Requester email',
          external_event_title: 'This event is external to your organization.',
          external_event_subtitle: 'You do not have the required permissions to access it.',
        },
      },
    },
  },
};
