export default {
  get_started: {
    enter_your_information: {
      title: 'Enter your information',
      sub_title: "You'll have access to your demo anytime.",
      default_organization_name: 'My organization',
      terms:
        "By submitting this form, you agree to the <a href='https://fourwaves.com/terms-of-use/' target='_blank'>Terms of Use</a> and the <a href='https://fourwaves.com/privacy-policy/' target='_blank'>Privacy Policy</a>.",
    },
    market_segment: {
      title: 'What type corresponds the most to your organization?',
    },
    field_of_interest: {
      title: 'Enter a word about your field of interest',
      sub_title: 'Our AI bot 🤖 will make your demo look real!',
      placeholder: 'Ex. Conference on microbiology',
    },
    check_inbox: {
      title: 'Check your inbox',
      sub_title: 'Click the magic link we just sent to {email} to access your event.',
      text: "Can't find it? Look in your spam folder.",
    },
    event_creation: {
      title: 'Event creation in progress',
      steps: [
        'Generating website content',
        'Putting together forms',
        'Generating registrations',
        'Generating submissions',
        'Finalizing the schedule',
        'Applying optimal settings',
        'Sprinkling a little magic here and there',
      ],
    },
    success: {
      title: 'Event successfully created!',
      message: 'Sit tight while we redirect you to your event.',
    },
    footer: {
      text: 'Ready to talk with us ?',
      book_a_demo: 'Book a demo',
      book_a_demo_link: 'https://fourwaves.com/book-a-demo/?utm_source=getstarted&utm_medium=website',
    },
  },
};
