
import { Vue, Component, Watch, namespace, Ref } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { GlobalFilterEvents, Role } from 'fourwaves-shared';
import { Collapsible } from 'fourwaves-shared/components';

interface DashboardDropdownOption {
  route: string;
  activeRoute: string;
  label: string;
  icon: string;
  role: Role;
}

const SettingsModule = namespace('settings');

@Component({
  components: {
    Collapsible,
  },
})
export default class DashboardDropdown extends Vue {
  @SettingsModule.Getter userDashboardRoleCookieName!: string;

  @Ref('optionsMenuContainer') readonly optionsMenuContainer?: HTMLMenuElement;
  @Ref('selectButton') readonly selectButton?: HTMLElement;

  readonly Role = Role;

  options: DashboardDropdownOption[] = [
    {
      route: 'user-dashboard-organizer',
      activeRoute: 'user-dashboard-organizer',
      label: 'user_dashboard.organizer.dropdown',
      role: Role.Organizer,
      icon: 'tools',
    },
    {
      route: 'user-dashboard-participant-index-events',
      activeRoute: 'user-dashboard-participant',
      label: 'user_dashboard.participant.dropdown',
      role: Role.Participant,
      icon: 'user-square',
    },
    {
      route: 'user-dashboard-reviewer',
      activeRoute: 'user-dashboard-reviewer',
      label: 'user_dashboard.reviewer.dropdown',
      role: Role.Reviewer,
      icon: 'reviewer',
    },
  ];

  isOpen = false;
  isFocused = false;
  GlobalFilterEvents = GlobalFilterEvents;
  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;

  get selectedOption(): DashboardDropdownOption {
    const normalizedRouteName = this.$route.name?.split('___')[0] ?? '';
    const selectedOption = this.options.find(({ activeRoute }) => normalizedRouteName.includes(activeRoute));

    return selectedOption ?? this.options[0];
  }

  @Watch('$route')
  onRouteChanged() {
    this.isOpen = false;
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;

    if (isOpen) {
      document.body.addEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$on(GlobalFilterEvents.SelectOpen, this.closeTray);
    } else {
      document.body.removeEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
    }
  }

  async mounted() {
    await this.$nextTick();
    if (!this.optionsMenuContainer) return;
    this.simplebarInstance = new SimpleBar(this.optionsMenuContainer);
    await this.$nextTick();
    this.optionsMenuContainer?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
    this.scrollElement = this.simplebarInstance.getScrollElement();
  }

  beforeDestroy() {
    document.body.removeEventListener('click', () => {
      this.closeTray();
    });
    this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
  }

  public isRouteActive(activeRoute: string) {
    return this.$route.name?.includes(activeRoute) ?? false;
  }

  public onButtonClick() {
    if (!this.isOpen) this.$nuxt.$emit(GlobalFilterEvents.SelectOpen);
    this.isOpen = !this.isOpen;
  }

  public tabTrapHandle(index: number): string | null {
    return index === 0 ? 'first' : index === this.options.length - 1 ? 'last' : null;
  }

  public closeTray(focusButton = false) {
    this.isOpen = false;
    if (focusButton) this.selectButton?.focus();
  }

  public onTrayClose() {
    if (!this.scrollElement) return;
    this.scrollElement.scrollTop = 0;
  }

  public updateCookie(role: string) {
    this.$cookies.set(this.userDashboardRoleCookieName, role, { path: '/' });
  }
}
