
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FormFieldDto, FormDto, FormEntryDto, getFormEntryValue } from 'fourwaves-shared';

@Component({})
export default class ScoreField extends Vue {
  @Prop({ required: true }) field!: FormFieldDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) formEntry!: FormEntryDto;
  @Prop(Boolean) isTable!: boolean;

  get value() {
    const value = getFormEntryValue(this.formEntry, this.form, this.field.id);
    return value !== '' ? `${value} / ${this.field.maxReviewScore}` : '---';
  }
}
