
import { Component, Vue, namespace } from 'nuxt-property-decorator';
import FourwavesLogoFull from 'fourwaves-shared/src/images/fourwaves-dark-full.png';
import { isValidGuid, OrganizationDto } from 'fourwaves-shared';
import OrganizationDropdown from '../dropdown/OrganizationDropdown.vue';
import MobileNav from '../MobileNav.vue';

const OrganizationsModule = namespace('organizations');

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: { OrganizationDropdown, MobileNav },
})
export default class OrganizationNavPanel extends Vue {
  @OrganizationsModule.State organizations!: OrganizationDto[];

  readonly FourwavesLogoFull = FourwavesLogoFull;

  get organizationName() {
    const organization = this.organizations.find(({ id }) => id === this.$route.params.id);
    return organization?.name || null;
  }
}
