/*
 * List of supported currencies. Make sure to update the back-end app if you change this list.
 */
export const Currencies = [
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CZK',
  'EUR',
  'GBP',
  'HUF',
  'MXN',
  'NOK',
  'SEK',
  'USD',
  'PLN',
  'HKD',
  'ETB',
  'KRW',
  'NZD',
];
