export default {
  communication: {
    title: 'Communications',
    back_to_list: 'Back to all communications',
    status: {
      processed: 'Processed',
      deferred: 'Deferred',
      delivered: 'Delivered',
      bounce: 'Bounce',
      dropped: 'Dropped',
      unprocessed: 'Unprocessed',
    },
    status_description: {
      processed: 'The email is being sent.',
      deferred:
        "The email was not delivered to the recipient. We'll continue trying to send this email for a few more hours.",
      delivered: 'The email was received by the mail server and delivered to the recipient.',
      bounce: "The recipient's mail server informed us that this message bounced. ",
      dropped: 'The email was not sent because the email address previously bounced.',
      unprocessed: 'We are currently processing the email.',
    },
    status_reason: {
      bounce: 'Bounce Reason',
      dropped: 'Dropped Reason',
    },
    resend: 'Resend',
    resend_modal: {
      title: 'Resend email',
      subtitle: 'Are you sure you want to resend this email?',
      confirmation: 'Confirm',
      success_message: 'The email has been resent successfully.',
    },
    header_status: 'Status',
    subject: 'Subject',
    sent: 'Date sent',
    recipient: 'Recipient',
    campaign: 'Campaign',
    data_table: {
      empty_title: 'No communications',
      empty_subtitle: 'No communications have been sent from this event yet.',
    },
  },
};
