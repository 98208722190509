
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FormFieldDto, FormDto, FormEntryDto, getFormEntryValue } from 'fourwaves-shared';

@Component({})
export default class FileField extends Vue {
  @Prop({ required: true }) field!: FormFieldDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) formEntry!: FormEntryDto;

  get fileUrls(): string[] {
    const value = getFormEntryValue(this.formEntry, this.form, this.field.id);
    return value.split(',').map(x => this.$api.getFileUploadUrl(x));
  }
}
