export default {
  page: {
    configuration: {
      content: 'Content',
      settings: 'Settings',
      options: 'Options',
      visibility_toggle: 'Show page on website',
      visibility_scope: 'Page visibility',
      scope_participants: 'Visible to registrants',
      scope_everyone: 'Visible to everyone',
      voting_enabled: 'Voting is enabled',
      submission_scope: {
        submitted: 'All submissions',
        accepted: 'Accepted submissions only',
        label: 'Submissions visibility',
        help: 'Determine when submissions are visible on your website. You can change the visibility of each submission at any time.',
      },
      page_filters: 'Select which fields are visible',
    },
  },
};
