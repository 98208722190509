import { addSharedLocales } from 'fourwaves-shared';
import getStarted from './get-started';
import base from './base';
import roles from './roles';
import schedule from './schedule';
import registration from './registration';
import submission from './submission';
import review from './review';
import page from './page';
import communication from './communication';
import userDashboard from './user-dashboard';
import eventConfig from './event-config';
import orgConfig from './org-config';
import transaction from './transaction';
import navigation from './navigation';
import bankAccount from './bank-account';
import checkout from './checkout';
import plans from './plans';
import createOrg from './create-org';
import createEvent from './create-event';

export default addSharedLocales('en', {
  ...base,
  ...roles,
  ...getStarted,
  ...schedule,
  ...registration,
  ...submission,
  ...review,
  ...page,
  ...communication,
  ...userDashboard,
  ...eventConfig,
  ...orgConfig,
  ...transaction,
  ...navigation,
  ...bankAccount,
  ...checkout,
  ...plans,
  ...createOrg,
  ...createEvent,
});
