import Vue from 'vue';

// Plugins
import Paginate from 'vuejs-paginate';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import money from 'v-money';
import FloatingVue from 'floating-vue';

// Shared components
import { Icon, Hint, ActionButton, ActionLink, Spinner } from 'fourwaves-shared/components';

// Layout
import SidePanel from '~/components/layout/SidePanel.vue';
import NoData from '~/components/data-table/NoData.vue';

// Form components
import Geocomplete from '~/components/form/Geocomplete.vue';
import RichTextEditor from '~/components/form/RichTextEditor.vue';
import ChangeDetectionBox from '~/components/form/ChangeDetectionBox.vue';

// Data components (make sure they stay lowercase)
import TextField from '~/components/data-form/TextField.vue';
import TextareaField from '~/components/data-form/TextareaField.vue';
import AuthorsField from '~/components/data-form/AuthorsField.vue';
import FileField from '~/components/data-form/FileField.vue';
import SlidesField from '~/components/data-form/SlidesField.vue';
import PosterField from '~/components/data-form/PosterField.vue';
import VideoField from '~/components/data-form/VideoField.vue';
import ImagesField from '~/components/data-form/ImagesField.vue';
import RichTextField from '~/components/data-form/RichTextField.vue';
import ScoreField from '~/components/data-form/ScoreField.vue';
import FormSummary from '~/components/data-form/FormSummary.vue';

Vue.component('Paginate', Paginate);
Vue.use(CKEditor);
Vue.use(money, { precision: 2 });
Vue.use(FloatingVue);
Vue.component('Spinner', Spinner);
Vue.component('Icon', Icon);
Vue.component('Hint', Hint);
Vue.component('SidePanel', SidePanel);
Vue.component('NoData', NoData);
Vue.component('Geocomplete', Geocomplete);
Vue.component('RichTextEditor', RichTextEditor);
Vue.component('ChangeDetectionBox', ChangeDetectionBox);
Vue.component('Textfield', TextField);
Vue.component('Emailfield', TextField);
Vue.component('Textareafield', TextareaField);
Vue.component('Authorsfield', AuthorsField);
Vue.component('Filefield', FileField);
Vue.component('Slidesfield', SlidesField);
Vue.component('Posterfield', PosterField);
Vue.component('Videofield', VideoField);
Vue.component('Imagesfield', ImagesField);
Vue.component('Richtextfield', RichTextField);
Vue.component('Scorefield', ScoreField);
Vue.component('FormSummary', FormSummary);

Vue.component('ActionLink', ActionLink);
Vue.component('ActionButton', ActionButton);
