
import { Component, namespace, mixins, Ref } from 'nuxt-property-decorator';
import { UserDropdown, Collapsible, OrganizationSplashScreen } from 'fourwaves-shared/components';
import OrganizationCreateModalWrapper from '../organization/OrganizationCreateModalWrapper.vue';
import LangChooser from './LangChooser.vue';
import OrganizerNavPanel from './nav-panel/OrganizerNavPanel.vue';
import OrganizationNavPanel from './nav-panel/OrganizationNavPanel.vue';
import DashboardNavPanel from './nav-panel/DashboardNavPanel.vue';
import { RouteContextMixin } from '~/mixins';

const EventsModule = namespace('events');

@Component({
  components: {
    OrganizerNavPanel,
    OrganizationNavPanel,
    DashboardNavPanel,
    UserDropdown,
    LangChooser,
    Collapsible,
    OrganizationSplashScreen,
    OrganizationCreateModalWrapper,
  },
})
export default class Navigation extends mixins(RouteContextMixin) {
  @EventsModule.Getter isEventMultiLanguage!: boolean;

  @Ref() readonly createOrganizationModal?: OrganizationCreateModalWrapper;

  bannerHasContent = false;
  showSplashScreen = false;

  get isDark() {
    return Boolean(this.isInOrganization || this.isInOrganizerPanel) || this.isInOrganizations;
  }

  get isInOrganizations() {
    return this.$route.name && this.$route.name.startsWith('organizations');
  }

  public openOrganizationCreateModal() {
    this.createOrganizationModal?.openModal();
  }

  public onPortalContentChanged(hasContent: boolean) {
    this.bannerHasContent = hasContent;
  }

  public onCollapsibleBeforeTransition(height: number | string) {
    const navigationOffset = height === 0 || height === 'auto' ? null : `${height}px`;
    document.body.style.setProperty('--navigation-offset', navigationOffset);
  }
}
