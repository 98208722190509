import { OrganizationDto, Role } from 'fourwaves-shared';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { RootState, OrganizationsState } from '~/types';

export const state = (): OrganizationsState => ({
  organizations: [],
});

export const mutations: MutationTree<OrganizationsState> = {
  setOrganizations(state: OrganizationsState, organizations: OrganizationDto[]): void {
    state.organizations = organizations;
  },
};

export const actions: ActionTree<OrganizationsState, RootState> = {
  init(_): Promise<void> {
    return this.dispatch('organizations/fetchOrganizations');
  },
  async fetchOrganizations({ commit }): Promise<void> {
    const organizations = await this.$api.getOrganizations();
    commit('setOrganizations', organizations);
  },
};

export const getters: GetterTree<OrganizationsState, RootState> = {
  adminOrganizations(state, getters, rootState) {
    if (!rootState.auth || !rootState.auth.user) return [];
    const adminOrgIds = rootState.auth.user.userRoles.organizations
      .filter(({ roles }) => roles.includes(Role.Administrator))
      .map(({ id }) => id);
    return state.organizations.filter(({ id }) => adminOrgIds.includes(id));
  },
};
