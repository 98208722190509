import { moment, Dictionary } from 'fourwaves-shared';
import { CustomValidationRule } from 'fourwaves-shared/src/plugins/validation-rules';

export const validationRules: Dictionary<CustomValidationRule> = {
  url_segment: {
    validate: value => /^[0-9A-Za-z-]+$/i.test(value),
  },
  isBefore: {
    validate: (value, [dateAsString]: any) =>
      dateAsString == null ||
      dateAsString === 'null' ||
      dateAsString === '' ||
      moment(value).isBefore(moment(dateAsString)),
  },
  isAfter: {
    validate: (value, [dateAsString]: any) =>
      dateAsString == null ||
      dateAsString === 'null' ||
      dateAsString === '' ||
      moment(value).isAfter(moment(dateAsString)),
  },
  isTimeBefore: {
    validate: (value, [timeAsString]: any) =>
      timeAsString == null ||
      timeAsString === 'null' ||
      timeAsString === '' ||
      moment(value, 'HH:mm').isBefore(moment(timeAsString, 'HH:mm')),
  },
  isTimeAfter: {
    validate: (value, [timeAsString]: any) =>
      timeAsString == null ||
      timeAsString === 'null' ||
      timeAsString === '' ||
      moment(value, 'HH:mm').isAfter(moment(timeAsString, 'HH:mm')),
  },
  tlink_url: {
    validate: value => {
      const regex = /^https:\/\/(?:test\.)?secure\.touchnet\.net:\d+\/\w+\/services\/TPGSecureLink$/;
      return regex.test(value);
    },
  },
  upay_url: {
    validate: value => {
      const regex = /^https:\/\/(?:test\.)?secure\.touchnet\.net:\d+\/\w+\/web\/index\.jsp$/;
      return regex.test(value);
    },
  },
};
