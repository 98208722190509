import { Intercom, update } from '@intercom/messenger-js-sdk';
const APP_ID = 'o9b2hrv8';

interface IntercomUserData {
  name?: string;
  email?: string;
  user_id?: string;
}

export default ({ $auth, app }) => {
  if ($auth?.loggedIn) {
    installIntercom($auth, app);
  }

  $auth.onRedirect((to: string) => {
    if (to === '/') installIntercom($auth, app);
  });
};

function getUserData($auth: any): IntercomUserData {
  return {
    name: `${$auth.user.firstName === null ? '' : $auth.user.firstName + ' '}${$auth.user.lastName ?? ''}`,
    email: $auth.user.email,
    user_id: $auth.user.id,
  };
}

function installIntercom($auth: any, app: any) {
  const userData = getUserData($auth);

  Intercom({ app_id: APP_ID, alignment: 'right', ...userData });

  app.router.afterEach(() => {
    update(userData);
  });
}
