export default {
  review: {
    user_dashboard: {
      reviewing_period: 'Reviewing Period',
      reviews: 'Reviews',
      completed_stamp: 'Completed',
      empty_state: {
        title: 'No reviews',
        subtitle: 'No reviews have been assigned to you yet.',
      },
    },
    back_to_list: 'Back to reviews',
    back_to_events: 'Back to all events',
    reviewing_period_status: {
      closed: 'Closed',
      closes: 'Closes',
      future: 'Opens',
      open: 'Open',
    },
    status: {
      pending: 'To Do',
      completed: 'Completed',
      conflictofinterest: 'Conflict of interest',
    },
    form: {
      title: 'Review Form',
      reviewed_by: 'Reviewed by',
      save: 'Save',
    },
    reviewing_period_notice: {
      opening_soon: {
        title: 'Reviewing period opening',
        message: 'Please come back later.',
        message_organizer: 'Reviewers cannot fill out or edit their reviews at this time.',
      },
      closed: {
        title: 'Reviewing period closed',
        message:
          'You cannot submit or edit a review at this time. Contact your administrator for any additional information.',
        message_organizer: 'Reviewers cannot fill out or edit their reviews at this time.',
      },
    },
    summary: {
      conflict_declared: 'Conflict of interest declared',
    },
    submission_id: 'Submission ID',
    configuration: {
      title: 'Reviews',
      empty_title: 'This form needs love',
      empty_subtitle: 'Create your reviewing form so that you can assign submissions to your reviewing committee.',
      form: 'Form',
      settings: 'Settings',
      reviewers: 'Reviewing Committee',
      reviewing_period: 'Reviewing Period',
      reviews_settings: 'Reviews Settings',
      visible_fields_to_reviewers: 'Fields visible to reviewers',
      results_sharing: 'Results Sharing',
      share_results_to_submitters: 'Share results to authors',
      visible_fields_to_submitters: 'Fields visible to authors',
      hide_reviewers: 'Hide reviewers names',
    },
    data_table: {
      empty_title: 'No reviews',
      empty_subtitle: 'There are no reviews related to this event yet.',
    },
  },
};
