
import { Component, namespace, Vue, Watch, Ref } from 'nuxt-property-decorator';
import {
  PageDto,
  PageType,
  PageScope,
  isSuccess,
  NestedPage,
  buildNestedPages,
  flattenNestedPages,
  isValidationError,
} from 'fourwaves-shared';
import { Modal, DeleteModal } from 'fourwaves-shared/components';
import Draggable from 'vuedraggable';
import EditMenuItem from './EditMenuItem.vue';
import FullscreenModal from '~/components/layout/FullscreenModal.vue';

const EventsModule = namespace('events');

@Component({
  components: {
    Modal,
    FullscreenModal,
    EditMenuItem,
    Draggable,
    DeleteModal,
  },
})
export default class EditMenuModal extends Vue {
  @EventsModule.Getter withContentPages!: boolean;
  @EventsModule.State pages!: PageDto[];
  @EventsModule.Action refreshContext!: () => Promise<boolean>;

  @Ref() readonly modal?: FullscreenModal;
  @Ref() readonly deleteModal!: DeleteModal;

  readonly PageType = PageType;

  nestedPages: NestedPage[] = [];
  editionLocale: string = window.$nuxt.$i18n.locale;
  pageIdToBeDeleted = '';
  isDeletingPage = false;
  isDragging = false;

  draggableOptions = {
    scrollSensitivity: 200,
    group: 'nestedPages',
    chosenClass: 'draggable__chosen',
    draggable: '.draggable__item',
    handle: '.draggable__handle',
    swapThreshold: 0.5,
    invertedSwapThreshold: 0.5,
    animation: 150,
  };

  @Watch('pages', { immediate: true, deep: true })
  onPagesChanged() {
    this.nestedPages = buildNestedPages(this.pages);
  }

  public checkMove({ draggedContext, to }: { draggedContext: { element: PageDto }; to: HTMLElement }) {
    const isFolder = draggedContext.element.type === PageType.Folder;
    const isNestedList = to.hasAttribute('data-nested');
    return !(isFolder && isNestedList);
  }

  public addPageItem(isFolder: boolean) {
    if (this.nestedPages.find(x => !x.id)) return;

    this.nestedPages.push({
      id: '',
      name: { fr: '', en: '' },
      order: 0,
      type: isFolder ? PageType.Folder : PageType.Content,
      isVisible: true,
      contentPage: null,
      scope: PageScope.Everyone,
      lastUpdateDate: null,
      submissionScope: null,
      formFields: [],
      parentId: null,
      children: [],
    });
  }

  public async openDeleteModal(page: PageDto) {
    this.pageIdToBeDeleted = page.id;
    this.isDeletingPage = page.type !== PageType.Folder;
    await this.$nextTick();
    this.deleteModal?.triggerVisibility(undefined, this.transl8(page.name));
  }

  public async deletePage() {
    this.$wait.start('delete');
    const result = await this.$api.deletePage(this.pageIdToBeDeleted);
    if (isSuccess(result)) {
      await this.refreshContext();
      this.$toast.success(this.$t('global.success_message'));
      this.deleteModal?.triggerVisibility();
    } else if (isValidationError(result)) {
      this.feedErrorBag(result);
    }
    this.$wait.end('delete');
  }

  public async onReorder(event: any) {
    if (!event.moved && !event.added) return;

    const eventType = event.moved || event.added;
    if (!eventType.element.id) return;

    if (this.nestedPages.find(x => !x.id)) return;

    const orderedPages = this.nestedPages.map((page, index) => {
      const orderedPage = { ...page, order: index + 1 };

      if (page.children) {
        orderedPage.children = page.children.map((child, childIndex) => ({
          ...child,
          order: childIndex + 1,
        }));
      }

      return orderedPage;
    });

    const pages = flattenNestedPages(orderedPages);
    const page = pages.find(x => x.id === eventType.element.id);
    if (!page) return;

    await this.$api.reorderPage(page.id, page.order, page.parentId);
    await this.refreshContext();
  }

  public onPageCancel({ index, parentId }) {
    if (!parentId) this.nestedPages.splice(index, 1);
    const parent = this.nestedPages.find(x => x.id === parentId);
    if (parent) parent.children.splice(index, 1);
  }

  public triggerVisibility() {
    this.nestedPages = buildNestedPages(this.pages);
    this.modal?.triggerVisibility();
  }

  public async onPageCreated() {
    await this.refreshContext();
  }
}
