import { AxiosError } from 'axios';
import { sortBy } from 'lodash-es';
import {
  TypeOrError,
  GetUsersDto,
  FormSectionDto,
  FormFieldDto,
  PaginatedList,
  IFourwavesApiService,
  BaseApiService,
  FormDto,
  GetTransactionsModel,
  TransactionDto,
  AuthenticationResult,
  SettingsDto,
  extractFileNameFromHeaders,
  RoomDto,
  GetFormEntriesModel,
  toURLSearchParams,
  EmailVariableDto,
  GetReviewsModel,
  ReviewDto,
  toReviewsSearchParams,
  OrganizationDto,
  OrganizationBankAccountDto,
  OrganizationBankAccountConnectionDto,
  LicenseDto,
  SsoStateDto,
  PresentationType,
  UserRegistrationDto,
  UserSubmissionDto,
  UserTransactionDto,
  CouponCodeDto,
  TrackDto,
  GetUserSubmissionsModel,
  GetUserRegistrationsModel,
  GetUserTransactionsModel,
  GetEventsWithAssignedSubmissionsModel,
  EventWithAssignedSubmissionsDto,
  toTransactionsSearchParams,
  UpdateOrganizationModel,
  GetOrganizationBankAccountConnectionsModel,
  GetOrganizationBankAccountsModel,
  UpgradePricingDto,
  UpgradePricingResponseDto,
} from 'fourwaves-shared';
import { ChangePasswordModel, EditProfileModel } from './models/account';
import {
  GetRegistrationStatisticsDto,
  GetSubmissionStatisticsDto,
  GetTransactionStatisticsDto,
  GetCommunicationStatisticsDto,
  GetReviewStatisticsDto,
} from './models/events/statistics';

import { CreateUpdateContentModel, CreateContentResult } from './models/pages/CreateUpdateContentModel';
import { UnlockEventModel } from './models/events/UnlockEventModel';
import { GetStartedModel, GetStartedResult } from './models/get-started';
import type { DeleteOrganizationBankAccountModel } from './models/organizations/DeleteOrganizationBankAccountModel';
import {
  CloneEventModel,
  UpdateEventModel,
  InviteUserModel,
  UpdateUserModel,
  RemoveUserModel,
  UpdateRegistrationProcessModel,
  UptimeDto,
  CreateUpdateFormFieldModel,
  CreateUpdateFormFieldChoiceModel,
  CreateUpdateFormSectionModel,
  UpdateSubmissionProcessModel,
  UpdateReviewProcessModel,
  UpdatePageModel,
  CreatePageModel,
  UpdateThemeModel,
  SendMessageModel,
  CreateUpdateScheduleModel,
  ExportFileType,
  CreateUpdateRoomModel,
  UpdateAbstractIdModel,
  UpdateSubmissionDecisionModel,
  UpdateFormEntryPublishedStatusModel,
  UpdateConfirmationEmailModel,
  UpdatePaymentOptions,
  AssignAbstractModel,
  UpdateReviewModel,
  AssignAbstractResult,
  UpdateOrganizationBankAccountModel,
  CreateOrganizationBankAccountModel,
  CreateOrganizationBankAccountConnectionModel,
  UpdateOrganizationBankAccountConnectionModel,
  CreateEventModel,
  CreateEventResult,
  CreateSchedulePresentationModel,
  DeleteSchedulePresentationModel,
  CreatePresentationTypeModel,
  UpdatePresentationTypeModel,
  GetSentEmailsModel,
  SentEmailDto,
  SentEmailDetailsDto,
  CampaignDto,
  KnowledgeArticlesDto,
  CreateUpdateCouponCodeModel,
  PackageDto,
  CreateTrackModel,
  UpdateTrackModel,
  CreateScheduleTrackModel,
  ExportScheduleModel,
  DeleteEventModel,
  ExportSubmissionAsFilesModel,
  ExportSubmissionAsFilesStatus,
  OrganizationUserDto,
  UpdateOrganizationBankAccountPaymentGatewayModel,
  MoveEventsToOrganizationModel,
  AddOnWithEventStatusDto,
} from './models';

export interface IApiService extends IFourwavesApiService {
  // profile / account
  getSettings(): Promise<SettingsDto>;
  editProfile(model: EditProfileModel): Promise<TypeOrError<boolean>>;
  changePassword(model: ChangePasswordModel): Promise<TypeOrError<boolean>>;
  deleteAccount(passphrase: string): Promise<TypeOrError<boolean>>;
  ssoState(email: string): Promise<SsoStateDto>;

  // organizations
  getOrganizations(): Promise<OrganizationDto[]>;
  getOrganization(id: string): Promise<OrganizationDto>;
  updateOrganization(model: UpdateOrganizationModel): Promise<TypeOrError<boolean>>;
  getOrganizationUsers(id: string): Promise<OrganizationUserDto[]>;
  removeOrganizationUser(userId: string, organizationId: string): Promise<TypeOrError<string | boolean>>;
  moveEventsToOrganization(model: MoveEventsToOrganizationModel): Promise<TypeOrError<boolean>>;

  getOrganizationBankAccounts(
    model: GetOrganizationBankAccountsModel,
  ): Promise<PaginatedList<OrganizationBankAccountDto>>;
  createOrganizationBankAccount(model: CreateOrganizationBankAccountModel): Promise<TypeOrError<string>>;
  updateOrganizationBankAccount(model: UpdateOrganizationBankAccountModel): Promise<TypeOrError<boolean>>;
  updateOrganizationBankAccountPaymentGateway(
    model: UpdateOrganizationBankAccountPaymentGatewayModel,
  ): Promise<TypeOrError<boolean>>;
  deleteOrganizationBankAccount(model: DeleteOrganizationBankAccountModel): Promise<TypeOrError<boolean>>;
  getOrganizationBankAccountConnections(
    model: GetOrganizationBankAccountConnectionsModel,
  ): Promise<PaginatedList<OrganizationBankAccountConnectionDto>>;
  createOrganizationBankAccountConnection(
    model: CreateOrganizationBankAccountConnectionModel,
  ): Promise<TypeOrError<boolean>>;
  updateOrganizationBankAccountConnection(
    model: UpdateOrganizationBankAccountConnectionModel,
  ): Promise<TypeOrError<boolean>>;

  // events
  createEvent(model: CreateEventModel): Promise<TypeOrError<CreateEventResult>>;
  completeRegistration(model: any): Promise<TypeOrError<AuthenticationResult>>;
  updateEvent(model: UpdateEventModel, locale?: string): Promise<TypeOrError<boolean>>;
  updateTheme(model: UpdateThemeModel): Promise<TypeOrError<boolean>>;
  updateRegistrationProcess(model: UpdateRegistrationProcessModel, locale?: string): Promise<TypeOrError<boolean>>;
  updateSubmissionProcess(model: UpdateSubmissionProcessModel, locale?: string): Promise<TypeOrError<boolean>>;
  updateReviewProcess(model: UpdateReviewProcessModel): Promise<TypeOrError<boolean>>;
  cloneEvent(model: CloneEventModel): Promise<TypeOrError<string | boolean>>;
  publishEvent(id: string): Promise<TypeOrError<boolean>>;
  unlockEvent(id: string, model: UnlockEventModel): Promise<TypeOrError<boolean>>;
  getOrganizationBankAccountConnection(eventId: string): Promise<OrganizationBankAccountConnectionDto | null>;
  cancelBankAccountConnection(id: string): Promise<TypeOrError<boolean>>;

  getKnowledgeArticles(context: string): Promise<KnowledgeArticlesDto>;

  getUsers(id: string): Promise<GetUsersDto>;
  getUserRegistrations(model: GetUserRegistrationsModel): Promise<PaginatedList<UserRegistrationDto>>;
  getUserSubmissions(model: GetUserSubmissionsModel): Promise<PaginatedList<UserSubmissionDto>>;
  getUserTransactions(model: GetUserTransactionsModel): Promise<PaginatedList<UserTransactionDto>>;
  getEventsWithAssignedSubmissions(
    model: GetEventsWithAssignedSubmissionsModel,
  ): Promise<PaginatedList<EventWithAssignedSubmissionsDto>>;
  inviteUser(model: InviteUserModel): Promise<TypeOrError<string | boolean>>;
  updateUser(model: UpdateUserModel): Promise<TypeOrError<boolean>>;
  removeUser(model: RemoveUserModel): Promise<TypeOrError<string | boolean>>;

  getRegistrationStatistics(id: string): Promise<GetRegistrationStatisticsDto>;
  getSubmissionStatistics(id: string): Promise<GetSubmissionStatisticsDto>;
  getTransactionStatistics(id: string): Promise<GetTransactionStatisticsDto>;
  getCommunicationStatistics(id: string): Promise<GetCommunicationStatisticsDto>;
  getReviewStatistics(id: string): Promise<GetReviewStatisticsDto>;

  exportUsageStatistics(id: string): Promise<void>;

  deleteEvent(model: DeleteEventModel): Promise<TypeOrError<boolean>>;

  // upgrade
  getUpgradePricing(model: UpgradePricingDto): Promise<UpgradePricingResponseDto>;

  // licenses
  getLicense(codeOrId: string): Promise<LicenseDto | null>;

  // payment gateways
  getOrganizationBankAccountByCode(code: string): Promise<OrganizationBankAccountDto | null>;
  getOrganizationBankAccountById(id: string, organizationId: string): Promise<OrganizationBankAccountDto | null>;
  initStripeConnect(id: string, callbackUrl?: string): Promise<TypeOrError<string>>;
  updateCurrency(id: string, currency: string): Promise<TypeOrError<boolean>>;
  updatePaymentOptions(model: UpdatePaymentOptions): Promise<TypeOrError<boolean>>;

  // coupon codes
  getCouponCodes(eventId: string): Promise<PaginatedList<CouponCodeDto>>;
  createCouponCode(model: CreateUpdateCouponCodeModel): Promise<TypeOrError<boolean>>;
  updateCouponCode(model: CreateUpdateCouponCodeModel): Promise<TypeOrError<boolean>>;

  // pages
  createPage(model: CreatePageModel, locale: string): Promise<TypeOrError<{ id: string }>>;
  updatePage(model: UpdatePageModel, locale: string): Promise<TypeOrError<boolean>>;
  reorderPage(pageId: string, order: number, parentId: string | null): Promise<TypeOrError<boolean>>;
  deletePage(pageId: string): Promise<TypeOrError<boolean>>;
  updateContent(model: CreateUpdateContentModel, locale: string): Promise<TypeOrError<boolean>>;
  createContent(model: CreateUpdateContentModel, locale: string): Promise<TypeOrError<CreateContentResult>>;
  moveContent(pageId: string, contentId: string, order: number): Promise<TypeOrError<boolean>>;
  deleteContent(contentId: string): Promise<TypeOrError<boolean>>;

  // forms and children entities
  getForm(id: string): Promise<FormDto>;

  getFormSection(id: string): Promise<FormSectionDto>;
  createFormSection(model: CreateUpdateFormSectionModel, locale: string): Promise<TypeOrError<boolean>>;
  updateFormSection(model: CreateUpdateFormSectionModel, locale: string): Promise<TypeOrError<boolean>>;
  deleteFormSection(formId: string, sectionId: string): Promise<TypeOrError<boolean>>;
  moveFormSection(formId: string, sectionId: string, order: number): Promise<TypeOrError<boolean>>;
  getFormSectionEntryValueCount(sectionId: string): Promise<TypeOrError<number>>;

  getFormField(id: string): Promise<FormFieldDto>;
  createFormField(model: CreateUpdateFormFieldModel, locale: string): Promise<TypeOrError<boolean>>;
  cloneFormField(fieldId: string, locale: string): Promise<TypeOrError<string>>;
  updateFormField(model: CreateUpdateFormFieldModel, locale: string): Promise<TypeOrError<boolean>>;
  moveFormField(sectionId: string, fieldId: string, order: number): Promise<TypeOrError<boolean>>;

  createFormFieldChoice(
    fieldId: string,
    model: CreateUpdateFormFieldChoiceModel,
    locale: string,
  ): Promise<TypeOrError<boolean>>;
  updateFormFieldChoice(
    fieldId: string,
    model: CreateUpdateFormFieldChoiceModel,
    locale: string,
  ): Promise<TypeOrError<boolean>>;
  moveFormFieldChoice(fieldId: string, choiceId: string, order: number): Promise<TypeOrError<boolean>>;

  getFormFieldEntryValueCount(fieldId: string): Promise<TypeOrError<number>>;
  getFormFieldChoiceEntryValueCount(fieldId: string, fieldChoiceId: string): Promise<TypeOrError<number>>;

  deleteFormField(fieldId: string): Promise<TypeOrError<boolean>>;
  deleteFormFieldChoice(fieldId: string, fieldChoiceId: string): Promise<TypeOrError<boolean>>;

  getEmailVariables(formId: string): Promise<Array<EmailVariableDto>>;
  updateConfirmationEmail(model: UpdateConfirmationEmailModel): Promise<TypeOrError<boolean>>;

  // form entries
  updateAbstractId(model: UpdateAbstractIdModel): Promise<TypeOrError<string | boolean>>;
  exportFormEntries(
    id: string,
    model: GetFormEntriesModel,
    fileType: ExportFileType,
    formFieldIds?: string[],
  ): Promise<void>;
  getExportSubmissionAsFilesStatus(formId: string): Promise<ExportSubmissionAsFilesStatus>;
  exportSubmissionAsFiles(model: ExportSubmissionAsFilesModel): Promise<TypeOrError<boolean>>;
  deleteFormEntries(id: string, model: GetFormEntriesModel): Promise<TypeOrError<boolean>>;
  assignFormEntries(
    model: GetFormEntriesModel,
    assignModel: AssignAbstractModel,
  ): Promise<TypeOrError<AssignAbstractResult>>;
  acceptFromWaitlist(formEntryId: string): Promise<TypeOrError<boolean>>;
  updateSubmissionDecision(
    model: GetFormEntriesModel,
    updateModel: UpdateSubmissionDecisionModel,
  ): Promise<TypeOrError<string | boolean>>;
  updateFormEntryPublishedStatus(model: UpdateFormEntryPublishedStatusModel): Promise<TypeOrError<string | boolean>>;

  // presentation types
  getPresentationTypes(eventId: string): Promise<PresentationType[]>;
  createPresentationType(model: CreatePresentationTypeModel): Promise<TypeOrError<boolean>>;
  updatePresentationType(model: UpdatePresentationTypeModel): Promise<TypeOrError<boolean>>;
  deletePresentationType(eventId: string, id: string): Promise<TypeOrError<boolean>>;

  // reviews
  getReview(eventId: string, id: string): Promise<ReviewDto>;
  updateReview(model: UpdateReviewModel): Promise<TypeOrError<boolean>>;
  deleteReviews(eventId: string, model: GetReviewsModel): Promise<TypeOrError<boolean>>;
  countRecipients(model: GetReviewsModel): Promise<number>;
  sendMessageToReviewers(model: SendMessageModel): Promise<TypeOrError<boolean>>;
  exportReviews(model: GetReviewsModel): Promise<void>;

  // transactions
  getTransactions(model: GetTransactionsModel): Promise<PaginatedList<TransactionDto>>;
  updateTransaction(transactionId: string, status: 'pending' | 'concluded'): Promise<TypeOrError<boolean>>;
  deleteTransaction(transactionId: string): Promise<TypeOrError<boolean>>;
  createRefund(transactionId: string, amount: number): Promise<TypeOrError<boolean>>;
  exportTransactions(model: GetTransactionsModel): Promise<void>;

  // monitoring
  getUptime(): Promise<UptimeDto>;

  // redirects
  getEventWebsiteUrl(eventId: string): string;
  getEventWebsiteScheduleUrl(eventId: string): string;
  getEventWebsiteUrlWithFormEntryId(formEntryId: string): string;
  getEventWebsiteUrlWithTransactionId(eventId: string, transactionId: string): string;
  getEventWebsiteUrlWithPresentationId(eventId: string, presentationId: string): string;

  // mailing
  sendMessage(model: SendMessageModel): Promise<TypeOrError<boolean>>;
  resendEmail(emailId: string, eventId: string): Promise<TypeOrError<boolean>>;

  // sessions
  createSession(model: CreateUpdateScheduleModel): Promise<TypeOrError<boolean>>;
  updateSession(model: CreateUpdateScheduleModel): Promise<TypeOrError<boolean>>;
  deleteSession(sessionId: string): Promise<TypeOrError<boolean>>;

  // rooms
  createRoom(model: CreateUpdateRoomModel, locale: string): Promise<TypeOrError<RoomDto>>;
  updateRoom(model: CreateUpdateRoomModel, locale: string): Promise<TypeOrError<boolean>>;
  deleteRoom(id: string): Promise<TypeOrError<boolean>>;

  // sessionPresentations
  createSessionPresentations(
    model: GetFormEntriesModel,
    assignModel: CreateSchedulePresentationModel,
  ): Promise<TypeOrError<boolean>>;
  updateSessionPresentation(id: string, minutes: number): Promise<TypeOrError<boolean>>;
  moveSessionPresentation(sessionId: string, id: string, index: number): Promise<TypeOrError<boolean>>;
  deleteSessionPresentation(id: string): Promise<TypeOrError<boolean>>;
  deleteSessionPresentationsBySessionId(sessionId: string): Promise<TypeOrError<boolean>>;

  // schedule
  exportSchedule(model: ExportScheduleModel): Promise<void>;

  // communications
  getCampaigns(eventId: string): Promise<CampaignDto[]>;
  getSentEmails(model: GetSentEmailsModel): Promise<PaginatedList<SentEmailDto>>;
  getSentEmailDetails(eventId: string, sentEmailId: string): Promise<SentEmailDetailsDto>;

  // packages
  getPackages(): Promise<PackageDto[]>;
  getPackage(id: string): Promise<PackageDto>;

  // add-ons
  getAddOnsWithEventStatus(eventId: string): Promise<AddOnWithEventStatusDto[]>;

  // tracks
  getTracks(eventId: string): Promise<TrackDto[]>;
  createTrack(model: CreateTrackModel): Promise<TypeOrError<TrackDto>>;
  updateTrack(model: UpdateTrackModel): Promise<TypeOrError<boolean>>;
  deleteTrack(eventId: string, id: string): Promise<TypeOrError<boolean>>;
  createSessionTrack(model: CreateScheduleTrackModel): Promise<TypeOrError<boolean>>;
  deleteSessionTrack(sessionId: string, trackId: string): Promise<TypeOrError<boolean>>;

  // get started
  getStarted(model: GetStartedModel): Promise<TypeOrError<GetStartedResult>>;
}

export class ApiService extends BaseApiService implements IApiService {
  public async getSettings(): Promise<SettingsDto> {
    const response = await this._client.get<SettingsDto>(`/api/account/settings`);
    return response.data;
  }

  public async editProfile(model: EditProfileModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/account/profile`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async changePassword(model: ChangePasswordModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put('/api/account/change-password', model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async ssoState(email: string): Promise<SsoStateDto> {
    const request = `/api/auth/sso-state?email=${encodeURI(email)}`;
    const result = await this._client.get<SsoStateDto>(request);
    return result.data;
  }

  public async deleteAccount(passphrase: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post('/api/account/delete', { passphrase });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteEvent(model: DeleteEventModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post('/api/events/delete', model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getUpgradePricing(model: UpgradePricingDto): Promise<UpgradePricingResponseDto> {
    const response = await this._client.post<UpgradePricingResponseDto>(
      `/v1.0/events/${model.eventId}/upgrade/pricing`,
      model,
    );
    return response.data;
  }

  public async getOrganization(id: string): Promise<OrganizationDto> {
    const response = await this._client.get<OrganizationDto>(`/api/organizations/${id}`);
    return response.data;
  }

  public async updateOrganization(model: UpdateOrganizationModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/organizations/${model.id}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getOrganizations(): Promise<OrganizationDto[]> {
    const response = await this._client.get<OrganizationDto[]>('/api/organizations');
    return response.data;
  }

  public async getOrganizationUsers(id: string): Promise<OrganizationUserDto[]> {
    const response = await this._client.get<OrganizationUserDto[]>(`/api/organizations/${id}/users`);
    return response.data;
  }

  public async removeOrganizationUser(userId: string, organizationId: string): Promise<TypeOrError<string | boolean>> {
    try {
      await this._client.delete(`/api/organizations/${organizationId}/users/${userId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async moveEventsToOrganization(model: MoveEventsToOrganizationModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/organizations/${model.destinationOrganizationId}/events/move`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getOrganizationBankAccounts(
    model: GetOrganizationBankAccountsModel,
  ): Promise<PaginatedList<OrganizationBankAccountDto>> {
    const { organizationId, ...queryParams } = model;
    const searchParams = new URLSearchParams(queryParams as any);
    const response = await this._client.get<PaginatedList<OrganizationBankAccountDto>>(
      `/api/organizations/${organizationId}/bank-accounts?${searchParams.toString()}`,
    );
    return response.data;
  }

  public async createOrganizationBankAccount(model: CreateOrganizationBankAccountModel): Promise<TypeOrError<string>> {
    try {
      const response = await this._client.post(`/api/organizations/${model.organizationId}/bank-accounts`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateOrganizationBankAccount(model: UpdateOrganizationBankAccountModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/organizations/${model.orgId}/bank-accounts/${model.id}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateOrganizationBankAccountPaymentGateway(
    model: UpdateOrganizationBankAccountPaymentGatewayModel,
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(
        `/api/organizations/${model.organizationId}/bank-accounts/${model.bankAccountId}/gateways`,
        model,
      );
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteOrganizationBankAccount(model: DeleteOrganizationBankAccountModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/organizations/${model.organizationId}/bank-accounts/${model.bankAccountId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getOrganizationBankAccountConnections(
    model: GetOrganizationBankAccountConnectionsModel,
  ): Promise<PaginatedList<OrganizationBankAccountConnectionDto>> {
    const { organizationId, bankAccountId, ...queryParams } = model;
    const searchParams = new URLSearchParams(queryParams as any);
    const response = await this._client.get<PaginatedList<OrganizationBankAccountConnectionDto>>(
      `/api/organizations/${organizationId}/bank-accounts/${bankAccountId}/connections?${searchParams.toString()}`,
    );
    return response.data;
  }

  public async createOrganizationBankAccountConnection(
    model: CreateOrganizationBankAccountConnectionModel,
  ): Promise<TypeOrError<boolean>> {
    try {
      const url = `/api/organizations/${model.organizationId}/bank-accounts/${model.bankAccountId}/connections`;
      await this._client.post(url, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateOrganizationBankAccountConnection(
    model: UpdateOrganizationBankAccountConnectionModel,
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(
        `/api/organizations/${model.organizationId}/bank-accounts/${model.bankAccountId}/connections/${model.id}`,
        model,
      );
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createEvent(model: CreateEventModel): Promise<TypeOrError<CreateEventResult>> {
    try {
      const response = await this._client.post<CreateEventResult>(`/api/account/create-event`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async completeRegistration(model: any): Promise<TypeOrError<AuthenticationResult>> {
    try {
      const response = await this._client.post(`/api/account/complete-registration`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateEvent(model: UpdateEventModel, locale?: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.id}/${locale || ''}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateTheme(model: UpdateThemeModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.id}/theme`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateRegistrationProcess(
    model: UpdateRegistrationProcessModel,
    locale?: string,
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.id}/update-registration-process/${locale || ''}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateSubmissionProcess(
    model: UpdateSubmissionProcessModel,
    locale?: string,
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.id}/update-submission-process/${locale || ''}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getKnowledgeArticles(context: string): Promise<KnowledgeArticlesDto> {
    const response = await this._client.get<KnowledgeArticlesDto>(`/api/knowledgeArticles?context=${context}`);
    return response.data;
  }

  public async getUsers(id: string): Promise<GetUsersDto> {
    const response = await this._client.get<GetUsersDto>(`/api/events/${id}/users`);
    return response.data;
  }

  public async getUserRegistrations(model: GetUserRegistrationsModel): Promise<PaginatedList<UserRegistrationDto>> {
    const query = new URLSearchParams(model as any);
    query.delete('userId');
    const response = await this._client.get<PaginatedList<UserRegistrationDto>>(
      `/v1.0/users/${model.userId}/registrations?${query.toString()}`,
    );
    return response.data;
  }

  public async getUserSubmissions(model: GetUserSubmissionsModel): Promise<PaginatedList<UserSubmissionDto>> {
    const query = new URLSearchParams(model as any);
    query.delete('userId');
    const response = await this._client.get<PaginatedList<UserSubmissionDto>>(
      `/v1.0/users/${model.userId}/submissions?${query.toString()}`,
    );
    return response.data;
  }

  public async getUserTransactions(model: GetUserTransactionsModel): Promise<PaginatedList<UserTransactionDto>> {
    const query = new URLSearchParams(model as any);
    query.delete('userId');
    const response = await this._client.get<PaginatedList<UserTransactionDto>>(
      `/v1.0/users/${model.userId}/transactions?${query.toString()}`,
    );
    return response.data;
  }

  public async getEventsWithAssignedSubmissions(
    model: GetEventsWithAssignedSubmissionsModel,
  ): Promise<PaginatedList<EventWithAssignedSubmissionsDto>> {
    const query = new URLSearchParams(model as any);
    query.delete('userId');
    const response = await this._client.get<PaginatedList<EventWithAssignedSubmissionsDto>>(
      `/v1.0/users/${model.userId}/events-with-assigned-submissions?${query.toString()}`,
    );
    return response.data;
  }

  public async inviteUser(model: InviteUserModel): Promise<TypeOrError<string | boolean>> {
    try {
      if (model.eventId) {
        await this._client.put(`/api/events/${model.eventId}/users`, model);
      } else {
        await this._client.put(`/api/organizations/${model.organizationId}/users`, model);
      }
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateUser(model: UpdateUserModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.eventId}/users/${model.userId}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async removeUser(model: RemoveUserModel): Promise<TypeOrError<string | boolean>> {
    try {
      await this._client.put(`/api/events/${model.eventId}/remove-user`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getRegistrationStatistics(id: string): Promise<GetRegistrationStatisticsDto> {
    const response = await this._client.get<GetRegistrationStatisticsDto>(`/api/events/${id}/statistics/registration`);
    return response.data;
  }

  public async getSubmissionStatistics(id: string): Promise<GetSubmissionStatisticsDto> {
    const response = await this._client.get<GetSubmissionStatisticsDto>(`/api/events/${id}/statistics/submission`);
    return response.data;
  }

  public async updateAbstractId(model: UpdateAbstractIdModel): Promise<TypeOrError<string | boolean>> {
    try {
      await this._client.put(`/api/form-entries/${model.formId}/update-sequential-id`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async exportFormEntries(
    formId: string,
    model: GetFormEntriesModel,
    fileType: ExportFileType,
    formFieldIds?: string[],
  ): Promise<void> {
    const query = toURLSearchParams(model);
    query.append('fileType', fileType.toString());

    if (formFieldIds && Array.isArray(formFieldIds)) {
      formFieldIds.forEach(id => query.append('formFieldIds', id));
    }

    const request = `/api/form-entries/${formId}/export?${query.toString()}`;
    try {
      const response = await this._client.get(request, { responseType: 'blob' });
      const filename = extractFileNameFromHeaders(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (exp) {
      throw new Error('EXPORT ERROR');
    }
  }

  public async getExportSubmissionAsFilesStatus(formId: string) {
    const request = `/api/form-entries/${formId}/export/files`;
    const response = await this._client.get<ExportSubmissionAsFilesStatus>(request);
    return response.data;
  }

  public async exportSubmissionAsFiles(model: ExportSubmissionAsFilesModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/form-entries/${model.query.formId}/export/files`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteFormEntries(formId: string, model: GetFormEntriesModel): Promise<TypeOrError<boolean>> {
    const query = toURLSearchParams(model);
    const request = `/api/form-entries/${formId}/delete?${query.toString()}`;
    try {
      await this._client.delete(request);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async assignFormEntries(
    model: GetFormEntriesModel,
    assignModel: AssignAbstractModel,
  ): Promise<TypeOrError<AssignAbstractResult>> {
    const request = `/api/form-entries/${model.formId}/assign?${toURLSearchParams(model)}`;
    try {
      const result = await this._client.post<AssignAbstractResult>(request, assignModel);
      return result.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async acceptFromWaitlist(formEntryId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post<AssignAbstractResult>(`/api/form-entries/${formEntryId}/accept`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateSubmissionDecision(
    model: GetFormEntriesModel,
    updateModel: UpdateSubmissionDecisionModel,
  ): Promise<TypeOrError<string | boolean>> {
    try {
      await this._client.put(
        `/api/form-entries/${model.formId}/update-submission-decision?${toURLSearchParams(model)}`,
        updateModel,
      );
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateFormEntryPublishedStatus(
    model: UpdateFormEntryPublishedStatusModel,
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/form-entries/update-published-status`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getTransactionStatistics(id: string): Promise<GetTransactionStatisticsDto> {
    const response = await this._client.get<GetTransactionStatisticsDto>(`/api/events/${id}/statistics/transaction`);
    return response.data;
  }

  public async getCommunicationStatistics(id: string): Promise<GetCommunicationStatisticsDto> {
    const response = await this._client.get<GetCommunicationStatisticsDto>(
      `/api/events/${id}/statistics/communication`,
    );
    return response.data;
  }

  public async getReviewStatistics(id: string): Promise<GetReviewStatisticsDto> {
    const response = await this._client.get<GetReviewStatisticsDto>(`/api/events/${id}/statistics/review`);
    return response.data;
  }

  public async exportUsageStatistics(id: string): Promise<void> {
    const request = `/api/events/${id}/export-usage-stats`;
    try {
      await this._client.post(request);
    } catch (exp) {
      throw new Error('EXPORT ERROR');
    }
  }

  public async getLicense(codeOrId: string): Promise<LicenseDto | null> {
    try {
      const response = await this._client.get(`/api/licenses/${codeOrId}`);
      return response.data;
    } catch {
      return null;
    }
  }

  public async getOrganizationBankAccountByCode(code: string): Promise<OrganizationBankAccountDto | null> {
    const response = await this._client.get<OrganizationBankAccountDto>(
      `/api/payment-gateways/bank-accounts?code=${code}`,
    );
    return response.data;
  }

  public async getOrganizationBankAccountById(
    id: string,
    organizationId: string,
  ): Promise<OrganizationBankAccountDto | null> {
    const response = await this._client.get<OrganizationBankAccountDto>(
      `/api/organizations/${organizationId}/bank-accounts/${id}`,
    );
    return response.data;
  }

  public async initStripeConnect(id: string, callbackUrl?: string): Promise<TypeOrError<string>> {
    try {
      const params = new URLSearchParams({ id });
      if (callbackUrl) params.append('callbackUrl', callbackUrl);
      const response = await this._client.post(`/api/payment-gateways/stripe/init?${params.toString()}`);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateCurrency(id: string, currency: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${id}/update-currency`, { currency });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updatePaymentOptions(model: UpdatePaymentOptions): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.id}/update-payment-options`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getCouponCodes(eventId: string): Promise<PaginatedList<CouponCodeDto>> {
    const response = await this._client.get<PaginatedList<CouponCodeDto>>(`/api/events/${eventId}/coupon-codes`);
    return response.data;
  }

  public async createCouponCode(model: CreateUpdateCouponCodeModel): Promise<TypeOrError<boolean>> {
    try {
      const result = await this._client.post(`/api/events/${model.eventId}/coupon-code`, model);
      return result.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateCouponCode(model: CreateUpdateCouponCodeModel): Promise<TypeOrError<boolean>> {
    try {
      const result = await this._client.put(`/api/events/${model.eventId}/coupon-code/${model.id}`, model);
      return result.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async cloneEvent(model: CloneEventModel): Promise<TypeOrError<string | boolean>> {
    try {
      const response = await this._client.post(`/api/events/${model.id}/clone`, model);
      return response.data.resourceId;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async publishEvent(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${id}/publish`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async unlockEvent(id: string, model: UnlockEventModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${id}/unlock`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getOrganizationBankAccountConnection(
    eventId: string,
  ): Promise<OrganizationBankAccountConnectionDto | null> {
    const response = await this._client.get<OrganizationBankAccountConnectionDto>(
      `/api/events/${eventId}/bank-account-connection`,
    );
    return response.data;
  }

  public async cancelBankAccountConnection(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/organizations/bank-account-connections/${id}/cancel`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateReviewProcess(model: UpdateReviewProcessModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.id}/update-review-process`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createPage(model: CreatePageModel, locale: string): Promise<TypeOrError<{ id: string }>> {
    try {
      const response = await this._client.post(`/api/pages/${locale}`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updatePage(model: UpdatePageModel, locale: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/pages/${model.id}/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async reorderPage(pageId: string, order: number, parentId: string | null): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/pages/${pageId}/order`, { order, parentId });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deletePage(pageId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/pages/${pageId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateContent(model: CreateUpdateContentModel, locale: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/pages/${model.pageId}/content/${model.id}/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createContent(
    model: CreateUpdateContentModel,
    locale: string,
  ): Promise<TypeOrError<CreateContentResult>> {
    try {
      const response = await this._client.post(`/api/pages/${model.pageId}/content/${locale}`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async moveContent(pageId: string, contentId: string, order: number): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/pages/${pageId}/content/${contentId}/move/${order}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteContent(contentId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/pages/content/${contentId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getForm(id: string): Promise<FormDto> {
    const response = await this._client.get<FormDto>(`/api/forms/${id}`);

    // Ordering
    if (response.data) {
      response.data.formSections = sortBy(response.data.formSections, 'order');
      response.data.formSections.forEach(section => {
        section.formFields = sortBy(section.formFields, 'order');
        section.formFields.forEach(field => {
          field.formFieldChoices = sortBy(field.formFieldChoices, 'order');
        });
      });
    }

    return response.data;
  }

  public async getFormSection(id: string): Promise<FormSectionDto> {
    const response = await this._client.get<FormSectionDto>(`/api/form-sections/${id}`);

    // Ordering
    if (response.data) {
      response.data.formFields = sortBy(response.data.formFields, 'order');
      response.data.formFields.forEach(field => {
        field.formFieldChoices = sortBy(field.formFieldChoices, 'order');
      });
    }

    return response.data;
  }

  public async createFormSection(model: CreateUpdateFormSectionModel, locale: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/forms/${model.formId}/sections/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateFormSection(model: CreateUpdateFormSectionModel, locale: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/forms/${model.formId}/sections/${model.id}/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteFormSection(formId: string, sectionId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/forms/${formId}/sections/${sectionId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async moveFormSection(formId: string, sectionId: string, order: number): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/forms/${formId}/sections/${sectionId}/move/${order}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getFormSectionEntryValueCount(sectionId: string): Promise<TypeOrError<number>> {
    const response = await this._client.get(`/api/form-sections/${sectionId}/get-entry-value-count`);
    return response.data;
  }

  public async moveFormField(sectionId: string, fieldId: string, order: number): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/form-sections/${sectionId}/fields/${fieldId}/move/${order}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getFormField(id: string): Promise<FormFieldDto> {
    const response = await this._client.get<FormFieldDto>(`/api/form-fields/${id}`);
    return response.data;
  }

  public async createFormField(model: CreateUpdateFormFieldModel, locale: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/form-fields/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async cloneFormField(fieldId: string, locale: string): Promise<TypeOrError<string>> {
    try {
      const response = await this._client.post(`/api/form-fields/clone/${locale}/${fieldId}`);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateFormField(model: CreateUpdateFormFieldModel, locale: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/form-fields/${model.id}/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createFormFieldChoice(
    fieldId: string,
    model: CreateUpdateFormFieldChoiceModel,
    locale: string,
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/form-fields/${fieldId}/choices/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateFormFieldChoice(
    fieldId: string,
    model: CreateUpdateFormFieldChoiceModel,
    locale: string,
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/form-fields/${fieldId}/choices/${model.id}/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async moveFormFieldChoice(fieldId: string, choiceId: string, order: number): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/form-fields/${fieldId}/choices/${choiceId}/move/${order}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getFormFieldEntryValueCount(fieldId: string): Promise<TypeOrError<number>> {
    const response = await this._client.get(`/api/form-fields/${fieldId}/get-entry-value-count`);
    return response.data;
  }

  public async getFormFieldChoiceEntryValueCount(fieldId: string, fieldChoiceId: string): Promise<TypeOrError<number>> {
    const response = await this._client.get(
      `/api/form-fields/${fieldId}/choices/${fieldChoiceId}/get-entry-value-count`,
    );
    return response.data;
  }

  public async deleteFormField(fieldId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/form-fields/${fieldId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteFormFieldChoice(fieldId: string, fieldChoiceId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/form-fields/${fieldId}/choices/${fieldChoiceId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getEmailVariables(formId: string): Promise<Array<EmailVariableDto>> {
    const response = await this._client.get(`/api/forms/${formId}/email-variables`);
    return response.data;
  }

  public async updateConfirmationEmail(model: UpdateConfirmationEmailModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/forms/${model.id}/update-confirmation-email`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getPresentationTypes(eventId: string): Promise<PresentationType[]> {
    const response = await this._client.get<PresentationType[]>(`/api/events/${eventId}/presentation-types`);
    return response.data;
  }

  public async createPresentationType(model: CreatePresentationTypeModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/events/${model.eventId}/presentation-types/${model.locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updatePresentationType(model: UpdatePresentationTypeModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.eventId}/presentation-types/${model.id}/${model.locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deletePresentationType(eventId: string, id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/events/${eventId}/presentation-types/${id}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getReview(eventId: string, id: string): Promise<ReviewDto> {
    const response = await this._client.get<ReviewDto>(`/api/reviews/${eventId}/${id}`);
    return response.data;
  }

  public async updateReview(model: UpdateReviewModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/reviews/${model.eventId}/${model.reviewId}/update`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteReviews(eventId: string, model: GetReviewsModel): Promise<TypeOrError<boolean>> {
    const request = `/api/reviews/${eventId}/delete?${toReviewsSearchParams(model).toString()}`;
    try {
      await this._client.delete(request);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async countRecipients(model: GetReviewsModel): Promise<number> {
    const response = await this._client.get<number>(
      `/api/reviews/${model.eventId}/count-recipients?${toReviewsSearchParams(model).toString()}`,
    );
    return response.data;
  }

  public async sendMessageToReviewers(model: SendMessageModel): Promise<TypeOrError<boolean>> {
    const typedModel = model.query as GetReviewsModel;
    if (typedModel.status === '') {
      // asp.net cannot deserialize empty string to nullable JSON
      typedModel.status = null;
    }
    try {
      await this._client.post(`/api/reviews/${typedModel.eventId}/send`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async exportReviews(model: GetReviewsModel): Promise<void> {
    try {
      const query = toReviewsSearchParams(model);
      const request = `/api/reviews/${model.eventId}/export?${query.toString()}`;
      const response = await this._client.get(request, { responseType: 'blob' });
      const filename = extractFileNameFromHeaders(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (exp) {
      throw new Error('EXPORT ERROR');
    }
  }

  public async getTransactions(model: GetTransactionsModel): Promise<PaginatedList<TransactionDto>> {
    const query = new URLSearchParams(model as any);
    query.delete('eventId');
    query.delete('organizationId');
    const url = model.eventId
      ? `/api/events/${model.eventId}/transactions?${query.toString()}`
      : `/api/organizations/${model.organizationId}/transactions?${query.toString()}`;
    const response = await this._client.get<PaginatedList<TransactionDto>>(url);
    return response.data;
  }

  public async updateTransaction(
    transactionId: string,
    status: 'pending' | 'concluded',
  ): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/accounting/transactions/${transactionId}`, { status });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteTransaction(transactionId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/accounting/transactions/${transactionId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createRefund(transactionId: string, amount: number): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/accounting/transactions/${transactionId}/refund`, {
        amount,
      });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async exportTransactions(model: GetTransactionsModel): Promise<void> {
    const query = toTransactionsSearchParams(model);
    query.delete('eventId');
    query.delete('organizationId');
    const request = model.eventId
      ? `/api/events/${model.eventId}/transactions/export?${query.toString()}`
      : `/api/organizations/${model.organizationId}/transactions/export?${query.toString()}`;
    try {
      const response = await this._client.get(request, { responseType: 'blob' });
      const filename = extractFileNameFromHeaders(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (exp) {
      throw new Error('EXPORT ERROR');
    }
  }

  public async exportSchedule(model: ExportScheduleModel): Promise<void> {
    const query = new URLSearchParams(model as any);
    const request = `/api/events/${model.eventId}/schedule/export?${query.toString()}`;

    try {
      const response = await this._client.get(request, { responseType: 'blob' });
      const filename = extractFileNameFromHeaders(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      throw new Error('EXPORT ERROR');
    }
  }

  public async getUptime(): Promise<UptimeDto> {
    const response = await this._client.get<UptimeDto>(`/uptime`);
    return response.data;
  }

  public getEventWebsiteUrl(eventId: string): string {
    return `${this._client.defaults.baseURL}/event-redirect/website?eventId=${eventId}`;
  }

  public getEventWebsiteScheduleUrl(eventId: string): string {
    return `${this._client.defaults.baseURL}/event-redirect/website/schedule/?eventId=${eventId}`;
  }

  public getEventWebsiteUrlWithFormEntryId(formEntryId: string): string {
    return `${this._client.defaults.baseURL}/event-redirect/website/form-entry?formEntryId=${formEntryId}`;
  }

  public getEventWebsiteUrlWithPresentationId(eventId: string, presentationId: string): string {
    return `${this._client.defaults.baseURL}/event-redirect/website/presentation?eventId=${eventId}&presentationId=${presentationId}`;
  }

  public getEventWebsiteUrlWithTransactionId(eventId: string, transactionId: string): string {
    return `${this._client.defaults.baseURL}/event-redirect/website/receipt?eventId=${eventId}&transactionId=${transactionId}`;
  }

  public async sendMessage(model: SendMessageModel): Promise<TypeOrError<boolean>> {
    try {
      const typedModel = model.query as GetFormEntriesModel;
      if (typedModel.sessionIds?.length && typedModel.sessionIds.includes('')) {
        // asp.net cannot deserialize empty string to nullable Guid
        typedModel.sessionIds[typedModel.sessionIds.findIndex(x => x === '')] = null!;
      }
      if (typedModel.withSubmissionFilters?.length) {
        // string to boolean translation
        typedModel.withSubmissionFilters = typedModel.withSubmissionFilters.map(f => f === 'true' || f === true);
      }
      await this._client.post(`/api/mailing/send`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async resendEmail(emailId: string, eventId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/v1.0/events/${eventId}/sent-emails/resend/${emailId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createSession(model: CreateUpdateScheduleModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/events/${model.eventId}/sessions`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateSession(model: CreateUpdateScheduleModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.eventId}/sessions/${model.id}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteSession(sessionId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/sessions/${sessionId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createRoom(model: CreateUpdateRoomModel, locale: string): Promise<TypeOrError<RoomDto>> {
    try {
      const result = await this._client.post(`/api/sessions/rooms/${locale}`, model);
      return result.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateRoom(model: CreateUpdateRoomModel, locale: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/sessions/rooms/${model.id}/${locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteRoom(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/sessions/rooms/${id}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createSessionPresentations(
    model: GetFormEntriesModel,
    assignModel: CreateSchedulePresentationModel,
  ): Promise<TypeOrError<boolean>> {
    const request = `/api/session-presentations?${toURLSearchParams(model)}`;
    try {
      await this._client.post(request, assignModel);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateSessionPresentation(id: string, minutes: number): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/session-presentations/${id}`, { id, minutes });
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async moveSessionPresentation(sessionId: string, id: string, index: number): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/sessions/${sessionId}/session-presentations/${id}/${index}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteSessionPresentation(id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/session-presentations/${id}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteSessionPresentationsBySessionId(sessionId: string): Promise<TypeOrError<boolean>> {
    const model = <DeleteSchedulePresentationModel>{
      sessionId,
    };
    const query = new URLSearchParams(model as any);
    const request = `/api/session-presentations?${query.toString()}`;
    try {
      await this._client.delete(request);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getCampaigns(eventId: string): Promise<CampaignDto[]> {
    const response = await this._client.get<CampaignDto[]>(`/v1.0/events/${eventId}/campaigns`);
    return response.data;
  }

  public async getSentEmails(model: GetSentEmailsModel): Promise<PaginatedList<SentEmailDto>> {
    const params = new URLSearchParams(model as any);
    params.delete('eventId');
    const response = await this._client.get<PaginatedList<SentEmailDto>>(
      `/v1.0/events/${model.eventId}/sent-emails?${params.toString()}`,
    );
    return response.data;
  }

  public async getSentEmailDetails(eventId: string, sentEmailId: string): Promise<SentEmailDetailsDto> {
    const endpoint = `/v1.0/events/${eventId}/sent-emails/${sentEmailId}`;
    const response = await this._client.get<SentEmailDetailsDto>(endpoint);
    return response.data;
  }

  public async getPackages(): Promise<PackageDto[]> {
    const response = await this._client.get<PackageDto[]>(`/api/packages`);
    return response.data;
  }

  public async getPackage(id: string): Promise<PackageDto> {
    const response = await this._client.get<PackageDto>(`/api/packages/${id}`);
    return response.data;
  }

  public async getAddOnsWithEventStatus(eventId: string): Promise<AddOnWithEventStatusDto[]> {
    const response = await this._client.get<AddOnWithEventStatusDto[]>(`/v1.0/events/${eventId}/add-ons`);
    return response.data;
  }

  public async getTracks(eventId: string): Promise<TrackDto[]> {
    const response = await this._client.get<TrackDto[]>(`/api/events/${eventId}/tracks`);
    return response.data;
  }

  public async createTrack(model: CreateTrackModel): Promise<TypeOrError<TrackDto>> {
    try {
      const result = await this._client.post(`/api/events/${model.eventId}/tracks/${model.locale}`, model);
      return result.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async updateTrack(model: UpdateTrackModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.put(`/api/events/${model.eventId}/tracks/${model.id}/${model.locale}`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteTrack(eventId: string, id: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/events/${eventId}/tracks/${id}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async createSessionTrack(model: CreateScheduleTrackModel): Promise<TypeOrError<boolean>> {
    try {
      await this._client.post(`/api/sessions/${model.sessionId}/session-tracks`, model);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async deleteSessionTrack(sessionId: string, trackId: string): Promise<TypeOrError<boolean>> {
    try {
      await this._client.delete(`/api/sessions/${sessionId}/session-tracks/${trackId}`);
      return true;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }

  public async getStarted(model: GetStartedModel): Promise<TypeOrError<GetStartedResult>> {
    try {
      const response = await this._client.post(`/api/get-started`, model);
      return response.data;
    } catch (error) {
      return this.apiError(error as AxiosError);
    }
  }
}
