
import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator';
import { Collapsible } from 'fourwaves-shared/components';
import { LocationWithRawQuery } from 'fourwaves-shared';

@Component({
  components: {
    Collapsible,
  },
})
export default class NavItem extends Vue {
  @Prop({ default: null }) readonly to!: LocationWithRawQuery | null;
  @Prop({ type: String, default: null }) readonly icon!: string;
  @Prop({ type: String, default: null }) readonly label!: string;
  @Prop({ type: Number, default: null }) readonly tabindex!: number;
  @Prop(Boolean) readonly isHidden!: boolean;
  @Prop(Boolean) readonly dropdown!: boolean;
  @Prop(Boolean) readonly currentActiveOnly!: boolean;
  @Prop(Boolean) readonly active!: boolean;
  @Prop(Boolean) isOpen!: boolean;

  @Emit() onToggle() {}

  isFocused = false;

  public toggleCollapsible() {
    this.onToggle();
  }

  public onBlur() {
    setTimeout(() => {
      this.isFocused = false;
    }, 200);
  }
}
