const middleware = {}

middleware['administrator'] = require('../src/middleware/administrator.ts')
middleware['administrator'] = middleware['administrator'].default || middleware['administrator']

middleware['chair'] = require('../src/middleware/chair.ts')
middleware['chair'] = middleware['chair'].default || middleware['chair']

middleware['clearEventContext'] = require('../src/middleware/clearEventContext.ts')
middleware['clearEventContext'] = middleware['clearEventContext'].default || middleware['clearEventContext']

middleware['ensureEventContext'] = require('../src/middleware/ensureEventContext.ts')
middleware['ensureEventContext'] = middleware['ensureEventContext'].default || middleware['ensureEventContext']

middleware['formEntryOwner'] = require('../src/middleware/formEntryOwner.ts')
middleware['formEntryOwner'] = middleware['formEntryOwner'].default || middleware['formEntryOwner']

middleware['organizationMember'] = require('../src/middleware/organizationMember.ts')
middleware['organizationMember'] = middleware['organizationMember'].default || middleware['organizationMember']

middleware['organizer'] = require('../src/middleware/organizer.ts')
middleware['organizer'] = middleware['organizer'].default || middleware['organizer']

middleware['reviewer'] = require('../src/middleware/reviewer.ts')
middleware['reviewer'] = middleware['reviewer'].default || middleware['reviewer']

export default middleware
