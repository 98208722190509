import type { FormEntryDto } from './FormEntryDto';
import type { UserDto } from './UserDto';
import { ReviewStatus } from './ReviewStatus';

export interface ReviewDto {
  id: string;
  sequentialId: number;
  status: ReviewStatus;
  title: string;
  user: UserDto | null;
  creationDate: string;
  lastUpdateDate: string;
  formEntry: FormEntryDto | null;
  submissionFormEntryId: string;
  submissionFormEntryUserId: string;
  score?: number;
}

export function getAverageScore(formFieldId: string, reviews: ReviewDto[]): number | null {
  const allScores = reviews
    .filter(({ status }) => status === ReviewStatus.Completed)
    .filter(x => x.formEntry)
    .flatMap(x => x.formEntry!.formEntryValues.filter(fev => fev.formFieldId === formFieldId).flatMap(fev => fev.value))
    .filter(value => value);

  if (allScores.length === 0) return null;
  const percentage = allScores.reduce((total, value) => total + parseInt(value), 0) / allScores.length;
  return Math.round(percentage * 100) / 100;
}

export function getTotalAverageScore(reviews: ReviewDto[]): number | null {
  if (reviews.length === 0 || !reviews.some(({ status }) => status === ReviewStatus.Completed)) {
    return null;
  }

  const validReviews = reviews.filter(
    ({ score, status }) => score !== null && score !== undefined && status === ReviewStatus.Completed,
  );
  if (validReviews.length === 0) return null;

  const percentage = validReviews.reduce((total, value) => total + value.score!, 0) / validReviews.length;
  return Math.round(percentage * 100) / 100;
}

export function formatScore(score: number | null, base = 5): string {
  if (score == null) return '---';
  return `${Math.round(score * 100) / 100} / ${base}`;
}
