
import sanitizeHtml from 'sanitize-html';
import { Component, Vue } from 'nuxt-property-decorator';
import FourwavesLogoFull from 'fourwaves-shared/src/images/fourwaves-light-full.png';
import FourwavesLogoSquare from 'fourwaves-shared/src/images/fourwaves-light-sq.png';
import { RouteNames } from '~/constants';

@Component({})
export default class NavigationlessLayout extends Vue {
  readonly FourwavesLogoFull = FourwavesLogoFull;
  readonly FourwavesLogoSquare = FourwavesLogoSquare;

  backButtonActive = false;

  mounted() {
    if (this.$route && this.$route.query && this.$route.query.tmsg) {
      const message = sanitizeHtml(this.$t(this.$route.query.tmsg as string));
      this.$toast.success(message);

      if (!this.$route.query.redirect) {
        const query = Object.assign({}, this.$route.query);
        delete query.tmsg;
        this.$router.replace({ query });
      }
    }
    if (this.$route && this.$route.name && this.$route.name.startsWith(RouteNames.Unlock + '___')) {
      this.backButtonActive = true;
    }
    this.$moment.locale(this.$i18n.locale);
    this.$nuxt.$on('backToPreviousPage', this.backToPreviousPage);
  }

  beforeDestroy() {
    this.$nuxt.$off('backToPreviousPage');
  }

  public backToPreviousPage() {
    this.$router.push(this.localePath({ name: 'events-dashboard', params: { id: this.$route.params.id } }));
  }
}
