
import { Component, Vue, Prop, Inject } from 'nuxt-property-decorator';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

@Component({
  components: {
    VueGoogleAutocomplete,
  },
})
export default class Geocomplete extends Vue {
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) name!: string;
  @Prop({ required: false }) value!: string;
  @Prop({ required: false }) latitude!: number;
  @Prop({ required: false }) longitude!: number;
  @Prop() validation!: object | string;

  @Inject() $validator;

  isMapShown = false;
  errors: any;
  autocompleteTimeout: any = null;

  get validationErrors() {
    if (this.errors && this.errors.items && this.errors.items.some(e => e.id === this.name)) {
      return this.errors.items.find(x => x.id === this.name).msg;
    }

    return this.errors.first(this.name);
  }

  mounted() {
    // disable autocomplete
    this.autocompleteTimeout = setTimeout(() => {
      (this.$refs.address as any).$el.setAttribute('autocomplete', 'disabled');
    }, 250);

    if (this.latitude && this.longitude) {
      this.showMap(this.latitude, this.longitude);
    }
  }

  beforeDestroy() {
    clearTimeout(this.autocompleteTimeout);
  }

  public onChange(autocompleteText: string) {
    if (autocompleteText === '') {
      this.$emit('input', autocompleteText);
    }
  }

  public getGeolocatedAddress(data: any) {
    this.showMap(data.latitude, data.longitude);
    this.$emit('addressChanged', data);
    this.$nextTick(() => {
      this.$emit('input', (this.$refs.address as any).autocompleteText);
    });
  }

  public showMap(latitude: number, longitude: number) {
    const latLngObj = new google.maps.LatLng(latitude, longitude);
    const map = new google.maps.Map(document.getElementById('map') as Element, {
      center: latLngObj,
      zoom: 14,
    });
    new google.maps.Marker({
      position: latLngObj,
    }).setMap(map);
    this.isMapShown = true;
  }
}
