
import sanitizeHtml from 'sanitize-html';
import { Component, Watch, namespace, mixins } from 'nuxt-property-decorator';
import { Role } from 'fourwaves-shared';
import { BeforeUnloadModal } from 'fourwaves-shared/components';
import Navigation from '~/components/navigation/Navigation.vue';
import SideNav from '~/components/navigation/SideNav.vue';
import { RouteContextMixin } from '~/mixins';

const EventsModule = namespace('events');
const SettingsModule = namespace('settings');

const floatingSideNavCookieName = 'opnav_floating';

@Component({
  components: {
    Navigation,
    SideNav,
    BeforeUnloadModal,
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
})
export default class DefaultLayout extends mixins(RouteContextMixin) {
  @EventsModule.State timeZone!: string;
  @SettingsModule.Getter hasOrganizationRole!: (organizationId: string, role: Role) => boolean;

  isSideNavFloating = false;

  get isAdministratorInOrganization() {
    return this.isInOrganization && this.hasOrganizationRole(this.$route.params.id, Role.Administrator);
  }

  @Watch('timeZone')
  onTimeZoneChanged() {
    this.$moment.tz.setDefault(this.timeZone);
  }

  created() {
    this.$moment.locale(this.$i18n.locale);
    this.onTimeZoneChanged();

    this.isSideNavFloating = !!this.$cookies.get(floatingSideNavCookieName);
  }

  mounted() {
    if (this.$route && this.$route.query && this.$route.query.tmsg) {
      const message = sanitizeHtml(this.$t(this.$route.query.tmsg as string));
      this.$toast.success(message);

      if (!this.$route.query.redirect) {
        const query = Object.assign({}, this.$route.query);
        delete query.tmsg;
        this.$router.replace({ query });
      }
    }
  }

  toggleSideNavMode() {
    this.isSideNavFloating = !this.isSideNavFloating;
    this.$cookies.set(floatingSideNavCookieName, this.isSideNavFloating, {
      path: '/',
      expires: this.$moment().add(1, 'year').toDate(),
    });
  }
}
