import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class PreventPageScrollMixin extends Vue {
  savedScrollPosition = 0;

  public preventPageScroll() {
    const viewport = document.getElementById('__layout');
    const body = document.body;
    if (!viewport) return;
    const scrollingElement = document.scrollingElement || document.documentElement;
    this.savedScrollPosition = scrollingElement.scrollTop;
    body.classList.add('-hide-intercom-btn');
    viewport.style.width = `${viewport.clientWidth}px`;
    viewport.style.top = `-${this.savedScrollPosition}px`;
    viewport.style.position = 'fixed';
  }

  public restorePageScroll() {
    const viewport = document.getElementById('__layout');
    const body = document.body;
    if (!viewport) return;
    const scrollingElement = document.scrollingElement || document.documentElement;
    body.classList.remove('-hide-intercom-btn');
    viewport.style.position = '';
    viewport.style.width = '';
    viewport.style.top = '';
    scrollingElement.scrollTop = this.savedScrollPosition;
  }
}
