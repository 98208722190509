export interface AssignAbstractModel {
  nbReviewsPerAbstract: number;
  reviewerIds: string[];
  method: 'random' | 'manual';
  validate: boolean;
  notifyByEmail: boolean;
}

export enum AssignReviewerMethod {
  Random = 'random',
  Manual = 'manual',
}

export interface AssignAbstractResult {
  reviewCount: number;
  reviewerCount: number;
}
