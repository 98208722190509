
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FormFieldDto, FormDto, FormEntryDto, getFormEntryValue } from 'fourwaves-shared';

@Component({})
export default class SlidesField extends Vue {
  @Prop({ required: true }) field!: FormFieldDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) formEntry!: FormEntryDto;

  get posterUrl() {
    return this.$api.getFileUploadUrl(getFormEntryValue(this.formEntry, this.form, this.field.id));
  }
}
