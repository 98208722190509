
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FormFieldDto, FormDto, FormEntryDto } from 'fourwaves-shared';

@Component({})
export default class ImagesField extends Vue {
  @Prop({ required: true }) field!: FormFieldDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) formEntry!: FormEntryDto;

  get parsedFileUploads(): string[] {
    return this.formEntry.formEntryValues.filter(x => x.formFieldId === this.field.id).map(x => JSON.parse(x.value));
  }
}
