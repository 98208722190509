
import { Component, mixins } from 'nuxt-property-decorator';
import { isValidGuid } from 'fourwaves-shared';
import NavItem from '../nav-items/NavItem.vue';
import FinancesSideNavItems from '../nav-items/FinancesSideNavItems.vue';
import { OrganizationNavItem } from '../types';
import NavPanelMixin from '../NavPanelMixin';

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: {
    NavItem,
    FinancesSideNavItems,
  },
})
export default class OrganizationSideNavPanel extends mixins(NavPanelMixin) {
  readonly OrganizationNavItem = OrganizationNavItem;
}
