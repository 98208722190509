export default {
  5392: {
    styles: `
      @media print {
        @page {
          size: letter;
          margin: 25.4mm 0 0 6.5mm;
        }
      }
    `,
    itemPerPage: 6,
    width: 359,
  },
  5390: {
    styles: `
      @media print {
        @page {
          size: letter;   
          margin-top: 26.75mm;
          margin-left: 0.75in;
        }
      }
    `,
    itemPerPage: 8,
    width: 311,
  },
  5395: {
    styles: `
      @media print {
        @page {
          size: letter; 
          margin: 14.25mm 0 0 17.25mm;
        }
      }
    `,
    itemPerPage: 8,
    width: 300,
  },
};
