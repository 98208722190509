
import { PageDto, PageType, isSuccess, Dictionary, isValidationError } from 'fourwaves-shared';
import { InputText } from 'fourwaves-shared/components';
import { Component, Prop, Vue, namespace, Emit, Inject } from 'nuxt-property-decorator';

const EventsModule = namespace('events');

@Component({
  components: { InputText },
})
export default class EditMenuItem extends Vue {
  @Inject() $validator;

  @EventsModule.State eventId!: string;
  @EventsModule.Getter isVotingEnabled!: boolean;

  @Prop({ required: true }) page!: PageDto;
  @Prop() parent!: PageDto;
  @Prop({ required: true }) index!: number;

  @Emit() openDeleteModal(page: PageDto) {
    return page;
  }

  @Emit() onPageCancel() {
    return { index: this.index, parentId: this.parent ? this.parent.id : null };
  }

  @Emit() onPageCreated(pageId: string) {
    return pageId;
  }

  @Emit() onPageUpdated() {
    return this.index;
  }

  randomNumber = Math.floor(Math.random() * 100000);

  readonly PageType = PageType;
  pageName = '';
  oldName = '';
  isEditing = false;

  iconNameMap: Dictionary<string> = {
    home: 'home',
    registration: 'registration',
    submission: 'submission',
    participants: 'user',
    abstracts: 'slides',
    schedule: 'calendar',
    content: 'page',
  };

  created() {
    if (!this.page.id) this.isEditing = true;
    this.oldName = this.page.name[this.$i18n.locale];
  }

  public onCancelClick() {
    if (!this.page.id) this.onPageCancel();
    this.isEditing = false;
    this.page.name[this.$i18n.locale] = this.oldName;
  }

  public async savePage() {
    if (!(await this.$validator.validateAll())) return;

    if (!this.page.id) {
      await this.createPage();
    } else {
      await this.updatePage();
    }
  }

  public async createPage() {
    const result = await this.$api.createPage(
      {
        eventId: this.eventId,
        name: this.page.name[this.$i18n.locale],
        order: this.index + 1,
        isFolder: this.page.type === PageType.Folder,
        parentId: this.parent ? this.parent.id : null,
      },
      this.$i18n.locale,
    );

    if (isSuccess(result)) {
      this.$toast.success(this.$t('global.success_message'));
      this.onPageCreated(result.id);
    } else if (isValidationError(result)) {
      this.feedErrorBag(result);
    }
  }

  public async updatePage() {
    const result = await this.$api.updatePage(
      {
        id: this.page.id,
        name: this.page.name[this.$i18n.locale],
        isVisible: this.page.isVisible,
        scope: this.page.scope,
        submissionScope: this.page.submissionScope,
        formFields: this.page.formFields.map(f => f.id),
        isVotingEnabled: this.isVotingEnabled,
      },
      this.$i18n.locale,
    );

    if (isSuccess(result)) {
      this.$toast.success(this.$t('global.success_message'));
      this.isEditing = false;
      this.onPageUpdated();
    } else if (isValidationError(result)) {
      this.feedErrorBag(result);
    }
  }
}
