import { Middleware } from '@nuxt/types';

const ensureEventContext: Middleware = (context): Promise<any> => {
  // load the event when none
  if (!context.store.state.events.eventId) {
    return context.store.dispatch('events/setEventContext', context.route.params.id);
  }

  // load the event when store ID != route ID
  if (context.store.state.events.eventId !== context.route.params.id) {
    return context.store.dispatch('events/setEventContext', context.route.params.id);
  }

  return Promise.resolve();
};

export default ensureEventContext;
