import { TranslateResult } from 'fourwaves-shared/src/types/i18n';
import { Location } from 'vue-router';

export interface RouteMapItem {
  visibility: boolean;
  to?: Location | null;
  label: TranslateResult | string;
  icon?: string;
  dataTestId?: string;
  currentActiveOnly?: boolean;
  subItems?: RouteMapItem[];
}

export enum OrganizerNavItem {
  Configuration = 'configuration',
  Website = 'website',
  Data = 'data',
}

export enum OrganizationNavItem {
  Finances = 'finances',
}
