export const redirect = {
  login: '/login',
  logout: '/login',
  callback: '/login',
  home: '/',
};

export default ctx => {
  ctx.app.$auth.onRedirect((to: string) => {
    if (to === redirect.login) {
      return ctx.app.localePath('login');
    }

    if (to === redirect.logout) {
      return ctx.app.localePath('index');
    }

    if (to === redirect.home) {
      // extract redirect url from query
      let redirectUrl = '';
      if (ctx.query.redirect) redirectUrl = String(ctx.query.redirect);
      if (ctx.query.returnTo) redirectUrl = String(ctx.query.returnTo);

      // if no redirect url, return to home
      if (!redirectUrl) return ctx.app.localePath('index');

      // if redirect url is absolute, redirect to it. bypass https://github.com/nuxt/nuxt.js/issues/770#issuecomment-372363558
      if (redirectUrl.startsWith('http')) {
        window.location.href = redirectUrl;
        return undefined;
      }

      // it is important to pass query and params in order to make /index.vue redirects work
      // this code path is specifically for the case where the user is not logged in and coming from an email link
      return ctx.app.localePath({ name: 'index', query: ctx.query, params: ctx.params });
    }

    if (ctx.query.redirect) {
      delete ctx.query.redirect;
    }

    if (ctx.query.returnTo) {
      delete ctx.query.returnTo;
    }

    return to;
  });
};
