import { BasePaginatedModel } from './BasePaginatedModel';

export interface GetTransactionsModel extends BasePaginatedModel {
  eventId?: string;
  organizationId?: string;
  searchTerm?: string;
  formEntryId?: string;
  from?: string | null;
  to?: string | null;
  subsetIds?: string[];
  excludedIds?: string[];
}

export function toTransactionsSearchParams(model: GetTransactionsModel): URLSearchParams {
  const query = new URLSearchParams(model as any);
  // URLSearchParams do not handles array, ex: foo=bar1&foo=bar2
  if (model.subsetIds && model.subsetIds.length > 0) {
    query.delete('subsetIds');
    model.subsetIds.forEach(x => query.append('subsetIds', x));
  }
  if (model.excludedIds && model.excludedIds.length > 0) {
    query.delete('excludedIds');
    model.excludedIds.forEach(x => query.append('excludedIds', x));
  }
  return query;
}
