export enum ExportScheduleFileType {
  Pdf = 'Pdf',
  Word = 'Word',
}

export interface ExportScheduleModel {
  eventId: string;
  isDetailed?: boolean;
  fileType: ExportScheduleFileType;
  redirectUrl?: string;
}
