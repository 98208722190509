
import { Component, namespace, Vue } from 'nuxt-property-decorator';
import { Badge } from 'fourwaves-shared/components';
import { TranslatableField } from 'fourwaves-shared';
import MobileNav from '../MobileNav.vue';

const EventsModule = namespace('events');
const SettingsModule = namespace('settings');

export enum EventStatus {
  Active = 'active',
  Draft = 'draft',
  Past = 'past',
}

@Component({ components: { Badge, MobileNav } })
export default class OrganizerNavPanel extends Vue {
  @EventsModule.State eventName!: TranslatableField;
  @EventsModule.State eventId!: string;
  @EventsModule.State publishedAt!: string;
  @EventsModule.State startDate!: string;
  @EventsModule.State endDate!: string;
  @SettingsModule.Getter isOrganizer!: boolean;

  get previousPageRoute() {
    const previousRoute = this.$nuxt.context.from;
    const routePrefixes = ['user-dashboard-organizer___', 'organizations-id-events___'];

    return routePrefixes.some(prefix => previousRoute.name?.startsWith(prefix))
      ? { ...this.$nuxt.context.from, name: previousRoute.name?.slice(0, -5) }
      : { name: 'user-dashboard-organizer' };
  }

  public getEventWebsiteUrl() {
    return this.$api.getEventWebsiteUrl(this.eventId);
  }

  public getEventStatus() {
    const userNow = this.$moment();
    const endMoment = this.$moment(this.endDate);

    if (!this.publishedAt) return EventStatus.Draft;
    if (userNow.isSameOrBefore(endMoment)) return EventStatus.Active;
    if (userNow.isAfter(endMoment)) return EventStatus.Past;

    return '';
  }

  get getTimeStatus() {
    const eventStatus = this.getEventStatus();

    if (eventStatus === EventStatus.Active) {
      const userNow = this.$moment();
      const startMoment = this.$moment(this.startDate);

      if (userNow.isAfter(startMoment.startOf('day'))) return this.$t('events.index.time_status.live');

      const monthsUntilStart = startMoment.diff(userNow, 'months');

      return monthsUntilStart > 0
        ? this.$tc('events.index.time_status.upcoming_months', monthsUntilStart)
        : this.$tc('events.index.time_status.upcoming_days', startMoment.diff(userNow, 'days') + 1);
    }

    return eventStatus === EventStatus.Draft
      ? this.$t('events.index.time_status.draft')
      : this.$t('events.index.time_status.past');
  }

  get getTimeStatusBadgeModifier() {
    const eventStatus = this.getEventStatus();

    if (eventStatus === EventStatus.Draft) return 'gray';
    if (eventStatus === EventStatus.Past) return 'primary';

    const userNow = this.$moment();
    const startMoment = this.$moment(this.startDate);

    return userNow.isAfter(startMoment.startOf('day')) ? 'success' : 'warning';
  }
}
