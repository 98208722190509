
import { Component, Vue, Ref, Watch, namespace } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { GlobalFilterEvents, isValidGuid, OrganizationDto } from 'fourwaves-shared';
import { Collapsible } from 'fourwaves-shared/components';
import OrganizationCreateModalWrapper from '~/components/organization/OrganizationCreateModalWrapper.vue';

const OrganizationsModule = namespace('organizations');

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: {
    Collapsible,
    OrganizationCreateModalWrapper,
  },
})
export default class OrganizationDropdown extends Vue {
  @OrganizationsModule.State organizations!: OrganizationDto[];

  @Ref() readonly optionsMenuContainer?: HTMLMenuElement;
  @Ref() readonly selectButton?: HTMLElement;
  @Ref() readonly createOrgModal?: OrganizationCreateModalWrapper;

  isOpen = false;
  GlobalFilterEvents = GlobalFilterEvents;
  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;

  @Watch('$route')
  onRouteChanged() {
    this.isOpen = false;
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;

    if (isOpen) {
      document.body.addEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$on(GlobalFilterEvents.SelectOpen, this.closeTray);
    } else {
      document.body.removeEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
    }
  }

  get currentOrganizationName() {
    const organization = this.organizations.find(organization => this.isOrganizationActive(organization));
    return organization ? this.transl8(organization.name) : '';
  }

  async mounted() {
    await this.$nextTick();
    if (!this.optionsMenuContainer) return;
    this.simplebarInstance = new SimpleBar(this.optionsMenuContainer);
    await this.$nextTick();
    this.optionsMenuContainer?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
    this.scrollElement = this.simplebarInstance.getScrollElement();
  }

  beforeDestroy() {
    document.body.removeEventListener('click', () => {
      this.closeTray();
    });
    this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
  }

  public setOrgCookie(organizationId: string) {
    this.$cookies.set(`odorg_${this.$auth.user.id}`, organizationId, { path: '/' });
  }

  public isOrganizationActive(organization: OrganizationDto) {
    return this.$route.params.id === organization.id;
  }

  public onButtonClick() {
    if (!this.isOpen) this.$nuxt.$emit(GlobalFilterEvents.SelectOpen);
    this.isOpen = !this.isOpen;
  }

  public tabTrapHandle(index: number): string | null {
    return index === 0 ? 'first' : index === this.$i18n.locales.length - 1 ? 'last' : null;
  }

  public closeTray(focusButton = false) {
    this.isOpen = false;
    if (focusButton) this.selectButton?.focus();
  }

  public onTrayClose() {
    if (!this.scrollElement) return;
    this.scrollElement.scrollTop = 0;
  }

  public openCreateOrganizationModal() {
    this.createOrgModal?.openModal();
  }
}
