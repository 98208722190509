export default {
  schedule: {
    configuration: {
      title: 'Schedule',
      schedule: 'Schedule',
      settings: 'Settings',
      options: 'Options',
    },
    by_pres: {
      title: 'Schedule',
      edit_schedule: 'Edit Schedule',
      view_schedule: 'View Schedule',
      empty_state: 'This submission has not been added to any session.',
      empty_state_user: 'No session information available.',
    },
  },
};
