
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FormFieldDto, FormDto, FormEntryDto, getFormEntryValue } from 'fourwaves-shared';

@Component({})
export default class VideoField extends Vue {
  @Prop({ required: true }) field!: FormFieldDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) formEntry!: FormEntryDto;

  get videoUrl() {
    const fileUploadId = getFormEntryValue(this.formEntry, this.form, this.field.id);
    return fileUploadId ? this.$api.getFileUploadUrl(fileUploadId) : '';
  }
}
