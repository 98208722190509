
import { Component, mixins, Watch } from 'nuxt-property-decorator';
import { BurgerBtn } from 'fourwaves-shared/components';
import OrganizerMobilePanel from './mobile-panel/OrganizerMobilePanel.vue';
import OrganizationMobilePanel from './mobile-panel/OrganizationMobilePanel.vue';
import { PreventPageScrollMixin, RouteContextMixin } from '~/mixins';

@Component({
  components: {
    BurgerBtn,
    OrganizerMobilePanel,
    OrganizationMobilePanel,
  },
})
export default class MobileNav extends mixins(PreventPageScrollMixin, RouteContextMixin) {
  isActive = false;

  @Watch('$route')
  onRouteChange() {
    this.isActive = false;
  }

  @Watch('isActive')
  onIsActiveChange() {
    if (this.isActive) {
      this.preventPageScroll();
    } else {
      this.restorePageScroll();
    }
  }

  beforeDestroy() {
    this.restorePageScroll();
  }
}
