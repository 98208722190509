export default {
  user_dashboard: {
    participant: {
      title: 'Participant Dashboard',
      dropdown: 'Participant',
      menus: {
        events: 'Events',
        submissions: 'Submissions',
        registrations: 'Registrations',
        transactions: 'Transactions',
      },
    },
    organizer: {
      title: 'Organizer Dashboard',
      dropdown: 'Organizer',
    },
    reviewer: {
      title: 'Reviewer Dashboard',
      dropdown: 'Reviewer',
    },
    event: {
      tabs: {
        active: 'Active & Upcoming',
        draft: 'Drafts',
        past: 'Past',
        all: 'All',
      },
      table_columns: {
        name: 'Event Name',
        organization: 'Organization',
        no_organization: 'Missing organization',
        location: 'Location',
        date: 'Date',
        format: 'Format',
        status: 'Status',
      },
    },
  },
};
