
import { Component, Prop, mixins, Ref } from 'nuxt-property-decorator';
import { ProgressStep } from 'fourwaves-shared';
import { FullPageModal } from 'fourwaves-shared/components';
import { PreventPageScrollMixin } from '~/mixins';

@Component({
  components: {
    FullPageModal,
  },
})
export default class FullscreenModal extends mixins(PreventPageScrollMixin) {
  @Prop({ type: String, required: true }) readonly title!: string;
  @Prop({ type: String, default: '' }) readonly subtitle!: string;
  @Prop({ type: String, default: '' }) readonly titleCaption!: string;
  @Prop({ type: String, default: '' }) readonly subtitleCaption!: string;
  @Prop({ type: String, default: 'sm' }) readonly size!: string;
  @Prop({ type: Array, default: Array }) readonly steps!: string[];
  @Prop({ type: Number, default: 0 }) readonly stepIndex!: number;
  @Prop({ type: String, default: null }) readonly awaitMessage!: string;
  @Prop({ type: Array, default: null }) readonly progressSteps!: ProgressStep[] | null;
  @Prop(Boolean) readonly secondLastSubmit!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly nextDisabled!: boolean;
  @Prop(Boolean) readonly hideFooter!: boolean;
  @Prop(Boolean) readonly noGrow!: boolean;
  @Prop(Boolean) readonly isWaiting!: boolean;
  @Prop({ type: String, default: '' }) readonly disabledMessage!: string;
  @Prop({ type: String, default: null }) readonly actionButtonLabel!: string | null;

  @Ref() readonly fpModal?: FullPageModal;

  public triggerVisibility() {
    this.fpModal?.triggerVisibility();
  }

  public onOpen() {
    this.preventPageScroll();
  }

  public onClose() {
    this.restorePageScroll();
  }

  public closeModal() {
    this.fpModal?.closeModal();
  }

  beforeDestroy() {
    this.restorePageScroll();
  }
}
