export default {
  registration: {
    title: 'Registration',
    user_dashboard: {
      empty_state: {
        title: 'No registrations',
        subtitle: 'You have not registered to any events yet.',
      },
    },
    menus: {
      organizer_panel: 'Registrations',
    },
    back_to_list: 'Back to registrations',
    edit: 'Edit Registration',
    registered: 'Registered',
    registered_waitlist: 'Registered (waitlist)',
    submissions: {
      title: 'Submissions',
      card: {
        view: 'View Submission',
        empty_participant: 'You have not sent any submission.',
        empty_organizer: 'No submissions have been sent by this participant.',
      },
    },
    configuration: {
      title: 'Registrations',
      form: 'Form',
      settings: 'Settings',
      email: 'Confirmation Email',
      options: 'Options',
      max_registrants: 'Set maximum number of participants',
      max_registrants_placeholder: 'Enter a number',
      waitlist_active: 'Allow to register on waitlist when limit is reached',
      registration_period: 'Registration period',
    },
    data_table: {
      empty_title: 'No registrations',
      empty_subtitle: 'There are no registrations to this event yet.',
    },
  },
};
