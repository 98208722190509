export enum PackageTier {
  Free = 'free',
  Essentials = 'essentials',
  Advanced = 'advanced',
  Professional = 'professional',
  Custom = 'custom',
}

export interface PackageDto {
  id: string;
  tier: PackageTier;
  version: string;
  registrationCapacity: number;
  submissionCapacity: number;
}

export interface PackageFeature {
  featureFlag: string | null;
  label: string;
}

export interface PackageOption {
  id: string;
  tier: PackageTier;
  label: string;
  cost: number;
  fee: number;
  featureList: PackageFeature[];
  disabled: boolean;
  current: boolean;
}
