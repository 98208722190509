import Vue from 'vue'
import VeeValidate from 'vee-validate'

Vue.use(VeeValidate, {
  "events": "change",
  "inject": false,
  "fieldsBagName": "fieldsBag"
})

export default ({ app }, inject) => {
  app.validator = VeeValidate.Validator
}
