import { MutationTree, ActionTree } from 'vuex';
import {
  EventDto,
  FeatureDto,
  FormType,
  getEventStatus,
  getWildcardDomain,
  PageDto,
  ScheduleDto,
  TrackDto,
} from 'fourwaves-shared';
import { RootState, EventsState } from '~/types';

const EVENT_ID_COOKIE_NAME = 'eventId';

export const state = (): EventsState => ({
  eventId: '',
  organizationId: '',
  eventName: { fr: '', en: '' },
  startDate: '',
  endDate: '',
  language: 'browser',
  registrationFormId: '',
  submissionFormId: '',
  reviewFormId: '',
  timeZone: '',
  unlockedAt: null,
  publishedAt: null,
  status: '',
  urlSegment: '',
  forms: [],
  pages: [],
  schedules: [],
  tracks: [],
  contactEmail: '',
  stripePublishableKey: undefined,
  paysafePublishableKey: undefined,
  touchNetUPaySiteId: undefined,
  globalPaymentsAppId: undefined,
  monerisTokenizationId: undefined,
  currency: 'CAD',
  gstBusinessNumber: '',
  qstBusinessNumber: '',
  vatBusinessNumber: '',
  pstBusinessNumber: '',
  receiptHeader: { fr: '', en: '' },
  features: [],
  withInvoicingPayments: false,
  invoicingPaymentInstructions: { fr: '', en: '' },
  logo: null,
});

export const getters = {
  isEventMultiLanguage: (state: EventsState): boolean => {
    return state.language === 'browser';
  },
  withVideoRecordings: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_video_recordings');
    return feature ? feature.isEnabled : false;
  },
  isReviewingFeatureEnabled: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'reviewing');
    return feature ? feature.isEnabled : false;
  },
  isVotingEnabled: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'voting_enabled');
    return feature ? feature.isEnabled : false;
  },
  isRegistrationCapacityLimited: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'reg_capacity_limited');
    return feature ? feature.isEnabled : false;
  },
  withUnlimitedSubmissions: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_unlimited_submissions');
    return feature ? feature.isEnabled : false;
  },
  withContentPages: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_content_pages');
    return feature ? feature.isEnabled : false;
  },
  withLiveApp: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_live_app');
    return feature ? feature.isEnabled : false;
  },
  withEnhancedContentBlocks: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_enhanced_content_blocks');
    return feature ? feature.isEnabled : false;
  },
  withMassEmails: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_mass_emails');
    return feature ? feature.isEnabled : false;
  },
  withBadges: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_badges');
    return feature ? feature.isEnabled : false;
  },
  withAbstractBooklet: (state: EventsState): boolean => {
    const feature = state.features.find(x => x.name === 'with_abstract_booklet');
    return feature ? feature.isEnabled : false;
  },
};

export const mutations: MutationTree<EventsState> = {
  setLanguage(state: EventsState, language: string): void {
    state.language = language;
  },
  setTimeZone(state: EventsState, timeZone: string): void {
    state.timeZone = timeZone;
  },
  setURLSegment(state: EventsState, urlSegment: string): void {
    state.urlSegment = urlSegment;
  },
  setEvent(state: EventsState, event: EventDto | null): void {
    state.eventId = event ? event.id : '';
    if (state.eventId) {
      this.$cookies.set(EVENT_ID_COOKIE_NAME, state.eventId, {
        domain: getWildcardDomain(this.$config.API_URL),
        path: '/',
      });
    }
    state.organizationId = event && event.organizationId ? event.organizationId : '';
    state.eventName = event ? event.name : { fr: '', en: '' };
    state.startDate = event ? event.startDate : '';
    state.endDate = event ? event.endDate : '';
    state.registrationFormId = event && event.forms ? event.forms.find(x => x.type === FormType.Registration)!.id : '';
    state.submissionFormId = event && event.forms ? event.forms.find(x => x.type === FormType.Submission)!.id : '';
    state.reviewFormId =
      event && event.forms && event.forms.find(x => x.type === FormType.Review)
        ? event.forms.find(x => x.type === FormType.Review)!.id
        : '';
    state.forms = event ? event.forms : [];
    this.commit('events/setTimeZone', event ? event.timeZone : '');
    this.commit('events/setLanguage', event ? event.language : '');
    this.commit('events/setURLSegment', event ? event.urlSegment : '');
    state.unlockedAt = event ? event.unlockedAt : null;
    state.publishedAt = event ? event.publishedAt : null;
    state.status = event ? getEventStatus(event) : '';
    state.contactEmail = event ? event.contactEmail : '';
    state.stripePublishableKey = event ? event.stripePublishableKey : undefined;
    state.paysafePublishableKey = event ? event.paysafePublishableKey : undefined;
    state.touchNetUPaySiteId = event ? event.touchNetUPaySiteId : undefined;
    state.globalPaymentsAppId = event ? event.globalPaymentsAppId : undefined;
    state.monerisTokenizationId = event ? event.monerisTokenizationId : undefined;
    state.currency = event ? event.currency : 'CAD';
    state.receiptHeader = event ? event.receiptHeader : { fr: '', en: '' };
    state.gstBusinessNumber = event ? event.gstBusinessNumber : '';
    state.qstBusinessNumber = event ? event.qstBusinessNumber : '';
    state.vatBusinessNumber = event ? event.vatBusinessNumber : '';
    state.pstBusinessNumber = event ? event.pstBusinessNumber : '';
    state.withInvoicingPayments = event ? event.withInvoicingPayments : false;
    state.invoicingPaymentInstructions = event ? event.invoicingPaymentInstructions : { fr: '', en: '' };
    state.logo = event ? event.logo : null;
  },
  setPages(state: EventsState, pages: PageDto[] | null): void {
    state.pages = pages || [];
  },
  setSessions(state: EventsState, sessions: ScheduleDto[] | null): void {
    state.schedules = sessions || [];
  },
  setFeatures(state: EventsState, features: FeatureDto[]): void {
    state.features = features || [];
  },
  setTracks(state: EventsState, tracks: TrackDto[]): void {
    state.tracks = tracks || [];
  },
};

export const actions: ActionTree<EventsState, RootState> = {
  async init(_, eventId) {
    if (eventId) await this.dispatch('events/setEventContext', eventId);
  },
  async setEventContext({ commit }, eventId: string): Promise<boolean> {
    this.$api._client.defaults.baseURL = this.$config.API_URL;
    this.$api._client.defaults.headers.common.Authorization = this.$cookies.get('auth._token.local');

    const eventDto = await this.$api.getEvent(eventId);
    if (eventDto) {
      commit('setEvent', eventDto);
      const [pages, sessions, tracks, features] = await Promise.all([
        this.$api.getPages(eventId, true),
        this.$api.getSessions(eventId),
        this.$api.getTracks(eventId),
        this.$api.getFeatures(eventId),
      ]);
      commit('setPages', pages);
      commit('setSessions', sessions);
      commit('setTracks', tracks);
      commit('setFeatures', features);
      return true;
    } else {
      // This event does not exist anymore OR user permission has changed.
      commit('setEvent', null);
      commit('setPages', null);
      commit('setFeatures', null);
      commit('setSessions', null);
      commit('setTracks', null);
      return false;
    }
  },
  async refreshContext({ state }): Promise<boolean> {
    return await this.dispatch('events/setEventContext', state.eventId);
  },
  clearEventContext({ commit }): void {
    commit('setEvent', null);
  },
};
