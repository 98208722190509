import { AuthorDto } from './FormEntryDto';

export interface SchedulePresentationDto {
  id: string;
  sessionId: string;
  formEntryId: string;
  sequentialId: string | number;
  title: string;
  presenterAuthors: AuthorDto[];
  startDate?: string | null;
  endDate?: string | null;
  isRestricted: boolean;
}

export function getPresenter(presentation: SchedulePresentationDto): string {
  // empty field
  if (!presentation.presenterAuthors) return '';

  // extract the presenter
  const authors = presentation.presenterAuthors
    .filter(author => author.isPresenter)
    .map(author => `${author.firstName} ${author.lastName}`);

  return authors.join(', ');
}
