
import { Component, namespace, Ref, Prop, Emit, mixins } from 'nuxt-property-decorator';
import { buildNestedPages, NestedPage, PageDto, PageType } from 'fourwaves-shared';
import { OrganizerNavItem } from '../types';
import NavPanelMixin from '../NavPanelMixin';
import NavItem from './NavItem.vue';
import EditMenuModal from '~/components/page-builder/EditMenuModal.vue';

const EventsModule = namespace('events');
const SettingsModule = namespace('settings');

@Component({
  components: {
    NavItem,
    EditMenuModal,
  },
})
export default class WebsiteSideNavItems extends mixins(NavPanelMixin) {
  @Prop(Boolean) readonly isOpen!: boolean;
  @EventsModule.State pages!: PageDto[];
  @SettingsModule.Getter isOrganizer!: boolean;

  @Ref() readonly orderingModal?: EditMenuModal;

  @Emit()
  onToggle() {
    return OrganizerNavItem.Website;
  }

  readonly PageType = PageType;

  get nestedPages() {
    const nestedPages = buildNestedPages(this.pages);
    return this.isOrganizer ? nestedPages : nestedPages.filter(({ type }) => type === PageType.Schedule);
  }

  get isParentActive() {
    const allPages = this.nestedPages.flatMap(page => [page, ...page.children]);
    return allPages.some(page => this.$route.path.startsWith(this.buildPageLink(page)));
  }

  public isGroupActive(group: NestedPage) {
    return group.children.some(page => this.$route.path.startsWith(this.buildPageLink(page)));
  }

  public openOrderingModal() {
    this.orderingModal?.triggerVisibility();
  }

  created() {
    if (this.isParentActive) this.onToggle();
  }

  mounted() {
    this.nestedPages.forEach(page => {
      if (page.type === PageType.Folder && this.isGroupActive(page)) {
        this.openNavItemKey = page.id;
      }
    });
  }

  public onPageGroupToggle(id: string) {
    this.openNavItemKey = id === this.openNavItemKey ? null : id;
  }
}
