export default function ({ $auth, store }) {
  if ($auth.loggedIn) {
    return store.dispatch('organizations/init');
  }

  $auth.$storage.watchState('loggedIn', (loggedIn: boolean) => {
    if (loggedIn) {
      store.dispatch('organizations/init');
    }
  });
}
