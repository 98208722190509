
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { FormDto, FormEntryDto, FormFieldDto, Dictionary, FormType } from 'fourwaves-shared';
import { RouteNames } from '~/constants';

@Component({})
export default class FormSummary extends Vue {
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) formEntry!: FormEntryDto;
  @Prop({ required: false }) formData!: Dictionary<string | string[]>;
  @Prop(Boolean) isTable!: boolean;

  get isReviewPage(): boolean {
    return this.$route.name?.includes(RouteNames.ReviewId) || false;
  }

  get allFields(): FormFieldDto[] {
    if (this.formEntry == null || this.form == null) {
      return [];
    }

    return this.form.formSections.flatMap(x => x.formFields);
  }

  get filteredBlockSections() {
    return this.form.formSections.filter(x => this.getFieldsToShow(x.formFields).length > 0);
  }

  get visibleFields(): FormFieldDto[] {
    if (!this.form?.formSections) return [];
    return this.form.formSections.flatMap(section => section.formFields).filter(field => field.isVisibleToReviewers);
  }

  public getFieldsToShow(fields: FormFieldDto[]): FormFieldDto[] {
    if (this.formEntry == null || this.form == null) {
      return [];
    }

    if (this.isReviewPage) {
      fields = fields.filter(field => this.visibleFields.some(visibleField => visibleField.id === field.id));
    }

    if (this.form.type === FormType.Submission) {
      return fields.filter(field => this.isValidSubmissionField(field, this.formEntry, this.form));
    } else {
      return fields.filter(field =>
        this.formEntry.formEntryValues.some(formEntryValue => formEntryValue.formFieldId === field.id),
      );
    }
  }
}
