
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { uniq } from 'lodash-es';
import { FormFieldDto, FormDto, FormEntryDto } from 'fourwaves-shared';

@Component({})
export default class AuthorsField extends Vue {
  @Prop({ required: true }) field!: FormFieldDto;
  @Prop({ required: true }) form!: FormDto;
  @Prop({ required: true }) formEntry!: FormEntryDto;

  get affiliations() {
    return this.formEntry.authors ? uniq(this.formEntry.authors.flatMap(x => x.affiliations)) : [];
  }

  public getAffiliationIndex(affiliation: string): number {
    return this.affiliations.indexOf(affiliation) + 1;
  }
}
