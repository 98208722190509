export default {
  create_org: {
    alert: {
      title: 'Some important changes are to be expected in the coming weeks.',
      text: 'Start by creating your organization, and link your existing events to it. Please note that eventually, all orphan events will be archived and become unavailable.',
      create: 'Create Organization',
      dismiss: 'Dismiss',
    },
  },
};
