import { GetterTree } from 'vuex';
import {
  hasPermission,
  hasOrganizationRole,
  isOrganizationMember,
  SettingsDto,
  Role,
  isMemberOfAnyOrganization,
} from 'fourwaves-shared';
import { RootState } from '~/types';

export const getters: GetterTree<{}, RootState> = {
  userDashboardRoleCookieName: (_state, _getters, rootState: RootState) =>
    rootState.auth?.user ? `udrole_${rootState.auth.user.id}` : '',

  hasPermission: (_1, _2, rootState: RootState) => (eventId: string, role: Role) => {
    return rootState && rootState.auth && rootState.auth.user
      ? hasPermission(rootState.auth.user as SettingsDto, eventId, role)
      : false;
  },

  isOrganizer: (_, getters, rootState: RootState) => {
    return rootState.events?.eventId ? getters.hasPermission(rootState.events.eventId, Role.Organizer) : false;
  },

  isChair: (_, getters, rootState: RootState) => {
    return rootState.events?.eventId ? getters.hasPermission(rootState.events.eventId, Role.Chair) : false;
  },

  isReviewer: (_, getters, rootState: RootState) => {
    return rootState.events?.eventId
      ? getters.hasPermission(rootState.events.eventId, Role.Organizer) ||
          getters.hasPermission(rootState.events.eventId, Role.Reviewer) ||
          getters.hasPermission(rootState.events.eventId, Role.Chair)
      : false;
  },

  isParticipant: (_, getters, rootState: RootState) => {
    return rootState.events?.eventId
      ? getters.hasPermission(rootState.events.eventId, Role.Participant) ||
          getters.hasPermission(rootState.events.eventId, Role.Organizer)
      : false;
  },

  hasOrganizationRole: (_state, _getter, rootState: RootState) => (organizationId: string, role: Role) => {
    return rootState.auth && rootState.auth.user && rootState.auth.user.userRoles
      ? hasOrganizationRole(rootState.auth.user as SettingsDto, organizationId, role)
      : false;
  },

  isOrganizationMember: (_state, _getter, rootState: RootState) => (orgId: string) => {
    return rootState.auth && rootState.auth.user
      ? isOrganizationMember(rootState.auth.user as SettingsDto, orgId)
      : false;
  },

  isMemberOfAnyOrganization: (_state, _getter, rootState: RootState) => {
    return rootState.auth && rootState.auth.user
      ? isMemberOfAnyOrganization(rootState.auth.user as SettingsDto)
      : false;
  },
};
