export default {
  checkout: {
    contact_us: 'Contact Us',
    contact_us_url: 'https://meetings.hubspot.com/matt193/chat-with-fourwaves',
    per_event: '/ Event',
    license_code: 'I have a license code',
    description: 'Not sure what are the best options for your needs?',
    org_billing_info: {
      title: 'Billing Information',
      use_org: "Use my Organization's billing address",
      name: 'Name',
      address_line_1: 'Street Address',
      city: 'City',
      postal_code: 'Postal / Zip',
      purchase_number: 'Purchase Order Number',
    },
    packages: {
      title: 'Select a package for this event.',
      subtitle: 'If applicable, a credit will be applied at checkout according to your current single-event plan.',
      fee: '{count}% Transaction Fee',
      fee_pro: 'Custom Transaction Fee',
      fee_custom: '% Transaction Fee',
      custom: 'Custom',
      select: 'Select Package',
      selected: 'Selected',
      current: 'Current Plan',
      downgrade: "You can't downgrade from current plan.",
      hint: 'Please note that your payment processor fee will also apply on top of this.',
    },
    add_ons: {
      title: 'Select add-ons for this event.',
      select: 'Select Add-On',
      purchased: 'Already Purchased',
    },
    cart: {
      subtotal: 'Subtotal',
      gst: 'GST',
      qst: 'QST',
      hst: 'HST',
      total: 'Total',
      powered_by: 'Powered & secured by',
    },
  },
};
