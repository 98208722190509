
import { isValidGuid } from 'fourwaves-shared';
import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator';
import { OrganizationNavItem, RouteMapItem } from '../types';
import NavItem from './NavItem.vue';

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: { NavItem },
})
export default class FinancesSideNavItems extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean;

  @Emit()
  onToggle() {
    return OrganizationNavItem.Finances;
  }

  get routesMap(): RouteMapItem[] {
    return [
      {
        visibility: true,
        to: { name: 'organizations-id-bankAccounts', params: { id: this.$route.params.id } },
        label: this.$t('navigation.organizations.finances.bank_accounts'),
        dataTestId: 'org-bank-accounts',
      },
      {
        visibility: true,
        to: { name: 'organizations-id-transactions', params: { id: this.$route.params.id } },
        label: this.$t('navigation.organizations.finances.transactions'),
        dataTestId: 'org-transactions',
      },
    ];
  }

  get isParentActive() {
    return this.routesMap.some(({ to }) => this.$route.name?.split('___')[0].startsWith(to?.name || ''));
  }

  created() {
    if (this.isParentActive) this.onToggle();
  }
}
