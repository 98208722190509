
import { isValidGuid, TranslatableField } from 'fourwaves-shared';
import { Collapsible } from 'fourwaves-shared/components';
import { Component, mixins, namespace, Emit } from 'nuxt-property-decorator';
import NavItem from '../nav-items/NavItem.vue';
import ConfigurationSideNavItems from '../nav-items/ConfigurationSideNavItems.vue';
import WebsiteSideNavItems from '../nav-items/WebsiteSideNavItems.vue';
import DataSideNavItems from '../nav-items/DataSideNavItems.vue';
import { OrganizerNavItem } from '../types';
import NavPanelMixin from '../NavPanelMixin';

const EventsModule = namespace('events');

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: {
    Collapsible,
    NavItem,
    ConfigurationSideNavItems,
    WebsiteSideNavItems,
    DataSideNavItems,
  },
})
export default class OrganizerMobilePanel extends mixins(NavPanelMixin) {
  @EventsModule.State eventName!: TranslatableField;
  @EventsModule.State eventId!: string;

  readonly OrganizerNavItem = OrganizerNavItem;

  @Emit() closePanel() {}

  get previousPageRoute() {
    return this.$nuxt.context.from.name && this.$nuxt.context.from.name.startsWith('user-dashboard-organizer___')
      ? { ...this.$nuxt.context.from, name: this.$nuxt.context.from.name.slice(0, -5) }
      : { name: 'user-dashboard-organizer' };
  }

  public getEventWebsiteUrl() {
    return this.$api.getEventWebsiteUrl(this.eventId);
  }
}
