import { Middleware } from '@nuxt/types';

const enforceChairRoleMiddleware: Middleware = context => {
  if (!context.$auth.$state.loggedIn) {
    // "auth" middleware will enforce this
    return;
  }

  const isUserChair = context.store.getters['settings/isChair'];
  if (!isUserChair) {
    const errorMessage = context.app.i18n.t('shared.error.server_validation.authorization_error');
    context.app.$toast.error(errorMessage);
    context.$auth.redirect('home');
  }
};

export default enforceChairRoleMiddleware;
