import en from 'vee-validate/dist/locale/en';
import fr from 'vee-validate/dist/locale/fr';
import { Validator, RuleResult } from 'vee-validate';
import isEmail from 'validator/lib/isEmail';
import {
  validationRules as sharedValidationRules,
  CustomValidationRule,
} from 'fourwaves-shared/src/plugins/validation-rules';
import { contentMixins } from 'fourwaves-shared';
import { validationRules } from './validation-rules';

export default function ({ app }) {
  // Loading base files
  Validator.localize('en', en);
  Validator.localize('fr', fr);

  // Extend the validator with custom rules
  Object.entries<CustomValidationRule>({ ...validationRules, ...sharedValidationRules }).forEach(([ruleName, rule]) => {
    Validator.extend(ruleName, rule.validate, rule.options ? rule.options : undefined);

    if (rule.localize)
      Validator.localize({
        fr: { messages: { [ruleName]: rule.localize.fr } },
        en: { messages: { [ruleName]: rule.localize.en } },
      });
  });

  // Changing default message for each message
  [
    { locale: 'en', module: en },
    { locale: 'fr', module: fr },
  ].forEach(data => {
    Validator.localize(data.locale, data.module);
    Validator.extend('emails', {
      getMessage(field, params, data) {
        return (data && data.message) || app.i18n.t('validation.emails');
      },
      validate(value) {
        const errors: RuleResult[] = contentMixins
          .getDelimitedValue(value)
          .filter(email => !isEmail(email))
          .map(email => ({
            valid: false,
            data: { message: `${email}: ${app.i18n.t('validation.emails')}` },
          }));
        return errors.length > 0
          ? errors[0]
          : {
              valid: true,
              data: { message: '' },
            };
      },
    });
  });

  // Setup custom messages
  Validator.localize({
    fr: {
      messages: {
        required: () => app.i18n.t('validation.required'),
        is: () => app.i18n.t('validation.is'),
        email: () => app.i18n.t('validation.email'),
        url: () => app.i18n.t('shared.error.server_validation.url'),
        min: (field, [length]) => `Ce champ doit être composé d'au moins ${length} caractères.`,
        max: (field, max) => `Ce champ ne peut pas contenir plus de ${max} caractères.`,
        min_value: (field, [min]) => `Ce champ doit avoir une valeur de ${min} ou plus.`,
        max_value: (field, [max]) => `Ce champ doit avoir une valeur de ${max} ou moins.`,
        numeric: () => `Ce champ ne peut contenir que des chiffres.`,
        alpha_num: () => `Ce champ doit contenir uniquemment des caractères alphanumériques.`,
        url_segment: () => `Ce champ doit contenir uniquemment des caractères alphanumériques.`,
        date_format: () => `Ce champ doit avoir un format de date valide.`,
        after: (field, [target]) => `Ce champ doit être postérieur au précédent (${target}).`,
        integer: () => `Ce champ doit être un entier`,
        decimal: (field, digits) => `Ce champ doit être numérique et peut contenir ${digits} décimales.`,
        isBefore: () => `La date de début ne peut être après la date de fin.`,
        isAfter: () => `La date de fin ne peut être avant la date de début.`,
        isTimeBefore: () => `L'heure de début ne peut être après l'heure de fin.`,
        isTimeAfter: () => `L'heure de fin ne peut être avant l'heure de début.`,
        word_limit: () => `Vous avez entré trop de mots. Veuillez raccourcir l'entrée.`,
        tlink_url: () => `Le champ doit être une URL TLink valide.`,
        upay_url: () => `Le champ doit être une URL uPay valide.`,
      },
    },
    en: {
      messages: {
        required: () => app.i18n.t('validation.required'),
        is: () => app.i18n.t('validation.is'),
        email: () => app.i18n.t('validation.email'),
        url: () => app.i18n.t('shared.error.server_validation.url'),
        min: (field, [length]) => `The field must be at least ${length} characters.`,
        max: (field, max) => `The field may not be greater than ${max} characters.`,
        min_value: (field, [min]) => `The field must be ${min} or more.`,
        max_value: (field, [max]) => `The field must be ${max} or less.`,
        numeric: () => `The field may only contain numeric characters.`,
        alpha_num: () => `This field must contain alphanumeric characters.`,
        url_segment: () => `This field must contain alphanumeric characters.`,
        date_format: () => `This field must contain valid date format.`,
        after: (field, [target]) => `This field must be after the previous one (${target}).`,
        integer: () => `This field must be an integer.`,
        decimal: (field, digits) => `This field must be numeric and may contain ${digits} decimal points.`,
        isBefore: () => `The start date cannot be after the end date.`,
        isAfter: () => `The end date cannot be before the start date.`,
        isTimeBefore: () => `The start time cannot be after the end time.`,
        isTimeAfter: () => `The end time cannot be before the start time.`,
        word_limit: () => `You entered too many words. Please shorten the input.`,
        tlink_url: () => `The field must be a valid TLink URL.`,
        upay_url: () => `The field must be a valid uPay Site URL.`,
      },
    },
  });

  // Localizing the app when user refresh or access a localized link
  Validator.localize(app.i18n.locale);

  // Called everytime language change
  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup) => {
    if (!isInitialSetup) {
      Validator.localize(newLocale);
    }
  };
}
