
import { Component, mixins, Watch, Ref, Emit, Prop } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import OrganizerSideNavPanel from './side-nav-panel/OrganizerSideNavPanel.vue';
import OrganizationSideNavPanel from './side-nav-panel/OrganizationSideNavPanel.vue';
import { RouteContextMixin } from '~/mixins';

@Component({
  components: {
    OrganizerSideNavPanel,
    OrganizationSideNavPanel,
  },
})
export default class SideNav extends mixins(RouteContextMixin) {
  @Prop(Boolean) isFloating!: boolean;

  isHover = false;

  @Ref() readonly sideNavScroll?: HTMLElement;

  simplebarInstance: SimpleBar | null = null;

  @Emit() onToggleMode() {}

  get isCollapsed() {
    return !this.isHover && this.isFloating;
  }

  get classNames() {
    return {
      '-dark': this.isInOrganization,
      '-hover': this.isHover && this.isFloating,
      '-floating': this.isFloating,
    };
  }

  @Watch('$route')
  onRouteChanged() {
    this.isHover = false;
  }

  public toggleMode() {
    if (!this.isFloating) this.isHover = false;
    this.onToggleMode();
  }

  async mounted() {
    await this.$nextTick();
    if (!this.sideNavScroll) return;
    this.simplebarInstance = new SimpleBar(this.sideNavScroll);
    await this.$nextTick();
    this.sideNavScroll?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
  }
}
