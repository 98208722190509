
import { Component, mixins, Ref, namespace, Emit, Prop } from 'nuxt-property-decorator';
import { OrganizationCreateModal } from 'fourwaves-shared/components';
import { OrganizationDto } from 'fourwaves-shared';
import { PreventPageScrollMixin } from '~/mixins';

const OrganizationsModule = namespace('organizations');

@Component({
  components: {
    OrganizationCreateModal,
  },
})
export default class OrganizationCreateModalWrapper extends mixins(PreventPageScrollMixin) {
  @Prop(Boolean) readonly withRedirect!: boolean;
  @OrganizationsModule.Getter adminOrganizations!: OrganizationDto[];
  @OrganizationsModule.Action fetchOrganizations!: () => Promise<void>;

  @Ref() readonly modal?: OrganizationCreateModal;

  @Emit() close() {}
  @Emit()
  public organizationCreated(organizationId: string): string {
    return organizationId;
  }

  public openModal(preSelectedEventId?: string) {
    this.modal?.openModal(preSelectedEventId);
  }

  public open() {
    this.preventPageScroll();
  }

  public onClose() {
    this.close();
    this.restorePageScroll();
  }

  public async onOrganizationCreated(organizationId: string) {
    await this.$auth.fetchUser();
    await this.fetchOrganizations();
    this.organizationCreated(organizationId);
    this.$toast.success(this.$t('shared.organizations.create_org.success_message') as string);
    if (this.withRedirect)
      await this.$router.push(this.localePath({ name: 'organizations-id-events', params: { id: organizationId } }));
  }

  beforeDestroy() {
    this.restorePageScroll();
  }
}
