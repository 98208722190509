export default {
  event_config: {
    no_organization: 'Missing organization',
    clone_modal: {
      title: 'Clone Event',
      name: 'Event Name',
      organization: 'Organization',
      no_org_title: 'Missing Organization',
      no_org_subtitle: 'Please link this event to an Organization before you can clone it',
      cant_clone_title: "Woops! Can't Clone Event",
      cant_clone_subtitle:
        'This action is reserved for administrators only. Please contact your administrator to clone this event.',
    },
    org_edit_modal: {
      title: 'Edit Organization',
      subtitle: 'Please be careful with this action as this could affect the access and permissions of some users.',
      current_org: 'Current Organization',
      move_to: 'Move to',
      no_org_title: 'Missing Organization',
      no_org_subtitle: 'Please create your organization first, so that you can link this event to it.',
    },
    general: {
      title: 'General',
      information: 'Information',
      event_name: 'Event Name',
      event_url_segment: 'Acronym',
      event_contact_email: 'Contact Email',
      event_contact_email_tips: 'Appears on various pages and receives replies to emails sent through Fourwaves.',
      event_language: 'Website Language(s)',

      dates_title: 'Dates',
      dates_tip: "Can't be modified once the event is published.",
      event_timezone: 'Event Time Zone',
      event_startDate: 'From',
      event_endDate: 'To',

      location_title: 'Location',
      event_type: 'Event Format',
      is_online: 'Online',
      is_physical: 'Physical',
      is_hybrid: 'Hybrid',
      event_place: 'Venue',
      event_formatted_address: 'Address',
      event_street_number: 'Street Number',
      event_route: 'Street Name',
      event_locality: 'City',
      event_administrative_area_level_1: 'State or Province',
      event_country: 'Country',
      event_postal_code: 'Postal or ZIP',
    },
    theme: {
      title: 'Theme & Colors',
      theme_title: 'Theme',
      theme_light: 'Light',
      theme_dark: 'Dark',

      banner_title: 'Banner',
      banner_tips: 'Appears on the home page of your event website',

      logo_title: 'Logo',
      logo_tips: 'Appears on invoices and badges',

      color_title: 'Primary color',
    },
    committee: {
      title: 'Committee',
      pending_user: 'Account pending activation',
      organizers: {
        title: 'Organizers',
        empty_tile: 'No organizing committee members have been added to this event yet.',
      },
    },
    payments: {
      title: 'Payments',
      online_title: 'Online Payments',
      stripe: {
        account: 'Stripe Account',
        connect: 'Go to Stripe',
        connected: 'Connected',
        create: 'Connect a Stripe account to this event',
        tips: 'You might need to create it first.',
        choose_currency: 'Select the currency of your fees',
      },
      gateway: {
        title: 'Connect Bank Account',
        title_choose_bank_account: 'Connect your organization’s bank account to this event',
        title_no_bank_accounts: 'Your Organization has no bank accounts',
        action_gateway_code: 'Use a Gateway Code',
        action_alternatively: 'Or alternatively, ',
        action_create_bank_account: 'Go to your Organization',
        action_create_bank_account_connect: 'to connect one, or ',
        enter_gateway_code: 'Enter your Gateway Code',
        fill_out_form: 'Fill out the following form',
        one_last_click: "One last click and you'll be ready to collect credit card payments.",
        will_be_analyzed: 'Your connection request will be analyzed and processed by your organization.',
        will_be_analyzed_other_org:
          'Your connection request will then be analyzed and processed by the corresponding organization.',
        bank_account_select: 'Bank Account',
        bank_account_select_placeholder: 'Select a bank account',
        connect: 'Connect',
        connection_request: 'Connection Request',
        submit_request: 'Submit Request',
        sent_on: 'Connection request sent · {timestamp}<br/> Decision pending',
        connected: 'Connected',
        correction_required: 'Modifications needed · ',
        refused_on: 'Rejected on · ',
        cancel_title: 'Cancel Request',
        cancel_message: 'Are you sure you want to cancel this bank account connection request?',
        no_gateway_error: 'This bank account is not linked to a payment gateway. Please contact your administrator.',
        bank_account_required: 'Please select a bank account.',
      },
      deferred_payments: {
        title: 'Invoice Payments',
        toggle: 'Allow participants to pay with check or wire transfer',
        tips: 'Will be displayed at checkout and on invoices.',
      },
      coupon_codes: {
        title: 'Coupon codes',
        empty_tile: 'Create coupon codes so that you can give special rates or discounts to a selected few.',
        sub_title: 'Create coupon codes that participants can use during the checkout',
        usage: 'Usage',
        amountType: {
          fixed: 'Amount',
          percentage: 'Percentage',
        },
        modal: {
          title_add: 'Add Coupon Code',
          title_edit: 'Edit Coupon Code',
          amount: 'Amount',
          amount_type: 'Type',
          has_max_usage: 'Limit quantity',
          max_usage: 'Quantity',
          usage: 'Usages',
        },
      },
    },
    reviews: {
      reviewers: {
        title: 'Reviewers',
        empty_tile: 'No reviewers have been added to this event yet.',
      },
    },
  },
};
