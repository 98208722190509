import { BasePaginatedModel } from 'fourwaves-shared';

type SenderEvent = { event: string; reason: string };

export enum SentEmailStatus {
  Delivered = 'delivered',
  Processed = 'processed',
  Deferred = 'deferred',
  Bounce = 'bounce',
  Dropped = 'dropped',
  Unprocessed = 'unprocessed',
}

export interface GetSentEmailsModel extends BasePaginatedModel {
  eventId: string;
  searchTerm?: string;
  status?: string;
  campaignId?: string;
}

export interface CampaignDto {
  id: string;
  subject: string;
  creationDate: string;
}

export interface SentEmailDto {
  id: string;
  toAddress: string;
  subject: string;
  creationDate: string;
  status: SentEmailStatus;
  statusReason: string | null;
}

export interface SentEmailDetailsDto {
  id: string;
  toAddress: string;
  fromAddress: string;
  subject: string;
  body: string;
  status: SentEmailStatus;
  statusReason: string | null;
  events: SenderEvent[];
  creationDate: string;
}
