export default {
  navigation: {
    organizer: {
      overview: 'Overview',
      event_config: {
        title: 'Configuration',
        general: 'General',
        theme_colors: 'Theme & Color',
        committee: 'Committee',
        payments: 'Payments',
        reviews: 'Reviews',
      },
      website_pages: {
        title: 'Website Pages',
      },
      event_data: {
        title: 'Event Data',
        registrations: 'Registrations',
        submissions: 'Submissions',
        reviews: 'Reviews',
        transactions: 'Transactions',
        communications: 'Communications',
      },
      manage_pages: 'Manage Pages',
      view_event: 'View Website',
      back_to_dashboard: 'Back to Dashboard',
    },
    organizations: {
      events: 'Events',
      users: 'Users',
      settings: 'Settings',
      finances: {
        title: 'Finances',
        bank_accounts: 'Bank Accounts',
        transactions: 'Transactions',
      },
      plans: 'Plans & Billing',
      switch_organization: 'Switch Organizations',
      aria_label: 'Organizations Menu',
      add_organization: 'New Organization',
    },
    lang_chooser: {
      aria_label: 'Language Chooser',
      lang_switch: 'Naviguer en',
    },
  },
};
