
import { Component, mixins, Emit, namespace } from 'nuxt-property-decorator';
import { isValidGuid } from 'fourwaves-shared';
import FourwavesLogoSquare from 'fourwaves-shared/src/images/fourwaves-light-sq.png';
import NavItem from '../nav-items/NavItem.vue';
import FinancesSideNavItems from '../nav-items/FinancesSideNavItems.vue';
import { OrganizationNavItem } from '../types';
import NavPanelMixin from '../NavPanelMixin';

const SettingsModule = namespace('settings');

@Component({
  validate({ params }) {
    return isValidGuid(params.id);
  },
  components: {
    NavItem,
    FinancesSideNavItems,
  },
})
export default class OrganizationMobilePanel extends mixins(NavPanelMixin) {
  @SettingsModule.Getter hasOrganizationRole!: (organizationId: string, role: Role) => boolean;

  @Emit() closePanel() {}

  readonly FourwavesLogoSquare = FourwavesLogoSquare;
  readonly OrganizationNavItem = OrganizationNavItem;
}
