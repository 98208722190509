import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2df5e1aa = () => interopDefault(import('../src/pages/accountNotFound.vue' /* webpackChunkName: "pages/accountNotFound" */))
const _666226d8 = () => interopDefault(import('../src/pages/completeRegistration.vue' /* webpackChunkName: "pages/completeRegistration" */))
const _7d0b85ea = () => interopDefault(import('../src/pages/create-event.vue' /* webpackChunkName: "pages/create-event" */))
const _5d276740 = () => interopDefault(import('../src/pages/forgotPassword.vue' /* webpackChunkName: "pages/forgotPassword" */))
const _19af2d80 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5dc9557b = () => interopDefault(import('../src/pages/get-started/index.vue' /* webpackChunkName: "pages/get-started/index" */))
const _865fe752 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2a42870c = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _3651b111 = () => interopDefault(import('../src/pages/organizations/index.vue' /* webpackChunkName: "pages/organizations/index" */))
const _275776f7 = () => interopDefault(import('../src/pages/user-dashboard/organizer/index.vue' /* webpackChunkName: "pages/user-dashboard/organizer/index" */))
const _7523528a = () => interopDefault(import('../src/pages/user-dashboard/participant/index.vue' /* webpackChunkName: "pages/user-dashboard/participant/index" */))
const _3e17a1c6 = () => interopDefault(import('../src/pages/user-dashboard/participant/index/events.vue' /* webpackChunkName: "pages/user-dashboard/participant/index/events" */))
const _7c8cd9f4 = () => interopDefault(import('../src/pages/user-dashboard/participant/index/registrations.vue' /* webpackChunkName: "pages/user-dashboard/participant/index/registrations" */))
const _54457953 = () => interopDefault(import('../src/pages/user-dashboard/participant/index/submissions.vue' /* webpackChunkName: "pages/user-dashboard/participant/index/submissions" */))
const _75f28f4e = () => interopDefault(import('../src/pages/user-dashboard/participant/index/transactions.vue' /* webpackChunkName: "pages/user-dashboard/participant/index/transactions" */))
const _39daa910 = () => interopDefault(import('../src/pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _373895b3 = () => interopDefault(import('../src/pages/resetPasswordDenied.vue' /* webpackChunkName: "pages/resetPasswordDenied" */))
const _0b7abc21 = () => interopDefault(import('../src/pages/restrictedAccess.vue' /* webpackChunkName: "pages/restrictedAccess" */))
const _a6a7f45a = () => interopDefault(import('../src/pages/user-dashboard/reviewer/index.vue' /* webpackChunkName: "pages/user-dashboard/reviewer/index" */))
const _f4ad0396 = () => interopDefault(import('../src/pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _3b89c8f8 = () => interopDefault(import('../src/pages/account/profile/index.vue' /* webpackChunkName: "pages/account/profile/index" */))
const _cf946336 = () => interopDefault(import('../src/pages/account/profile/password.vue' /* webpackChunkName: "pages/account/profile/password" */))
const _7d017b32 = () => interopDefault(import('../src/pages/errors/passwordless-token.vue' /* webpackChunkName: "pages/errors/passwordless-token" */))
const _8a05bc2c = () => interopDefault(import('../src/pages/get-started/check-inbox.vue' /* webpackChunkName: "pages/get-started/check-inbox" */))
const _97dc2828 = () => interopDefault(import('../src/pages/get-started/success.vue' /* webpackChunkName: "pages/get-started/success" */))
const _58b42824 = () => interopDefault(import('../src/pages/user-dashboard/participant/registrations/_id.vue' /* webpackChunkName: "pages/user-dashboard/participant/registrations/_id" */))
const _03c04efb = () => interopDefault(import('../src/pages/user-dashboard/participant/submissions/_id.vue' /* webpackChunkName: "pages/user-dashboard/participant/submissions/_id" */))
const _4b566d3e = () => interopDefault(import('../src/pages/user-dashboard/participant/submissions/_id/index.vue' /* webpackChunkName: "pages/user-dashboard/participant/submissions/_id/index" */))
const _f6eb64c8 = () => interopDefault(import('../src/pages/user-dashboard/participant/submissions/_id/review.vue' /* webpackChunkName: "pages/user-dashboard/participant/submissions/_id/review" */))
const _65370a68 = () => interopDefault(import('../src/pages/user-dashboard/reviewer/_eventId/index.vue' /* webpackChunkName: "pages/user-dashboard/reviewer/_eventId/index" */))
const _348b3c85 = () => interopDefault(import('../src/pages/events/configuration/index.vue' /* webpackChunkName: "pages/events/configuration/index" */))
const _0f0ef020 = () => interopDefault(import('../src/pages/events/dashboard.vue' /* webpackChunkName: "pages/events/dashboard" */))
const _43c689ea = () => interopDefault(import('../src/pages/organizations/_id/bankAccounts/index.vue' /* webpackChunkName: "pages/organizations/_id/bankAccounts/index" */))
const _5c5d7ae9 = () => interopDefault(import('../src/pages/organizations/_id/settings.vue' /* webpackChunkName: "pages/organizations/_id/settings" */))
const _9d9e6f42 = () => interopDefault(import('../src/pages/organizations/_id/events.vue' /* webpackChunkName: "pages/organizations/_id/events" */))
const _eb4dfdd8 = () => interopDefault(import('../src/pages/organizations/_id/plans.vue' /* webpackChunkName: "pages/organizations/_id/plans" */))
const _95f04fca = () => interopDefault(import('../src/pages/organizations/_id/transactions.vue' /* webpackChunkName: "pages/organizations/_id/transactions" */))
const _2143b5b2 = () => interopDefault(import('../src/pages/organizations/_id/users.vue' /* webpackChunkName: "pages/organizations/_id/users" */))
const _653d8fa0 = () => interopDefault(import('../src/pages/events/configuration/committee.vue' /* webpackChunkName: "pages/events/configuration/committee" */))
const _7fa9208e = () => interopDefault(import('../src/pages/events/configuration/reviews.vue' /* webpackChunkName: "pages/events/configuration/reviews" */))
const _e05af6de = () => interopDefault(import('../src/pages/events/configuration/reviews/index.vue' /* webpackChunkName: "pages/events/configuration/reviews/index" */))
const _2017a54d = () => interopDefault(import('../src/pages/events/configuration/reviews/reviewers.vue' /* webpackChunkName: "pages/events/configuration/reviews/reviewers" */))
const _5b7c8498 = () => interopDefault(import('../src/pages/events/configuration/reviews/settings.vue' /* webpackChunkName: "pages/events/configuration/reviews/settings" */))
const _69bb4f4a = () => interopDefault(import('../src/pages/events/configuration/payments.vue' /* webpackChunkName: "pages/events/configuration/payments" */))
const _65b635fc = () => interopDefault(import('../src/pages/events/configuration/theme.vue' /* webpackChunkName: "pages/events/configuration/theme" */))
const _7fcaddf1 = () => interopDefault(import('../src/pages/events/data/communications/index.vue' /* webpackChunkName: "pages/events/data/communications/index" */))
const _303be7dd = () => interopDefault(import('../src/pages/events/data/reviews/index.vue' /* webpackChunkName: "pages/events/data/reviews/index" */))
const _3007836b = () => interopDefault(import('../src/pages/events/data/schedule/index.vue' /* webpackChunkName: "pages/events/data/schedule/index" */))
const _70dcfb8a = () => interopDefault(import('../src/pages/events/data/registration.vue' /* webpackChunkName: "pages/events/data/registration" */))
const _0201a6dd = () => interopDefault(import('../src/pages/events/data/submission.vue' /* webpackChunkName: "pages/events/data/submission" */))
const _738a58c0 = () => interopDefault(import('../src/pages/events/data/submission/index.vue' /* webpackChunkName: "pages/events/data/submission/index" */))
const _6632b37a = () => interopDefault(import('../src/pages/events/data/submission/review.vue' /* webpackChunkName: "pages/events/data/submission/review" */))
const _31a7c029 = () => interopDefault(import('../src/pages/events/data/transactions/index.vue' /* webpackChunkName: "pages/events/data/transactions/index" */))
const _d4d99342 = () => interopDefault(import('../src/pages/events/configuration/pages/schedule.vue' /* webpackChunkName: "pages/events/configuration/pages/schedule" */))
const _210f39a2 = () => interopDefault(import('../src/pages/events/configuration/pages/schedule/index.vue' /* webpackChunkName: "pages/events/configuration/pages/schedule/index" */))
const _39d4a303 = () => interopDefault(import('../src/pages/events/configuration/pages/schedule/settings.vue' /* webpackChunkName: "pages/events/configuration/pages/schedule/settings" */))
const _31fad381 = () => interopDefault(import('../src/pages/events/configuration/pages/registration.vue' /* webpackChunkName: "pages/events/configuration/pages/registration" */))
const _65be1578 = () => interopDefault(import('../src/pages/events/configuration/pages/registration/index.vue' /* webpackChunkName: "pages/events/configuration/pages/registration/index" */))
const _7ba5a30e = () => interopDefault(import('../src/pages/events/configuration/pages/registration/email.vue' /* webpackChunkName: "pages/events/configuration/pages/registration/email" */))
const _1d6eac21 = () => interopDefault(import('../src/pages/events/configuration/pages/registration/settings.vue' /* webpackChunkName: "pages/events/configuration/pages/registration/settings" */))
const _71d31094 = () => interopDefault(import('../src/pages/events/configuration/pages/submission.vue' /* webpackChunkName: "pages/events/configuration/pages/submission" */))
const _d7a44fd2 = () => interopDefault(import('../src/pages/events/configuration/pages/submission/index.vue' /* webpackChunkName: "pages/events/configuration/pages/submission/index" */))
const _42b285e1 = () => interopDefault(import('../src/pages/events/configuration/pages/submission/email.vue' /* webpackChunkName: "pages/events/configuration/pages/submission/email" */))
const _5c031724 = () => interopDefault(import('../src/pages/events/configuration/pages/submission/settings.vue' /* webpackChunkName: "pages/events/configuration/pages/submission/settings" */))
const _6d998c91 = () => interopDefault(import('../src/pages/events/configuration/pages/_pageId.vue' /* webpackChunkName: "pages/events/configuration/pages/_pageId" */))
const _0974a254 = () => interopDefault(import('../src/pages/events/configuration/pages/_pageId/index.vue' /* webpackChunkName: "pages/events/configuration/pages/_pageId/index" */))
const _6ae72511 = () => interopDefault(import('../src/pages/events/configuration/pages/_pageId/settings.vue' /* webpackChunkName: "pages/events/configuration/pages/_pageId/settings" */))
const _6ebee503 = () => interopDefault(import('../src/pages/events/data/communications/_communicationId.vue' /* webpackChunkName: "pages/events/data/communications/_communicationId" */))
const _1f2b8832 = () => interopDefault(import('../src/pages/events/data/form-entries/_formId.vue' /* webpackChunkName: "pages/events/data/form-entries/_formId" */))
const _f6800d06 = () => interopDefault(import('../src/pages/events/data/reviews/_reviewId.vue' /* webpackChunkName: "pages/events/data/reviews/_reviewId" */))
const _04e557f4 = () => interopDefault(import('../src/pages/events/data/form-entries/badges/_formId.vue' /* webpackChunkName: "pages/events/data/form-entries/badges/_formId" */))
const _032459f1 = () => interopDefault(import('../src/pages/events/data/form-entries/poster-tags/_formId.vue' /* webpackChunkName: "pages/events/data/form-entries/poster-tags/_formId" */))
const _111abbe4 = () => interopDefault(import('../src/pages/organizations/_id/bankAccounts/_bankAccountId.vue' /* webpackChunkName: "pages/organizations/_id/bankAccounts/_bankAccountId" */))
const _98887132 = () => interopDefault(import('../src/pages/organizations/_id/bankAccounts/_bankAccountId/index.vue' /* webpackChunkName: "pages/organizations/_id/bankAccounts/_bankAccountId/index" */))
const _238504bf = () => interopDefault(import('../src/pages/organizations/_id/bankAccounts/_bankAccountId/form.vue' /* webpackChunkName: "pages/organizations/_id/bankAccounts/_bankAccountId/form" */))
const _06fa6e3f = () => interopDefault(import('../src/pages/organizations/_id/bankAccounts/_bankAccountId/requests.vue' /* webpackChunkName: "pages/organizations/_id/bankAccounts/_bankAccountId/requests" */))
const _0f56f260 = () => interopDefault(import('../src/pages/user-dashboard/reviewer/_eventId/_id.vue' /* webpackChunkName: "pages/user-dashboard/reviewer/_eventId/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'higher-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/account-not-found",
    component: _2df5e1aa,
    name: "accountNotFound___en"
  }, {
    path: "/complete-registration",
    component: _666226d8,
    name: "completeRegistration___en"
  }, {
    path: "/create-new-event",
    component: _7d0b85ea,
    name: "create-event___en"
  }, {
    path: "/forgot-password",
    component: _5d276740,
    name: "forgotPassword___en"
  }, {
    path: "/fr",
    component: _19af2d80,
    name: "index___fr"
  }, {
    path: "/get-started",
    component: _5dc9557b,
    name: "get-started___en"
  }, {
    path: "/login",
    component: _865fe752,
    name: "login___en"
  }, {
    path: "/logout",
    component: _2a42870c,
    name: "logout___en"
  }, {
    path: "/organizations",
    component: _3651b111,
    name: "organizations___en"
  }, {
    path: "/organizer",
    component: _275776f7,
    name: "user-dashboard-organizer___en"
  }, {
    path: "/participant",
    component: _7523528a,
    name: "user-dashboard-participant___en",
    children: [{
      path: "/participant/my-events",
      component: _3e17a1c6,
      name: "user-dashboard-participant-index-events___en"
    }, {
      path: "/participant/my-registrations",
      component: _7c8cd9f4,
      name: "user-dashboard-participant-index-registrations___en"
    }, {
      path: "/participant/my-submissions",
      component: _54457953,
      name: "user-dashboard-participant-index-submissions___en"
    }, {
      path: "/participant/my-transactions",
      component: _75f28f4e,
      name: "user-dashboard-participant-index-transactions___en"
    }]
  }, {
    path: "/reset-password",
    component: _39daa910,
    name: "resetPassword___en"
  }, {
    path: "/reset-password-denied",
    component: _373895b3,
    name: "resetPasswordDenied___en"
  }, {
    path: "/restricted-access",
    component: _0b7abc21,
    name: "restrictedAccess___en"
  }, {
    path: "/reviewer",
    component: _a6a7f45a,
    name: "user-dashboard-reviewer___en"
  }, {
    path: "/account/profile",
    component: _f4ad0396,
    children: [{
      path: "/account/profile",
      component: _3b89c8f8,
      name: "account-profile___en"
    }, {
      path: "/account/profile/password",
      component: _cf946336,
      name: "account-profile-password___en"
    }]
  }, {
    path: "/errors/invalid-token",
    component: _7d017b32,
    name: "errors-passwordless-token___en"
  }, {
    path: "/fr/acces-restreint",
    component: _0b7abc21,
    name: "restrictedAccess___fr"
  }, {
    path: "/fr/commencer",
    component: _5dc9557b,
    name: "get-started___fr"
  }, {
    path: "/fr/completer-inscription",
    component: _666226d8,
    name: "completeRegistration___fr"
  }, {
    path: "/fr/compte-introuvable",
    component: _2df5e1aa,
    name: "accountNotFound___fr"
  }, {
    path: "/fr/connexion",
    component: _865fe752,
    name: "login___fr"
  }, {
    path: "/fr/creer-un-evenement",
    component: _7d0b85ea,
    name: "create-event___fr"
  }, {
    path: "/fr/evaluateur",
    component: _a6a7f45a,
    name: "user-dashboard-reviewer___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _5d276740,
    name: "forgotPassword___fr"
  }, {
    path: "/fr/organisateur",
    component: _275776f7,
    name: "user-dashboard-organizer___fr"
  }, {
    path: "/fr/organisations",
    component: _3651b111,
    name: "organizations___fr"
  }, {
    path: "/fr/participant",
    component: _7523528a,
    name: "user-dashboard-participant___fr",
    children: [{
      path: "/fr/participant/mes-evenements",
      component: _3e17a1c6,
      name: "user-dashboard-participant-index-events___fr"
    }, {
      path: "/fr/participant/mes-inscriptions",
      component: _7c8cd9f4,
      name: "user-dashboard-participant-index-registrations___fr"
    }, {
      path: "/fr/participant/mes-soumissions",
      component: _54457953,
      name: "user-dashboard-participant-index-submissions___fr"
    }, {
      path: "/fr/participant/mes-transactions",
      component: _75f28f4e,
      name: "user-dashboard-participant-index-transactions___fr"
    }]
  }, {
    path: "/fr/reinitialisation-mot-passe-refusee",
    component: _373895b3,
    name: "resetPasswordDenied___fr"
  }, {
    path: "/fr/reinitialiser-mot-de-passe",
    component: _39daa910,
    name: "resetPassword___fr"
  }, {
    path: "/fr/se-deconnecter",
    component: _2a42870c,
    name: "logout___fr"
  }, {
    path: "/get-started/check-your-inbox",
    component: _8a05bc2c,
    name: "get-started-check-inbox___en"
  }, {
    path: "/get-started/success",
    component: _97dc2828,
    name: "get-started-success___en"
  }, {
    path: "/fr/account/profile",
    component: _f4ad0396,
    children: [{
      path: "/fr/mon-compte/profil",
      component: _3b89c8f8,
      name: "account-profile___fr"
    }, {
      path: "/fr/mon-compte/profil/mot-de-passe",
      component: _cf946336,
      name: "account-profile-password___fr"
    }]
  }, {
    path: "/fr/commencer/succes",
    component: _97dc2828,
    name: "get-started-success___fr"
  }, {
    path: "/fr/commencer/verifiez-votre-boite-de-reception",
    component: _8a05bc2c,
    name: "get-started-check-inbox___fr"
  }, {
    path: "/fr/erreurs/jeton-invalide",
    component: _7d017b32,
    name: "errors-passwordless-token___fr"
  }, {
    path: "/",
    component: _19af2d80,
    name: "index___en"
  }, {
    path: "/fr/participant/mes-inscriptions/:id",
    component: _58b42824,
    name: "user-dashboard-participant-registrations-id___fr"
  }, {
    path: "/fr/participant/mes-soumissions/:id",
    component: _03c04efb,
    children: [{
      path: "",
      component: _4b566d3e,
      name: "user-dashboard-participant-submissions-id___fr"
    }, {
      path: "/fr/participant/mes-soumissions/:id/evaluation",
      component: _f6eb64c8,
      name: "user-dashboard-participant-submissions-id-review___fr"
    }]
  }, {
    path: "/fr/evaluateur/:eventId",
    component: _65370a68,
    name: "user-dashboard-reviewer-eventId___fr"
  }, {
    path: "/participant/my-registrations/:id",
    component: _58b42824,
    name: "user-dashboard-participant-registrations-id___en"
  }, {
    path: "/participant/my-submissions/:id",
    component: _03c04efb,
    children: [{
      path: "",
      component: _4b566d3e,
      name: "user-dashboard-participant-submissions-id___en"
    }, {
      path: "/participant/my-submissions/:id/review",
      component: _f6eb64c8,
      name: "user-dashboard-participant-submissions-id-review___en"
    }]
  }, {
    path: "/fr/evenements/:id/configuration",
    component: _348b3c85,
    name: "events-configuration___fr"
  }, {
    path: "/fr/evenements/:id/tableau-de-bord",
    component: _0f0ef020,
    name: "events-dashboard___fr"
  }, {
    path: "/fr/organisations/:id/comptes-bancaires",
    component: _43c689ea,
    name: "organizations-id-bankAccounts___fr"
  }, {
    path: "/fr/organisations/:id/configuration",
    component: _5c5d7ae9,
    name: "organizations-id-settings___fr"
  }, {
    path: "/fr/organisations/:id/evenements",
    component: _9d9e6f42,
    name: "organizations-id-events___fr"
  }, {
    path: "/fr/organisations/:id/plans",
    component: _eb4dfdd8,
    name: "organizations-id-plans___fr"
  }, {
    path: "/fr/organisations/:id/transactions",
    component: _95f04fca,
    name: "organizations-id-transactions___fr"
  }, {
    path: "/fr/organisations/:id/utilisateurs",
    component: _2143b5b2,
    name: "organizations-id-users___fr"
  }, {
    path: "/fr/evenements/:id/configuration/comite",
    component: _653d8fa0,
    name: "events-configuration-committee___fr"
  }, {
    path: "/fr/evenements/:id/configuration/evaluations",
    component: _7fa9208e,
    children: [{
      path: "",
      component: _e05af6de,
      name: "events-configuration-reviews___fr"
    }, {
      path: "/fr/evenements/:id/configuration/evaluations/evaluateurs",
      component: _2017a54d,
      name: "events-configuration-reviews-reviewers___fr"
    }, {
      path: "/fr/evenements/:id/configuration/evaluations/parametres",
      component: _5b7c8498,
      name: "events-configuration-reviews-settings___fr"
    }]
  }, {
    path: "/fr/evenements/:id/configuration/paiements",
    component: _69bb4f4a,
    name: "events-configuration-payments___fr"
  }, {
    path: "/fr/evenements/:id/configuration/theme",
    component: _65b635fc,
    name: "events-configuration-theme___fr"
  }, {
    path: "/fr/evenements/:id/donnees/communications",
    component: _7fcaddf1,
    name: "events-data-communications___fr"
  }, {
    path: "/fr/evenements/:id/donnees/evaluations",
    component: _303be7dd,
    name: "events-data-reviews___fr"
  }, {
    path: "/fr/evenements/:id/donnees/horaire",
    component: _3007836b,
    name: "events-data-schedule___fr"
  }, {
    path: "/fr/evenements/:id/donnees/inscription",
    component: _70dcfb8a,
    name: "events-data-registration___fr"
  }, {
    path: "/fr/evenements/:id/donnees/resume",
    component: _0201a6dd,
    children: [{
      path: "",
      component: _738a58c0,
      name: "events-data-submission___fr"
    }, {
      path: "/fr/fr/evenements/:id/donnees/resume/evaluations",
      component: _6632b37a,
      name: "events-data-submission-review___fr"
    }]
  }, {
    path: "/fr/evenements/:id/donnees/transactions",
    component: _31a7c029,
    name: "events-data-transactions___fr"
  }, {
    path: "/fr/evenements/:id/configuration/pages-du-site/horaire",
    component: _d4d99342,
    children: [{
      path: "",
      component: _210f39a2,
      name: "events-configuration-pages-schedule___fr"
    }, {
      path: "/fr/evenements/:id/configuration/pages-du-site/horaire/parametres",
      component: _39d4a303,
      name: "events-configuration-pages-schedule-settings___fr"
    }]
  }, {
    path: "/fr/evenements/:id/configuration/pages-du-site/inscription",
    component: _31fad381,
    children: [{
      path: "",
      component: _65be1578,
      name: "events-configuration-pages-registration___fr"
    }, {
      path: "/fr/evenements/:id/configuration/pages-du-site/inscription/courriel",
      component: _7ba5a30e,
      name: "events-configuration-pages-registration-email___fr"
    }, {
      path: "/fr/evenements/:id/configuration/pages-du-site/inscription/parametres",
      component: _1d6eac21,
      name: "events-configuration-pages-registration-settings___fr"
    }]
  }, {
    path: "/fr/evenements/:id/configuration/pages-du-site/soumission",
    component: _71d31094,
    children: [{
      path: "",
      component: _d7a44fd2,
      name: "events-configuration-pages-submission___fr"
    }, {
      path: "/fr/evenements/:id/configuration/pages-du-site/soumission/courriel",
      component: _42b285e1,
      name: "events-configuration-pages-submission-email___fr"
    }, {
      path: "/fr/evenements/:id/configuration/pages-du-site/soumission/parametres",
      component: _5c031724,
      name: "events-configuration-pages-submission-settings___fr"
    }]
  }, {
    path: "/fr/evenements/:id/configuration/pages-du-site/:pageId",
    component: _6d998c91,
    children: [{
      path: "",
      component: _0974a254,
      name: "events-configuration-pages-pageId___fr"
    }, {
      path: "/fr/evenements/:id/configuration/pages-du-site/:pageId/parametres",
      component: _6ae72511,
      name: "events-configuration-pages-pageId-settings___fr"
    }]
  }, {
    path: "/fr/evenements/:id/donnees/communications/:communicationId",
    component: _6ebee503,
    name: "events-data-communications-communicationId___fr"
  }, {
    path: "/fr/evenements/:id/donnees/entrees-formulaires/:formId",
    component: _1f2b8832,
    name: "events-data-form-entries-formId___fr"
  }, {
    path: "/fr/evenements/:id/donnees/evaluations/:reviewId",
    component: _f6800d06,
    name: "events-data-reviews-reviewId___fr"
  }, {
    path: "/fr/evenements/:id/donnees/entrees-formulaires/:formId/badges",
    component: _04e557f4,
    name: "events-data-form-entries-badges-formId___fr"
  }, {
    path: "/fr/evenements/:id/donnees/entrees-formulaires/:formId/etiquettes-presentations",
    component: _032459f1,
    name: "events-data-form-entries-poster-tags-formId___fr"
  }, {
    path: "/fr/organizations/:id/bankAccounts/:bankAccountId",
    component: _111abbe4,
    children: [{
      path: "/fr/organisations/:id/comptes-bancaires/:bankAccountId",
      component: _98887132,
      name: "organizations-id-bankAccounts-bankAccountId___fr"
    }, {
      path: "/fr/organisations/:id/comptes-bancaires/:bankAccountId/formulaire",
      component: _238504bf,
      name: "organizations-id-bankAccounts-bankAccountId-form___fr"
    }, {
      path: "/fr/organisations/:id/comptes-bancaires/:bankAccountId/requetes",
      component: _06fa6e3f,
      name: "organizations-id-bankAccounts-bankAccountId-requests___fr"
    }]
  }, {
    path: "/fr/evaluateur/:eventId/:id",
    component: _0f56f260,
    name: "user-dashboard-reviewer-eventId-id___fr"
  }, {
    path: "/reviewer/:eventId",
    component: _65370a68,
    name: "user-dashboard-reviewer-eventId___en"
  }, {
    path: "/events/:id/configuration",
    component: _348b3c85,
    name: "events-configuration___en"
  }, {
    path: "/events/:id/dashboard",
    component: _0f0ef020,
    name: "events-dashboard___en"
  }, {
    path: "/organizations/:id/bank-accounts",
    component: _43c689ea,
    name: "organizations-id-bankAccounts___en"
  }, {
    path: "/organizations/:id/events",
    component: _9d9e6f42,
    name: "organizations-id-events___en"
  }, {
    path: "/organizations/:id/plans",
    component: _eb4dfdd8,
    name: "organizations-id-plans___en"
  }, {
    path: "/organizations/:id/settings",
    component: _5c5d7ae9,
    name: "organizations-id-settings___en"
  }, {
    path: "/organizations/:id/transactions",
    component: _95f04fca,
    name: "organizations-id-transactions___en"
  }, {
    path: "/organizations/:id/users",
    component: _2143b5b2,
    name: "organizations-id-users___en"
  }, {
    path: "/events/:id/configuration/committee",
    component: _653d8fa0,
    name: "events-configuration-committee___en"
  }, {
    path: "/events/:id/configuration/payments",
    component: _69bb4f4a,
    name: "events-configuration-payments___en"
  }, {
    path: "/events/:id/configuration/reviews",
    component: _7fa9208e,
    children: [{
      path: "",
      component: _e05af6de,
      name: "events-configuration-reviews___en"
    }, {
      path: "/events/:id/configuration/reviews/reviewers",
      component: _2017a54d,
      name: "events-configuration-reviews-reviewers___en"
    }, {
      path: "/events/:id/configuration/reviews/settings",
      component: _5b7c8498,
      name: "events-configuration-reviews-settings___en"
    }]
  }, {
    path: "/events/:id/configuration/theme",
    component: _65b635fc,
    name: "events-configuration-theme___en"
  }, {
    path: "/events/:id/data/communications",
    component: _7fcaddf1,
    name: "events-data-communications___en"
  }, {
    path: "/events/:id/data/registration",
    component: _70dcfb8a,
    name: "events-data-registration___en"
  }, {
    path: "/events/:id/data/reviews",
    component: _303be7dd,
    name: "events-data-reviews___en"
  }, {
    path: "/events/:id/data/schedule",
    component: _3007836b,
    name: "events-data-schedule___en"
  }, {
    path: "/events/:id/data/submission",
    component: _0201a6dd,
    children: [{
      path: "",
      component: _738a58c0,
      name: "events-data-submission___en"
    }, {
      path: "/events/:id/data/submission/reviews",
      component: _6632b37a,
      name: "events-data-submission-review___en"
    }]
  }, {
    path: "/events/:id/data/transactions",
    component: _31a7c029,
    name: "events-data-transactions___en"
  }, {
    path: "/events/:id/configuration/website-pages/registration",
    component: _31fad381,
    children: [{
      path: "",
      component: _65be1578,
      name: "events-configuration-pages-registration___en"
    }, {
      path: "/events/:id/configuration/website-pages/registration/email",
      component: _7ba5a30e,
      name: "events-configuration-pages-registration-email___en"
    }, {
      path: "/events/:id/configuration/website-pages/registration/settings",
      component: _1d6eac21,
      name: "events-configuration-pages-registration-settings___en"
    }]
  }, {
    path: "/events/:id/configuration/website-pages/schedule",
    component: _d4d99342,
    children: [{
      path: "",
      component: _210f39a2,
      name: "events-configuration-pages-schedule___en"
    }, {
      path: "/events/:id/configuration/website-pages/schedule/settings",
      component: _39d4a303,
      name: "events-configuration-pages-schedule-settings___en"
    }]
  }, {
    path: "/events/:id/configuration/website-pages/submission",
    component: _71d31094,
    children: [{
      path: "",
      component: _d7a44fd2,
      name: "events-configuration-pages-submission___en"
    }, {
      path: "/events/:id/configuration/website-pages/submission/email",
      component: _42b285e1,
      name: "events-configuration-pages-submission-email___en"
    }, {
      path: "/events/:id/configuration/website-pages/submission/settings",
      component: _5c031724,
      name: "events-configuration-pages-submission-settings___en"
    }]
  }, {
    path: "/events/:id/configuration/website-pages/:pageId",
    component: _6d998c91,
    children: [{
      path: "",
      component: _0974a254,
      name: "events-configuration-pages-pageId___en"
    }, {
      path: "/events/:id/configuration/website-pages/:pageId/settings",
      component: _6ae72511,
      name: "events-configuration-pages-pageId-settings___en"
    }]
  }, {
    path: "/events/:id/data/communications/:communicationId",
    component: _6ebee503,
    name: "events-data-communications-communicationId___en"
  }, {
    path: "/events/:id/data/form-entries/:formId",
    component: _1f2b8832,
    name: "events-data-form-entries-formId___en"
  }, {
    path: "/events/:id/data/reviews/:reviewId",
    component: _f6800d06,
    name: "events-data-reviews-reviewId___en"
  }, {
    path: "/events/:id/data/form-entries/:formId/badges",
    component: _04e557f4,
    name: "events-data-form-entries-badges-formId___en"
  }, {
    path: "/events/:id/data/form-entries/:formId/poster-tags",
    component: _032459f1,
    name: "events-data-form-entries-poster-tags-formId___en"
  }, {
    path: "/organizations/:id/bankAccounts/:bankAccountId",
    component: _111abbe4,
    children: [{
      path: "/organizations/:id/bank-accounts/:bankAccountId",
      component: _98887132,
      name: "organizations-id-bankAccounts-bankAccountId___en"
    }, {
      path: "/organizations/:id/bank-accounts/:bankAccountId/form",
      component: _238504bf,
      name: "organizations-id-bankAccounts-bankAccountId-form___en"
    }, {
      path: "/organizations/:id/bank-accounts/:bankAccountId/requests",
      component: _06fa6e3f,
      name: "organizations-id-bankAccounts-bankAccountId-requests___en"
    }]
  }, {
    path: "/reviewer/:eventId/:id",
    component: _0f56f260,
    name: "user-dashboard-reviewer-eventId-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
